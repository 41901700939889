@use "./src/sedestral/sass/mixins/positioning";
@use "../../../../sedestral/sass/icons/icons";
@use "./src/sedestral/sass/mixins/texts";
@use "./src/sedestral/sass/extends/animations";

.visualSwap {
  position: fixed;
  width: 100%;
  background: var(--white);
  bottom: 0;
  border-radius: 18px 18px 0px 0px;
  box-shadow: 0 -20px 15px -12px rgb(0 0 0 / 9%);
  left: 0;
  z-index: 99999;
  max-height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  padding-bottom: env(safe-area-inset-bottom);

  &.safeArea {
    padding-top: env(safe-area-inset-top);
  }

  .swapSelector {
    height: 40px;
    width: 100%;

    @include positioning.flex-globally;

    .swapBar {
      height: 5px;
      width: 40%;
      border-radius: 10px;
      left: 30%;
      background: var(--grey-sur);
    }
  }

  .head {
    height: 31px;
    margin-left: 15px;
    width: calc(100% - 30px);

    .name {
      float: left;
      font-size: 22px;

      @include texts.font("bold");
    }

    .close {
      float: right;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      background: var(--grey-sur);
      margin-top: 2px;

      @include positioning.flex-globally;
      @include animations.ripple;

      .icon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-close-bold");
      }
    }
  }

  .content {
    margin-top: 10px;
    height: calc(100% - 81px);
    margin-left: 15px;
    width: calc(100% - 30px);
    position: relative;
  }

}