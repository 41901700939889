@use "../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../sedestral/sass/extends/boxs";
@use "../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../sedestral/sass/extends/inputs";

.globalQuillFormatting {
  @include positioning.clear;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .categoryButtons {
    float: left;
    border-right: var(--grey-sur) 1px solid;

    &:last-child {
      border-right: unset;

      button {
        margin-right: 0;
      }
    }
  }
}