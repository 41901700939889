.componentOfferManageCard {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  position: relative;

  .stripe {
    padding: 14px;
    border-radius: 12px;
    margin-bottom: 25px;

    .error {
      border: 2px solid var(--red);
      border-radius: 5px;
      margin-bottom: 25px;
      font-size: 14px;
      padding: 14px;
      color: var(--red-real);
    }
  }
}