@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentEditorDocsLanguages {
  width: 100%;

  div[data-selectbox] {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    border: unset !important;

    &:hover {
      background: unset;
    }
  }
}