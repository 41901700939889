@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferManage {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 36px;

    .item {
      cursor: pointer;
      border-bottom: 3px solid var(--grey-sur);
      padding-bottom: 8px;
      font-size: 18px;

      @include texts.font("bold");

      &.Home, &.Invoices, &.Payments, &.Address {
        visibility: visible;
      }

      &[checked], &:hover {
        border-bottom: 3px solid var(--blue);
      }
    }
  }

  .bottom {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
  }
}