@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentClusterCover {
  display: flex;
  flex-direction: column;

  .description {
    color: var(--orange-cover);
    font-size: 13px;
    margin-bottom: 5px;

    &.orange {
      color: var(--orange-cover);
    }

    &.red {
      color: var(--red);
    }

    &.green {
      color: var(--green);
    }
  }

  .progress {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
}