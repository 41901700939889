@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferManageAds {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;

  .title {
    font-size: 18px;
    @include texts.font("bold");
  }

  .subtitle {
    font-size: 14px;
    @include texts.font("medium");
  }

  .button {
    display: flex;
    width: fit-content;
    margin-top: 11px;
    padding: 12px 18px;
    background: var(--green-dark);
    border-radius: 9px;
    color: var(--white);
    cursor: pointer;
    font-size: 14px;
    @include texts.font("semi-bold");

    &:hover {
      background: var(--green-dark-hover);
    }

    .icon {
      width: 16px;
      height: 16px;
      margin: 2px 0 0 20px;
      filter: var(--icon-white);
      @include icons.ico("black-right-arrow");
    }
  }

  .illus {
    width: 154px;
    height: 154px;
    margin-top: 15px;
    @include icons.ico("3d-offer-list");
  }
}