@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentCommunityPageAutopilotClusters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .cluster {
    border: 1px solid var(--grey-sur);
    display: flex;
    padding: 5px 10px;
    width: fit-content;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    border-radius: 20px;
    cursor: pointer;

    .button {
      height: 24px;
      width: 24px;
      border-radius: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--grey-sur);
      }

      .icon {
        height: 14px;
        width: 14px;

        @include icons.ico("black-delete-light");
      }
    }

    &.add {
      background: var(--grey-sur);
      border-color: transparent;

      &:hover {
        background: var(--grey-sur-hover);
      }

      .button {
        pointer-events: none;

        .icon {
          @include icons.ico("black-plus");
        }
      }
    }
  }
}