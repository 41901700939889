@use "../../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../../sedestral/sass/mixins/texts";

.componentQuillEditorSendMenu {
  .entry {
    display: flex;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: var(--grey-sur);
    }

    &.schedule {
      .icon {
        @include icons.ico("3d-time");
      }
    }


    .icon {
      height: 18px;
      width: 18px;
      flex: 0 0 18px;
      position: relative;
      margin-right: 6px;
    }

    .text {
      font-size: 14px;

      @include texts.font("semi-bold");
    }

  }
}