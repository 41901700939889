@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentContentScore {
  padding: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);

  .title {
    font-size: 15px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    @include texts.font("semi-bold");

    .button {
      border: 1px solid var(--grey-sur);
      display: flex;
      font-size: 12px;
      padding: 4px 10px;
      margin-left: auto;
      border-radius: 22px;
      gap: 6px;
      align-items: center;
      cursor: pointer;

      @include texts.font("medium");

      &:hover {
        border-color: transparent;
        background: var(--grey-sur);
      }

      .icon {
        height: 10px;
        width: 10px;
        transform: rotate(45deg);

        @include icons.ico("black-top-arrow");
      }
    }
  }

  .competitors {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 20px;
    color: var(--grey-text-dark);

    &.left {
      justify-content: unset;
    }

    .competitor {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 13px;

      &.top {
        .icon {
          @include icons.ico("black-score-top");
        }
      }

      &.average {
        .icon {
          height: 14px;
          width: 14px;

          @include icons.ico("black-score-average");
        }
      }

      .icon {
        display: inline-block;
        height: 12px;
        width: 12px;
        filter: var(--icon-grey-very-dark)
      }
    }
  }

  .contentScore {
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 15px;

    .names{
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;

      .button {
        border: 1px solid var(--grey-sur);
        display: flex;
        font-size: 12px;
        padding: 4px 10px;
        margin-left: auto;
        border-radius: 22px;
        gap: 6px;
        align-items: center;
        cursor: pointer;

        @include texts.font("medium");

        &:hover {
          border-color: transparent;
          background: var(--grey-sur);
        }

        .google {
          height: 12px;
          width: 12px;

          @include icons.ico("colored-google");
        }

        .icon {
          height: 10px;
          width: 10px;
          transform: rotate(45deg);

          @include icons.ico("black-top-arrow");
        }
      }
    }

    .progress {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -10px;
      margin-bottom: 25px;

      .semi {
        height: 123px;
        width: 230px;
        overflow: hidden;
      }
    }
  }

  .contentStructure {
    padding: 14px 0px;
    border-bottom: 1px solid var(--grey-sur);
    margin-bottom: 15px;

    .title {
      margin-bottom: 15px;
    }

    .stats {
      display: flex;
      align-items: center;
      gap: 8px;

      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2px;
        width: 70px;

        .label {
          font-size: 11px;
          text-transform: uppercase;
          color: var(--grey-text-dark);
          letter-spacing: -0.5px;
          transform: scaleY(1.05);
        }

        .value {
          font-size: 16px;
          display: flex;
          gap: 5px;
          align-items: center;

          @include texts.font("semi-bold");

          &.words, &.headings, &.para, &.images {
            visibility: visible;
          }

          .span {
            visibility: visible;
          }

          .icon {
            height: 11px;
            width: 11px;

            &.add {
              filter: var(--icon-red);

              @include icons.ico("black-top-arrow");
            }

            &.reduce {
              filter: var(--icon-red);

              @include icons.ico("black-bottom-arrow");
            }

            &.good {
              filter: var(--icon-green);

              @include icons.ico("black-checked");
            }
          }
        }

        .interval {
          font-size: 11px;
          text-transform: uppercase;
          color: var(--grey-text-dark);
        }
      }

      .separator {
        height: 45px;
        border-right: 1px solid var(--grey-sur);
      }
    }
  }

  .contentQuality {
    margin-bottom: 15px;
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 15px;

    .title {
      margin-bottom: 12px;
    }

    .bar {
      position: relative;
      height: 4px;
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 15px;
    }
  }
}