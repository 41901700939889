@use "../../../../../sedestral/sass/mixins/texts";

.visualSelectBoxCases {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .case {
    padding: 10px 20px;
    background: var(--grey-sur);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;

    @include texts.font("semi-bold");

    &.selected {
      background: var(--black) !important;
      color: var(--white);

      &:hover {
        opacity: 0.7;
      }
    }

    &:hover {
      background: var(--grey-sur-hover);
    }
  }
}