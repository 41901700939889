@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPickerList {

  .entries {

    div[scrolltrack] {
      right: 4px;
      top: 10px;
      height: calc(100% - 70px);
    }

    .entry {
      display: flex;
      margin-bottom: 5px;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      border-radius: 14px;

      &:hover {
        background: var(--grey-sur);
      }

      .avatarContainer {
        width: 28px;
        height: 28px;
        position: relative;
        margin-right: 8px;
      }


      .name {
        font-size: 15px;

        @include texts.font("semi-bold");
      }

      .checked {
        height: 10px;
        width: 10px;
        margin-left: auto;

        @include icons.ico("black-checked");
      }
    }
  }

  .add {
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
    padding: 5px 15px;
    font-size: 15px;
    border-radius: 20px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur);
    }

    .plus {
      height: 10px;
      width: 10px;
      margin-left: 10px;

      @include icons.ico("black-plus");
    }
  }
}