@use "../../../../../../../sedestral/sass/extends/boxs";
@use "../../../../../../../sedestral/sass/extends/inputs";
@use "../../../../../../../sedestral/sass/extends/buttons";

.globalQuillIntegrationCreator {
  height: 100%;
  padding: 15px;
  width: calc(100% - 30px);

  .input {
    @include inputs.border;

    height: 40px;
    font-size: 14px;
  }

  .integrationButtons {
    margin-top: 30px;

    .integrationButton {
      width: fit-content;
      font-size: 14px;
      padding: 9px 24px;
      border-radius: 6px !important;
      margin-left: 5px;
      float: right;

      &.validate {
        @include buttons.green;
      }

      &.cancel {
        @include buttons.basic;

        color: var(--black);

        &:hover {
          background: var(--grey-sur);
        }
      }
    }
  }
}