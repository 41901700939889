.componentStatisticsChart {
  padding-top: 3px;
  padding-left: 1px;
  height: 100%;
  width: 100%;

  &.empty {
    pointer-events: none;

    .data {
      position: absolute;
      top: 0;
      left: 14px;
      height: 100%;
      width: calc(100% - 15px);
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--black-alpha);
    }
  }

  .chartContainer {
    height: 100%;
    width: 100%;
  }
}