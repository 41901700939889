@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentAiGenerations {
  flex: 1;
  width: 100%;
  display: flex;
  gap: 10px;

  .gen {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur);
    }

    &.selected {
      background: var(--grey-sur);
    }
  }

  .regen {
    padding-left: 10px;
    padding-right: 10px;
    height: 34px;
    border: 1px solid var(--grey-sur);
    border-radius: 27px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--black);
    cursor: pointer;
    position: relative;

    &:hover {
      background: var(--grey-sur);
      border-color: transparent;
    }

    &.loading {
      pointer-events: none;

      svg {
        width: 20px;
      }

      .text {
        opacity: 0;
      }

      .icon {
        opacity: 0;
      }
    }

    .text {
      visibility: visible;
    }

    .icon {
      margin-left: 10px;
      height: 18px;
      width: 18px;

      @include icons.ico("black-regen");
    }
  }
}