@mixin outline($width) {
  cursor: pointer;
  box-shadow: #{var(--outline-blue)} 0 0 0 #{$width}px !important;
  outline: unset;
}

@mixin black($width) {
  cursor: pointer;
  box-shadow: #{var(--black)} 0 0 0 #{$width}px !important;
  outline: unset;
}

@mixin blue-hover($width) {
  &:hover {
    @include outline($width);
  }
}

@mixin blue-focus($width) {
  &:focus {
    @include outline($width);
  }
}

@mixin blue-active($width) {
  &:active {
    @include outline($width);
  }
}

@mixin alpha($width) {
  cursor: pointer;
  box-shadow: var(--grey-sur) 0 0 0 #{$width}px !important;
}

@mixin alpha-hover($width) {
  &:hover {
    @include alpha($width);
  }
}