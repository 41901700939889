@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/icons/icons";

.globalQuillIntegrations {
  height: 100%;
  width: 100%;
}

.globalQuillIntegration {
  height: 22px;
  padding-top: 5px;
  margin-left: 10px;
  padding-left: 10px;
  border-radius: 10px;
  font-size: 13px;
  width: calc(100% - 30px);
  cursor: pointer;
  position: relative;

  @include texts.font("semi-bold");

  &.mail {
    .icon {
      @include icons.ico("black-mail");
    }
  }

  &.user {
    .icon {
      @include icons.ico("black-user");
    }
  }

  &:hover {
    background: var(--grey-sur);
  }

  .icon {
    height: 12px;
    width: 12px;
    margin-top: 2px;
    margin-right: 10px;
    float: left;
  }

  .left {
    float: left;
  }

  .right {
    height: 10px;
    width: 10px;
    margin-top: 3px;
    margin-right: 6px;
    float: right;
    @include icons.ico("black-right");
  }
}