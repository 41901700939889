@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentRoleDraggable {
  width: fit-content;
  margin-bottom: 2px;
  position: relative;
  padding: 4px 4px;
  border-radius: 6px;
  background: var(--white);

  @include positioning.clear;

  &[checked] {
    .roleButton {
      background: var(--grey-sur);

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }

  &.inactive {
    opacity: 0.3;
    pointer-events: none;
  }


  .grab {
    height: 12px;
    width: 12px;
    margin-top: 5px;
    margin-right: 8px;
    margin-left: -2px;
    float: left;
    cursor: grab;
    filter: var(--icon-grey);

    @include icons.ico("black-draggable");
  }

  .roleButton {
    padding: 2px 8px;
    margin-left: -4px;
    border-radius: 6px;
    cursor: pointer;
    float: left;
    max-width: calc(100% - 30px);

    @include effects.hover-alpha;

    .roleNames {
      @include positioning.clear;

      .roleName {
        font-size: 14px;
        margin-top: 1px;
        float: left;

        @include texts.font("semi-bold");
      }
    }

    .roleColor {
      position: relative;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 100%;
      margin-left: 2px;
      margin-top: 4px;
    }
  }
}