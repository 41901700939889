@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentAlertSeo {
  border: 1px solid var(--grey-sur);
  font-size: 14px;
  padding: 20px;
  border-radius: 14px;
  display: flex;
  margin-bottom: 15px;
  gap: 15px;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }

  span {
    @include texts.font("semi-bold");
  }

  .bad {
    color: var(--red);
  }

  .good {
    color: var(--green);
  }

  .numberKeywords {
    cursor: pointer;
    text-decoration: underline;
  }

  .buttonCreate {
    min-width: 175px;
    background: var(--black);
    color: var(--white);
    display: flex;
    gap: 10px;
    width: fit-content;
    border-radius: 25px;
    padding: 4px 12px;
    font-size: 14px;
    margin-left: auto;
    margin-top: 5px;
    align-items: center;
    cursor: pointer;

    @include texts.font("semi-bold");

    &:hover {
      opacity: 0.7;
    }

    .icon {
      height: 12px;
      width: 12px;
      filter: var(--icon-white);
      flex: 0 0 12px;

      @include icons.ico("black-right-arrow");
    }
  }

  .iconGsc {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;

    @include icons.ico("colored-google");
  }

  .iconRed {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;

    @include icons.ico("3d-stop-red");
  }

  .iconGreen {
    height: 40px;
    width: 40px;
    flex: 0 0 40px;

    @include icons.ico("3d-stop-green");
  }
}