@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";

.componentAccountSettings {
  @include boxs.card-form;
  border: unset;

  .editIcon {
    .icon {
      @include icons.ico("black-edit");
    }
  }

  .text {
    margin: 15px;
    font-size: 13.5px;
  }

  .secret {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: flex;
    max-width: 400px;
    margin: 0 auto;
  }

  div[btnsave] {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;

    div[btns], div[btnc] {
      font-size: 13px;
      padding: 10px 25px;
    }
  }


  .name, .email, .work, .current, .new, .repeat {
    visibility: visible;
  }

}