@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentPresentationSearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .icon {
    height: 120px;
    width: 120px;
    margin-bottom: 10px;
    margin-top: 35px;

    @include icons.ico("3d-target");
  }

  .title {
    font-size: 30px;

    @include texts.font("semi-bold");
  }

  .description {
    text-align: center;
    max-width: 700px;
  }

  .inputZone {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    width: 100%;
    max-width: 430px;

    .inputContainer {
      height: 46px;
      width: 100%;

      input {
        border-radius: 30px;
        border-width: 1px;
      }
    }

    .searchButton {
      background: var(--black);
      color: var(--white);
      padding: 0px 15px;
      border-radius: 26px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}