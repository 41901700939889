@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/icons/icons";
@use "../../../../sedestral/sass/extends/animations";
@use "../../../../sedestral/sass/extends/editor";

.globalQuill {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include editor.tags;

  div[scrolltrack] {
    right: 0px;
  }

  div[imagedloaderblot] {
    div[networkerror] {
      position: absolute;
      border: 2px solid var(--grey-sur);
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      border-radius: 14px;
    }

    div[mediaupload] {
      position: absolute !important;
    }

    div[loaderlight] {
      width: 100% !important;
      position: absolute !important;
    }

    div[progress] {
      margin-top: 0;
      position: absolute;
      height: 60%;
      top: 20%;
    }
  }

  :global {
    .ql-editor {
      height: 100%;
      width: 100%;
      white-space: pre-wrap;
    }

    .ql-indent-1 {
      margin-left: 40px;
    }

    .ql-line-q {
      margin: 0 !important;
    }

    .ql-cursor-flag {
      margin-top: -2px;
      border-radius: 2px !important;
      margin-left: 1px !important;
    }

    .ql-cursor-selection-block {
      opacity: 0.5;
    }

    .ql-cursor-name {
      margin-left: 4px !important;
      margin-right: 4px !important;
      margin-bottom: 3px !important;
      margin-top: 3px !important;

      @include texts.font("semi-bold");
    }

    .ql-cursor-caret {
      width: 3px !important;
      border-radius: 6px;
      margin-left: 0 !important;
    }

    .ql-hidden {
      display: none;
    }
  }

  .quillEditor {
    overflow: visible !important;
    height: 100%;
  }

  .attachmentsContainer {
    visibility: visible;
  }

  .qlSpanBlotContainer {
    user-select: none;

    .qlSpanBlotChild {
      padding: 0 2px;
      border-radius: 4px;
      background: var(--blue-alpha-dark);
      color: var(--blue-hover);
      cursor: pointer;

      &:hover {
        background: var(--blue-alpha-dark-light);
      }

      .qlSpanBlotContent {
        visibility: visible;
      }

      .qlSpanBlotDelimiter {
        .icon {
          display: inline-block;
          height: 12px;
          width: 12px;
          top: 1.5px;
          margin-left: 0;
          margin-right: 4px;
          position: relative;

          &.blue {
            filter: var(--icon-blue-light);
          }

          &.integration {
            @include icons.ico("black-integration");
          }
        }
      }
    }
  }

  .lineDetailsContainer {
    position: absolute;
    left: -45px;
    top: 0;
    height: 100%;

    .tag {
      padding: 2px 6px;
      border: 1px solid var(--grey-sur);
      border-radius: 6px;
      font-size: 12px;
      position: absolute;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    border-radius: 10px;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--grey-dark);
    z-index: 3;
  }

  table {
    border-collapse: collapse;
    border: 1px solid var(--grey-sur);
    font-size: 13.5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }

  th, td {
    border: 1px solid #dfdfdf;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
}

