@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunity {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  div[emptybasic] {
    div[emptytext] {
      font-size: 22px;
    }

    div[emptyemoji] {
      font-size: 90px;
    }
  }

  .left {
    padding: 0 12px;
    width: 260px;
    height: 100%;
    flex: 0 0 260px;
    position: relative;
    border-right: 1px solid var(--grey-sur);
    background: var(--white);
    display: flex;
    flex-direction: column;

    .head {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-left: 4px;
      align-items: center;

      .names {
        display: flex;

        .productName {
          margin-top: 3px;
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 7px;

          @include texts.ellipsis(1);
          @include texts.font("bold");

          .capsule {
            height: 22px;
            width: 22px;
            position: relative;
          }
        }
      }

      .play {
        margin-left: auto;
        display: flex;
        font-size: 12px;
        border: 1px solid var(--grey-sur);
        height: fit-content;
        border-radius: 16px;
        padding: 3px 6px;
        align-items: center;
        cursor: pointer;

        .icon {
          margin-left: 6px;
          height: 10px;
          width: 10px;

          @include icons.ico("black-maximize");
        }
      }
    }

    .navigation {
      flex-grow: 1;
      position: relative;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .suspended {
        background: var(--red);
        padding: 10px;
        font-size: 14px;
        color: var(--white);
        border-radius: 14px;
        margin-bottom: 8px;
      }

      .bottomAccount {
        display: flex;
        gap: 8px;

        .settings {
          height: 35px;
          width: 35px;
          border-radius: 54px;
          flex: 0 0 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid var(--grey-sur);

          &:hover {
            background: var(--grey-sur);
            border-color: transparent;
          }

          .icon {
            height: 18px;
            width: 18px;

            @include icons.ico("black-lineal-actions");
          }
        }
      }

      .buttons {
        display: flex;
        flex-direction: column;
      }

      .button {
        border: 1px solid var(--grey-sur);
        min-height: 28px;
        display: flex;
        flex-direction: row;
        border-radius: 50px;
        padding: 0 4px;
        width: calc(100% - 10px);
        cursor: pointer;
        align-items: center;
        margin-bottom: 7px;

        &:hover {
          border-color: transparent;
          background: var(--grey-sur);
        }

        &[checked] {
          border-color: transparent;
          background: var(--grey-sur);
        }

        &.strategy {
          .icon {
            @include icons.ico("black-lineal-target");
          }
        }

        &.home {
          .icon {
            @include icons.ico("black-lineal-h");
          }
        }

        &.blog {
          .icon {
            width: 16px;
            height: 16px;
            margin-left: 1px;

            @include icons.ico("black-lineal-blog");
          }
        }

        &.ai {
          .icon {
            @include icons.ico("black-lineal-ai");
          }
        }

        &.account {
          padding: 4px;
          gap: 10px;

          .avatar {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            position: relative;
          }
        }

        &.selected {
          background: var(--grey-sur);
          border-color: transparent;

          &:hover {
            background: var(--grey-sur-hover);
          }
        }

        .icon {
          width: 18px;
          height: 18px;
          margin-right: 9px;
          flex: 0 0 18px;
        }

        .name {
          font-size: 14px;
          display: flex;
          align-items: center;

          @include texts.font("semi-bold");
        }
      }

      .picker {
        display: flex;
      }

      .content {
        flex-grow: 1;
        width: 100%;
        position: relative;
        border-top: 1px solid var(--grey-sur);
        height: 0px;
        overflow: auto;
        padding: 12px 12px 0px 12px;
        margin-left: -12px;

        &.loading {
          overflow: hidden;
        }
      }

      .bottom {
        margin-top: 10px;
        border-top: 1px solid var(--grey-sur);
        padding-top: 10px;
      }

      .loader {
        margin-top: -10px;
      }
    }
  }

  .bodyContainer {
    width: calc(100% - 285px);
    position: relative;

    .loader {
      position: relative;
      overflow: hidden;
      height: calc(100% - 24px);
      padding: 10px;
    }
  }

  &.mobile {
    display: block;

    .left {
      width: calc(100% - 24px);
    }

    .bodyContainer {
      display: none;
    }
  }

  &.mobileBody {
    .left {
      display: none;
    }

    .bodyContainer {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .left {
      .navigation {
        .buttons {
          flex-direction: unset;
          flex-wrap: wrap;
          gap: 5px;

          .button {
            width: calc(50% - 13px);
          }
        }
      }
    }
  }
}