@use "src/sedestral/sass/mixins/texts";

.componentContentScoreSpamWords {
  margin-top: 15px;
  height: 100%;

  div[scrolltrack] {
    right: -10px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .progress {
    height: 7px;
    position: relative;
    margin-bottom: 15px;
    width: 50%;
    border-radius: 28px;
    overflow: hidden;
  }

  .entries {
    border: 1px solid var(--grey-sur);
    border-radius: 12px;

    .entry {
      display: flex;
      border-bottom: 1px solid var(--grey-sur);
      align-items: center;
      font-size: 14px;

      &.titles {
        font-size: 15px;

        @include texts.font("semi-bold");
      }

      &:last-child {
        border-color: transparent;
      }

      .keyword {
        border-right: 1px solid var(--grey-sur);
        width: 100%;
        padding: 10px;
      }

      .count {
        padding: 10px;
        flex: 0 0 160px;
      }
    }
  }
}