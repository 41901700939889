@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageKnowCreatorLink {
  .input {
    height: 52px;
    font-size: 15px;
    margin-top: 12px;
    margin-bottom: 15px;
  }

  .sliding {
    position: relative;
    height: 30px;
    font-size: 14px;
    margin-bottom: 25px;

    @include texts.font("semi-bold");

    .icon {
      height: 12px;
      width: 12px;
      margin-right: 10px;

      &.link {
        @include icons.ico("black-link");
      }

      &.light {
        @include icons.ico("black-link-light");
      }
    }
  }
}