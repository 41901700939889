@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";

.componentGeneralSite {
  width: calc(100% - 30px) !important;
  padding: 15px;
  height: 100%;

  @include positioning.clear;

  div[scrolltrack] {
    right: 4px !important;
    top: 55px;
    height: calc(100% - 65px);
  }

  div[loaderBlock] {
    height: calc(100% - 20px);
  }

  div[btnsave] {
    margin-bottom: 15px;
  }
}