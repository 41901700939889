@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/icons/icons";

.globalMenu {
  .entry {
    display: flex;
    padding: 8px;
    border-radius: 12px;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: var(--grey-sur);
    }

    &.members {
      .icon {
        @include icons.ico("black-members");
      }
    }

    &.delete {
      .icon {
        @include icons.ico("black-delete");
      }
    }

    &.edit {
      .icon {
        @include icons.ico("black-bold-edit");
      }
    }

    &.blog {
      .icon {
        @include icons.ico("black-blog");
      }
    }

    &.roles {
      .icon {
        @include icons.ico("black-roles");
      }
    }

    &.helpCenter {
      .icon {
        @include icons.ico("black-help-center");
      }
    }

    .icon {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .text {
      font-size: 15px;

      @include texts.font("semi-bold");
    }
  }
}