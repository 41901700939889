@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/icons/icons";

.componentRoles {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: var(--grey-sur) 1px solid;
  position: relative;

  .left {
    width: 210px;
    border-right: var(--grey-sur) 2px solid;
    position: relative;
    height: 100%;
    float: left;

    .headTop {
      border-bottom: var(--grey-sur) 2px solid;
      height: 46px;
      position: relative;
      width: 100%;

      .button {
        @include texts.font("bold");
        @include buttons.round;

        height: 19px;
        border-radius: 10px;
        font-size: 15px;
        width: fit-content;
        padding: 5px 6px;
        top: 9px;
        left: 10px;
        position: relative;

        &.CreatorButton {
          visibility: visible;
        }

        .icon {
          height: 12px;
          margin-top: 3px;
          width: 12px;
          margin-right: 10px;
          float: left;

          @include texts.font("bold");
          @include icons.ico("black-plus");
        }
      }
    }

    .bodyContent {
      margin-left: 10px;
      margin-top: 10px;
      height: calc(100% - 60px);
      width: calc(100% - 20px);

      .rolesContainer {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
      }
    }
  }

  .right {
    width: calc(100% - 212px);
    position: relative;
    height: 100%;
    float: right;

    .headTop {
      height: 48px;
      position: relative;
      width: 100%;

      &.rightHead {
        visibility: visible;
      }

      .nameContainer {
        border-bottom: var(--grey-sur) 2px solid;
        width: fit-content;
        height: 35px;
        margin-top: 12px;
        margin-left: 15px;
        position: absolute;

        .nameEditor {
          font-size: 16px;
          float: left;
          width: calc(100% - 25px);
          border: unset;
          padding-left: 2px;

          @include texts.font("bold");
        }

        .icon {
          height: 10px;
          margin-top: 5px;
          margin-left: 10px;
          width: 10px;
          float: left;

          @include icons.ico("black-edit");
        }
      }

      .buttonsContainer {
        top: 10px;
        right: 15px;
        position: absolute;

        .roundButton {
          height: 28px;
          width: 28px;
          border-radius: 100%;
          margin-left: 5px;
          background: var(--grey-sur);
          float: left;
          position: relative;

          @include outline.alpha-hover(2);
          @include positioning.flex-globally;
          @include buttons.round;

          &:hover {
            background: var(--grey-sur-hover);
          }

          &.hide {
            display: none;
          }

          &.colors {
            height: 28px;
            width: 28px;

            .icon {
              opacity: 0.5;
              @include icons.ico("black-color");
            }
          }

          &.delete {
            .icon {
              @include icons.ico("black-delete");
            }
          }

          &.copy {
            .icon {
              @include icons.ico("black-copy");
            }
          }

          .icon {
            height: 10px;
            width: 10px;
          }
        }
      }

      .loader {
        margin-top: 13px;
        height: 20px;
        left: 13px;
        position: absolute;
        width: 50%;
        background: var(--grey-sur);
        border-radius: 10px;

        &.shine {
          @include animations.shine;
        }
      }
    }

    .permissions {
      position: relative;
      overflow: hidden;
      height: calc(100% - 46px);

      div[loaderChat] {
        margin-left: 10px;
        margin-top: 20px;
        width: calc(100% - 20px);
      }
    }
  }

  .emptyContainer {
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, var(--white), #d06bf900);

    .icon {
      height: 80px;
      width: 80px;

      @include icons.ico("3d-roles");
    }
  }

}