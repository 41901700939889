@use "src/sedestral/sass/icons/icons";

.clusterCreator {
  margin-top: 20px;

  .input{
    height: 50px;
    position: relative;

    input {
      font-size: 15px !important;
      border-width: 1px !important;
      border-radius: 30px;
    }
  }


  .buttonsContainer {
    margin-top: 15px;
  }
}