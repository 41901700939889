@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";
@use "../../../../../sedestral/sass/mixins/outline";

.globalMediaImage {
  height: 100%;
  width: 100%;
  min-height: 62px;
  min-width: 62px;
  position: relative;
  background: var(--grey-sur);

  .container {
    height: 100%;
    width: 100%;
    visibility: visible;

    @include positioning.flex-globally;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .edit {
    background: black;
    position: absolute;
    border-radius: 16px;
    bottom: 6px;
    color: var(--white);
    right: 10px;
    background: var(--black-alpha);
    display: flex;
    font-size: 12px;
    height: 24px;
    padding: 0px 8px;
    align-items: center;

    @include outline.blue-hover(3);
    @include texts.font("semi-bold");

    .icon {
      height: 12px;
      width: 12px;
      filter: var(--icon-white);
      margin-left: 8px;

      @include icons.ico("black-bold-edit");
    }
  }

}