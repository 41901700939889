@use "../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../sedestral/sass/extends/animations";

.globalLoaderObjectChat {
  width: 100%;
  overflow: hidden;

  &.shine {
    @include animations.shine;
  }

  .object {
    margin-bottom: 20px;
    clear: both;
    width: 100%;
    position: relative;

    &.right {
      .message {
        margin: auto 0 10px auto;
      }
    }

    .message {
      margin-bottom: 10px;
      border-radius: 10px;
      background: var(--grey-sur);

      &.first {
        width: 30%;
        height: 30px;
      }

      &.second {
        width: 70%;
        height: 75px;
      }

      &.last {
        width: 40%;
        height: 50px;
      }
    }
  }
}