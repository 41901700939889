@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/animations";

.componentKeywords {

  &.tiny {
    .line {
      margin-bottom: 0 !important;
      font-size: 14px !important;
    }

    .head {
      height: 30px !important;

      .column {
        font-size: 13px !important;

        @include texts.font("medium");
      }
    }
  }

  .search {
    width: 300px;
    background: var(--grey-sur);
    height: 36px;
    margin-bottom: 10px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    gap: 6px;

    .icon {
      height: 16px;
      width: 16px;
      flex: 0 0 16px;
      margin-left: 10px;
      filter: var(--icon-grey-dark);

      @include icons.ico("black-search");
    }

    .input {
      border-color: transparent;
      background: transparent;
      height: 100%;
    }
  }

  .lines {
    .line {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 10px;
      border-bottom: 1px solid var(--grey-sur);
      margin-bottom: 8px;
      font-size: 15px;

      @include texts.font("semi-bold");

      &.head {
        visibility: visible;
        height: 50px;
        z-index: 1;
        position: relative;

        .column {
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 10px;
          cursor: pointer;

          &.notSortable {
            cursor: default;

            &:hover {
              background: unset;
            }
          }

          &:hover {
            background: var(--grey-sur);
          }
        }
      }

      .columnMultiple {
        display: flex;
        gap: 17px;
      }

      @media (max-width: 480px) {
        .columnMultiple {
          flex-wrap: wrap;
        }
      }

      .column {
        width: calc(100% - 10px);
        padding: 0 10px;
        margin-left: -10px;

        &.keyword {
          flex: 0 0 250px;
        }

        &.large2 {
          flex: 0 0 150px;
        }

        .intent {
          height: 20px;
          width: 20px;
          position: relative;
        }

        .progress {
          height: 36px;
          width: 36px;
        }

        .button {
          background: var(--black);
          color: var(--white);
          font-size: 13px;
          padding: 6px 14px;
          border-radius: 20px;
          margin-left: auto;
          display: flex;
          align-items: center;
          gap: 10px;
          width: fit-content;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }

          .icon {
            margin-left: auto;
            height: 12px;
            width: 12px;
            filter: var(--icon-white);

            @include icons.ico("black-right-arrow");
          }
        }

        .note {
          background: var(--green);
          padding: 2px 8px;
          font-size: 13px;
          border-radius: 5px;
          width: fit-content;
          color: var(--white);

          &.recommandation {
            border-radius: 15px;
          }

          &.high {
            background: var(--red);
          }

          &.medium {
            background: var(--orange);
          }

          &.good {
            background: var(--green);
          }
        }

        .loader {
          height: 14px;
          width: 100%;
          background: var(--grey-sur);
          border-radius: 20px;
          margin-left: -10px;
          overflow: hidden;
          position: relative;

          @include animations.shine;
        }

        .arrow {
          height: 14px;
          width: 14px;
          margin-left: auto;

          &.desc {
            @include icons.ico("black-bottom");
          }

          &.asc {
            @include icons.ico("black-top");
          }
        }

        .onChart {
          display: flex;

          .lastValue {
            color: var(--blue);
            display: flex;
            align-items: center;
            margin-right: 5px;
            font-size: 13px;
            width: 36px;
          }

          .chart {
            width: 64px;
            height: 34px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            &:hover {
              overflow: visible;
            }

            .chartContainer {
              height: 100px;
              width: 70px;
              position: absolute;
              margin-left: -10px;
            }
          }
        }

        .link {
          padding: 3px;
          display: flex;
          align-items: center;
          border-radius: 20px;
          gap: 5px;
          border: 1px solid var(--grey-sur);
          font-size: 13px;
          cursor: pointer;
          width: fit-content;

          @include texts.font("medium");

          &:hover {
            border-color: transparent;
            background: var(--grey-sur);
          }

          .text {
            max-width: 100px;

            @include texts.ellipsis(1);
          }

          .icon {
            height: 14px;
            width: 14px;

            &.maximize {
              height: 10px;
              width: 10px;
              margin-left: 5px;
              margin-right: 5px;

              @include icons.ico("black-maximize");
            }

            &.google {
              @include icons.ico("colored-google");
            }
          }
        }

        .coverage {
          height: 26px;
          width: 26px;
          position: relative;
          border-radius: 100%;
          overflow: hidden;
        }

        .name {
          display: flex;
          align-items: center;
          gap: 10px;
          word-break: break-all;
          min-width: 188px;

          a:hover {
            color: var(--blue) !important;
          }

          .sedestral {
            height: 16px;
            width: 16px;
            position: relative;
            flex: 0 0 16px;
          }
        }
      }
    }
  }

  .empty {
    height: 250px;

    .icon {
      height: 100px;
      width: 100px;

      @include icons.ico("3d-website");
    }
  }

  @media screen and (max-width: 480px) {

    .search {
      width: 100%;
    }

    .empty {
      .icon {
        width: 70px;
        height: 70px;
      }

      div[emptytext] {
        font-size: 18px !important;
      }
    }

    .lines {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }

    .line {
      flex-direction: column;
      border: 1px solid var(--grey-sur);
      border-radius: 12px;

      &.head {
        display: none;
      }

      .column {
        flex: unset !important;
        display: flex;
        gap: 8px;

        &.keyword {
          font-size: 18px;
        }
      }
    }
  }
}