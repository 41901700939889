@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentClusterCompetitors {
  height: 100%;
  width: 100%;

  div[scrolltrack] {
    right: 5px !important;
  }

  .emptyIcon {
    height: 100px;
    width: 100px;

    @include icons.ico("3d-empty");
  }

  .competitor {
    margin-bottom: 10px;
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 10px;

    &:last-child {
      margin-bottom: 50px;
    }

    .domain {
      font-size: 15px;
      margin-bottom: 5px;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        color: var(--blue);
      }
    }

    .stats {
      display: flex;

      .stat {
        border-right: 1px solid var(--grey-sur);
        padding-right: 10px;
        margin-right: 10px;

        .label {
          font-size: 13px;
          margin-bottom: 3px;
          color: var(--grey-text-dark);
        }

        .value {
          @include texts.font("semi-bold");
        }
      }
    }
  }
}