@use "../../../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../../../sedestral/sass/mixins/texts";

.globalQuillFormattingButton {
  border-radius: 6px;
  padding: 0 7px;
  cursor: pointer;
  margin-right: 3px;
  border: 0;
  background: unset;
  height: 24px;
  position: relative;
  float: left;

  @include positioning.flex-globally;

  &:first-child {
    margin-left: 3px;
  }

  &.large {
    width: 48px;
  }

  &.fontFamily {
    width: 123px;
    justify-content: unset !important;
    @include positioning.flex-horizontally;

    .icon {
      float: left;
    }

    .name {
      width: 75px;
      float: left;
      @include texts.ellipsis(1);
    }

    .bottom {
      right: 6px;
      position: absolute;
      float: right !important;
    }
  }

  &.active {
    background: var(--blue-cian);
    color: var(--blue-new-text);

    .icon {
      filter: var(--icon-blue-pulp);
    }
  }

  &.formatBackground {
    width: 34px;
    padding: 0 0;
  }

  &:hover {
    background: var(--grey-sur);
  }

  .fontColor {
    height: 15px;
    float: left;
    width: 14px;
    border-radius: 5px;
    background: var(--black);
  }

  .fontBackground {
    height: calc(100% - 10px);
    width: 4px;
    left: 4px;
    position: absolute;
    bottom: 5px;
    border-radius: 20px;
  }

  .icon {
    height: 14px;
    width: 14px;

    &.AlignmentIcon {
      visibility: visible;
    }

    &.list {
      @include icons.ico("formatting-list");
    }

    &.bold {
      @include icons.ico("formatting-bold");
    }

    &.quote {
      @include icons.ico("formatting-quote");
    }

    &.image {
      @include icons.ico("formatting-image");
    }

    &.italic {
      @include icons.ico("formatting-italic");
    }

    &.underline {
      @include icons.ico("formatting-underline");
    }

    &.strikethrough {
      @include icons.ico("formatting-strikethrough");
    }

    &.link {
      @include icons.ico("formatting-link");
    }

    &.delete {
      @include icons.ico("black-delete");
    }

    &.shadow {
      @include icons.ico("black-boolean");
    }

    &.embedAlignCenter {
      @include icons.ico("formatting-embed-align-center");
    }

    &.embedHalfWidth {
      @include icons.ico("formatting-embed-half-width");
    }

    &.embedMaxWidth {
      @include icons.ico("formatting-embed-max-width");
    }

    &.embedAlignLeft {
      @include icons.ico("formatting-embed-align-left");
    }

    &.embedAlignRight {
      @include icons.ico("formatting-embed-align-right");
    }

    &.fonts {
      @include icons.ico("formatting-fonts");
    }

    &.alignmentLeft {
      @include icons.ico("formatting-alignment-left");
    }

    &.background {
      margin-left: 8px;
      @include icons.ico("formatting-background");
    }

    &.emojis {
      @include icons.ico("black-emojis");
    }

    &.bottom {
      @include icons.ico("black-bottom-bold");
      margin-left: 5px;
      height: 12px;
      width: 12px;
    }

    &.alignmentRight {
      @include icons.ico("formatting-alignment-right");
    }

    &.alignmentCenter {
      @include icons.ico("formatting-alignment-center");
    }

    &.fontSize {
      @include icons.ico("formatting-font-size");
    }
  }

  .name {
    text-align: left;
    margin-left: 5px;
    margin-right: 3px;
  }
}