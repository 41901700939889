@use "src/sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/icons/icons";

.componentCommunityArticleAiRulesLinks {
  width: 100%;
  height: inherit;

  &.collapsed {
    .entries, .add {
      display: none;
    }
  }

  .entries {
    position: relative;

    .emptyIcon {
      height: 40px;
      width: 40px;

      @include icons.ico("3d-forms");
    }

    .inputContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 12px;

      .promptContainer {
        flex: 0 0 100%;
      }

      .name,.link {
        @include inputs.border;

        border: var(--grey-sur) 2px solid;
        width: calc(100% - 40px);
        font-size: 14px;
        height: 44px;
        border-radius: 10px;
      }

      .delete {
        @include buttons.round;
        @include positioning.flex-globally;

        flex: 0 0 34px;
        height: 34px;
        width: 34px;
        border-radius: 100%;
        margin-left: 5px;

        &:hover {
          .icon {
            filter: var(--icon-black);
          }
        }

        .icon {
          height: 20px;
          width: 20px;
          filter: var(--icon-grey-dark);

          @include icons.ico("black-delete-light");
        }
      }
    }

    .ghostContainer {
      height: calc(100% - 80px);
      width: 100%;
      top: 0;
      overflow: hidden;
      left: 0;
      position: absolute;

      .shadow {
        height: 100%;
        width: 100%;
        background: linear-gradient(
                        0deg, white, #ffffff00);
        position: absolute;
        top: 0;
        left: 0;
      }

      .entry {
        position: relative;
        height: 44px;
        width: 100%;
        background: var(--grey-sur);
        border-radius: 10px;
        margin-bottom: 10px;
      }
    }

    .emptyContainer {
      height: 180px;
      width: 100%;
      min-height: 170px;

      div[emptyText] {
        font-size: 18px;

        @include texts.font("semi-bold");
      }
    }
  }

  .add {
    margin-bottom: 15px;
    border-top: var(--grey-sur) 1px solid;
    width: 100%;
    position: relative;

    .addButton {
      @include texts.font("bold");
      @include buttons.round;

      display: flex;
      margin-top: 15px;
      padding: 7px 16px;
      font-size: 14px;
      width: fit-content;
      border-radius: 6px;
      position: relative;

      .icon {
        height: 12px;
        width: 12px;
        margin-top: 3px;
        margin-right: 8px;

        @include icons.ico("black-plus");
      }
    }
  }
}