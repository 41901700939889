@use "src/sedestral/sass/mixins/positioning";

.componentRoleVerticalRoles {
  height: 100%;
  width: 100%;
  line-height: 12px;

  @include positioning.clear;

  div[role] {
    margin-right: 5px;
  }
}