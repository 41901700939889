@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentCluster {
  padding: 15px;
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  width: calc(33.33% - 46px);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @include outline.blue-hover(2);

  .head {
    display: flex;
    align-items: center;

    .details {
      margin-right: 10px;

      .titles {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 8px;

        .title {
          font-size: 17px;

          @include texts.font("semi-bold");

          .fire {
            width: 16px;
            height: 16px;
            display: inline-block;
            top: 2px;
            position: relative;
            filter: var(--icon-fire);

            @include icons.ico("black-fire");
          }
        }

        .badge {
          border: 1px solid var(--grey-sur);
          border-radius: 8px;
          padding: 3px 6px;
          color: var(--grey-text-dark);
          font-size: 12px;

          &.hide {
            display: none;
          }
        }
      }

      .vol {
        font-size: 13px;

        span {
          color: var(--grey-text-dark);
        }
      }
    }

    .semi {
      height: 60px;
      width: 60px;
      margin-left: auto;
      flex: 0 0 60px;
    }
  }

  .entries {
    margin-bottom: 20px;
    margin-top: 15px;

    .entry {
      font-size: 13px;
      border-bottom: 1px solid var(--grey-sur);
      padding: 12px 0;
      display: flex;
      align-items: center;

      .icon {
        height: 16px;
        width: 16px;
        position: relative;
        margin-left: auto;
        border-radius: 100%;
        overflow: hidden;
      }
    }
  }

  .bottom {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: auto;

    &.withCover {
      flex-direction: column;
      height: unset;
      gap: 10px;

      .button {
        &:hover {
          opacity: 1 !important;
        }
      }
    }

    .cover {
      display: flex;
      gap: 10px;
      align-items: center;
      width: 100%;
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 14px;

      .text {
        font-size: 13px;
        color: var(--grey-text-dark);
        width: 100%;
      }

      .progress {
        height: 6px;
        position: relative;
        width: 100%;
        margin-left: auto;
        overflow: hidden;
        border-radius: 8px;
      }
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 10px;

      .button {
        background: var(--black);
        color: var(--white);
        font-size: 14px;
        display: flex;
        gap: 10px;
        padding: 0 12px;
        height: 40px;
        align-items: center;
        border-radius: 30px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &.delete {
          border: 1px solid var(--grey-sur);
          background: var(--white);

          &:hover {
            opacity: 1;
            border-color: transparent;
            background: var(--grey-sur);
          }

          .icon {
            height: 18px;
            width: 18px;
            filter: var(--icon-black);

            @include icons.ico("black-delete-light");
          }
        }

        .icon {
          height: 18px;
          width: 18px;
          filter: var(--icon-white);

          @include icons.ico("black-write-ai");
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}