@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.componentMenuTab {
  border-bottom: 1px solid var(--grey-sur);
  padding: 0 0px 5px 0px;
  display: flex;
  gap: 15px;

  .case {
    height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 6px;
    flex-shrink: 0;

    @include texts.font("semi-bold");

    &:hover {

      border-color: transparent;
      background: var(--grey-sur);
    }

    &.selected, &[checked] {
      background: var(--grey-sur);

      &:hover {
        background: var(--grey-sur-hover);
      }
    }

    &.competition {
      .icon {
        @include icons.ico("black-pos-competition");
      }
    }

    &.google {
      .icon {
        @include icons.ico("colored-google");
      }
    }

    &.track {
      .icon {
        width: 16px;
        height: 16px;

        @include icons.ico("black-pos-track");
      }
    }

    &.list {
      .icon {
        @include icons.ico("black-lineal-blocks");
      }
    }

    &.map {
      .icon {
        @include icons.ico("colored-topical-map");
      }
    }

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    overflow: auto;
    flex: 0 0 32px;
  }
}