@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityLanguagePicker {
  background: var(--white);

  .entries {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .entry {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 8px 12px;
      border-radius: 12px;
      font-size: 15px;

      @include texts.font("semi-bold");

      .icon {
        height: 18px;
        width: 18px;
        position: relative;
        margin-right: 8px;
      }

      &:hover {
        background: var(--grey-sur);
      }

      &.selected {
        background: var(--grey-sur);

        &:hover {
          background: var(--grey-sur-hover);
        }
      }
    }
  }

  .add {
    padding: 5px 15px;
    margin-top: 10px;
    border-radius: 20px;
    cursor: pointer;
    color: var(--black);
    float: right;
    font-size: 15px;
    height: 154px;
    border: 1px solid var(--grey-sur);

    @include texts.font("semi-bold");

    div[emptytext] {
      font-size: 14px;
      width: 100%;
      left: 0;

      @include texts.font("semi-bold");
    }

    div[emptybutton] {
      padding: 4px 12px !important;
      font-size: 14px !important;
      margin-top: 12px;

      @include texts.fontImportant("semi-bold");
    }

    .icon {
      height: 36px;
      width: 36px;

      @include icons.ico("3d-translate");
    }
  }
}