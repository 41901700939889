@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityPageKnowCreatorText {
  margin-top: 20px;


  .editor {
    height: 240px;
    border-radius: 12px;
    border: 1px solid var(--grey-sur);
  }

  .label {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .sliding {
    position: relative;
    height: 30px;
    font-size: 14px;
    margin-bottom: 15px;
    margin-top: 15px;

    @include texts.font("semi-bold");

    .icon {
      height: 12px;
      width: 12px;
      margin-right: 10px;

      &.link {
        @include icons.ico("black-link");
      }

      &.light {
        @include icons.ico("black-link-light");
      }
    }
  }

  .buttonsContainer {
    margin-top: 15px;
  }
}