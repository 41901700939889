@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/outline";
@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/icons/icons";

.componentAccountInvitations {
  width: 100%;
  overflow: hidden;

  .categories {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid var(--grey-sur);
    border-top: 1px solid var(--grey-sur);

    .column {
      font-size: 13px;
      padding-left: 10px;
      width: calc(16% - 10px);
      height: 100%;
      float: left;
      @include positioning.flex-vertically;
      @include texts.font("bold");

      &.large {
        width: calc(28% - 10px);
      }

      &.short {
        width: calc(18% - 10px);
      }

      &.edit {
        width: calc(10% - 10px);
      }
    }
  }

  .entriesScroll {
    .entries {
      visibility: visible;
      position: relative;

      &.shine {
        @include animations.shine;
      }
    }
  }

  .invitationsButtons {
    bottom: 10px;
    width: 100%;
    z-index: 9;
    position: absolute;
    right: 15px;

    @include boxs.blur-inside;


    .creatorContainer {
      right: 0;
      bottom: 58px;
      height: 276px;
      padding: 15px;
      display: none;
      width: 336px;
      overflow: hidden;
      position: absolute;

      @include boxs.sur;
    }

    .button {
      @include texts.font("bold");
      @include outline.blue-hover(3);
      @include buttons.grey;
      padding: 8px 18px;
      font-size: 15px;
      float: right;
      border-radius: 20px;
      position: relative;

      &.CreateButton {
        visibility: visible;
      }
    }
  }


  .emptyContainer {
    position: absolute;
    height: calc(100% - 63px);
    border-radius: 15px;
    width: 100%;
    top: 0;
    background: linear-gradient(to top, var(--white), #d06bf900);

    .icon {
      height: 80px;
      width: 80px;

      @include icons.ico("3d-link");
    }
  }
}