@use "../../../../sedestral/sass/mixins/positioning";
@use "../../../../sedestral/sass/icons/icons";

.globalHoursClockPicker {
  position: relative;
  width: 100%;
  height: 100%;

  @include positioning.flex-vertically;

  div[data-selectbox] {
    border: none !important;
  }
}