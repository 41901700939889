@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentCommunityPageAutopilot {

  .cases {
    display: flex;
    gap: 15px;
    width: 100%;
    margin-bottom: 15px;

    .case {
      background: var(--grey-sur);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 25px 15px 15px 15px;
      min-height: 200px;
      width: calc(100% - 45px);
      position: relative;

      &.write {
        .icon {
          @include icons.ico("3d-write");
        }
      }

      &.idea {
        .icon {
          @include icons.ico("3d-idea");
        }
      }

      &.publish {
        .icon {
          @include icons.ico("illus-letter");
        }
      }

      .icon {
        height: 51px;
        width: 51px;
      }

      .title {
        font-size: 22px;
        text-align: center;

        @include texts.font("semi-bold");

        .arrow {
          height: 14px;
          width: 14px;
          display: inline-block;

          @include icons.ico("black-right-arrow");
        }
      }

      .text {
        text-align: center;
        font-size: 15px;
      }
    }
  }

  .settings {
    margin-top: 40px;

    .zone {
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 15px;
      margin-bottom: 20px;
      margin-top: 20px;

      .active {
        color: var(--white);
        background: var(--green);
        width: fit-content;
        padding: 8px 14px;
        border-radius: 20px;
        margin-bottom: 20px;
        font-size: 14px;

        @include texts.font("semi-bold");
      }

      .label {
        font-size: 16px;
        margin-bottom: 15px;

        @include texts.font("semi-bold");
      }

      .writers {
        width: fit-content;
        border: 1px solid var(--grey-sur);
        padding: 8px 20px;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur);
          border-color: transparent;
        }
      }

      .stat {
        font-size: 18px;

        @include texts.font("semi-bold");

        .count {
          font-size: 46px;
        }
      }

      .empty {
        border: 1px solid var(--grey-sur);
        width: fit-content;
        padding: 15px;
        border-radius: 10px;
        height: 90px;

        div[emptyemoji] {
          display: none;
        }

        div[emptytext] {
          font-size: 15px;
          margin-bottom: 0;

          @include texts.font("semi-bold");
        }

        div[emptybutton] {
          font-size: 15px;
          margin-top: 15px;

          @include texts.font("semi-bold");
        }
      }

      .list {
        height: 50px;
        max-width: 250px;
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .circle {
      border-radius: 100%;
      font-size: 50px;
      border: 1px solid var(--grey-sur);
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;

      @include texts.font("semi-bold");

      span {
        font-size: 30px
      }
    }

    .button {
      background: var(--grey-sur);
      width: fit-content;
      padding: 14px 28px;
      font-size: 18px;
      border-radius: 32px;
      cursor: pointer;
      position: relative;

      @include texts.font("semi-bold");

      &.loading {
        color: transparent;
      }

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }

  @media (max-width: 480px) {
  }
}