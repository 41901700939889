@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityArticleAiKeywords {
  width: 100%;
  margin-top: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;

  &.loading {
    .case {
      display: none;
    }
  }

  .emptyIcon {
    height: 200px;
    width: 200px;

    @include icons.ico("animated-website");
  }

  .case {
    border-radius: 14px;
    border: 1px solid var(--grey-sur);
    padding: 12px;

    .head {
      margin-bottom: 20px;

      .title {
        font-size: 15px;

        @include texts.font("semi-bold");
      }
    }

    .table {
      display: none;
    }

    .custom {
      display: flex;
      gap: 15px;

      .input {
        height: 42px;
        position: relative;
        width: 100%;

        input {
          border-width: 1px;
          border-radius: 22px;
        }
      }

      .button {
        background: var(--black);
        color: var(--white);
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0 14px;
        margin-left: auto;
        border-radius: 28px;
        font-size: 14px;
        flex: 0 0 240px;
        cursor: pointer;

        @include texts.font("semi-bold");

        &:hover {
          opacity: 0.8;
        }

        .icon {
          height: 14px;
          width: 14px;
          filter: var(--icon-white);
          margin-left: auto;

          @include icons.ico("black-right-arrow");
        }
      }
    }

    .analyse {
      position: relative;

      div[emptybasic] {
        height: fit-content;
      }

      div[emptyText] {
        font-size: 16px;

        @include texts.font("semi-bold");
      }

      div[emptybutton] {
        font-size: 16px;
        margin-top: 0;

        @include texts.font("semi-bold");
      }

      .icon {
        width: 60px;
        height: 60px;

        @include icons.ico("3d-website");
      }
    }
  }

  @media screen and (max-width: 480px) {
    .custom {
      flex-direction: column;

      .button {
        flex: unset !important;
        height: 40px;
      }
    }

    .emptyIcon {
      height: 100px;
      width: 100px;
    }

    div[emptytext] {
      font-size: 18px !important;
    }
  }
}