@use "src/sedestral/sass/mixins/texts";

.componentKeywordsStats {
  height: 100%;

  .entry {
    display: flex;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--grey-sur);
    margin-bottom: 5px;
    font-size: 14px;
    align-items: center;
    gap: 10px;

    .label {
      color: var(--grey-text-dark);
      text-transform: uppercase;
      font-size: 13px;
      margin-bottom: 15px;

      &.left {
        margin-left: auto;
      }
    }

    .name {
      font-size: 14px;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        color: var(--blue);
      }

    }

    .value {
      margin-left: auto;
      font-size: 17px;

      @include texts.font("semi-bold");
    }
  }
}