@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/icons/icons";

.componentCursor {
  margin-left: auto;
  display: flex;

  .round {
    height: 28px;
    width: 28px;
    background: var(--grey-sur);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background: var(--grey-sur-hover);
    }

    &.less {
      .icon {
        @include icons.ico("black-less");
      }
    }

    &.plus {
      .icon {
        @include icons.ico("black-plus");
      }
    }

    .icon {
      height: 12px;
      width: 12px;
    }
  }

  .input {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 18px;
    border: unset;
    width: 50px;
    text-align: center;

    @include texts.font("semi-bold");
  }
}