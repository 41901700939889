@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/extends/animations";

.componentInvoice {
  width: 100%;
  height: 50px;
  border-bottom: var(--grey-sur) 1px solid;
  font-size: 14px;
  position: relative;

  .case {
    width: calc(18% - 11px);
    padding-left: 10px;
    border-right: var(--grey-sur) 1px solid;
    height: 100%;
    float: left;

    @include positioning.flex-vertically;

    &:last-child {
      border-right: 0;
    }

    &.large {
      width: calc(18% - 11px);
    }

    &.short {
      width: calc(10% - 11px);
    }
  }

  .download, .ticket {
    position: relative;
    cursor: pointer;
    width: 100%;
    text-decoration: underline
  }

  .ghostLine {
    height: 5px;
    width: 50%;
    border-radius: 4px;
    background: var(--grey-sur);

    @include animations.shine;
  }
}