@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyRankTrackArticle {
  .items {
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
    gap: 20px;
    min-height: calc(100% - 20px);
  }

  .loader {
    height: 100%;
    width: calc(100% - 30px);
    position: absolute;
  }

  .paging {
    height: 50px;
    margin-top: auto;
    margin-right: 15px;
  }

  .empty {
    height: fit-content !important;
    position: relative;
    width: 100%;

    div[emptytext] {
      font-size: 20px !important;

      @include texts.font("semi-bold");
    }

    .positionIcon {
      height: 242px;
      width: 531px;
      margin-top: 25px;
      margin-bottom: 25px;

      @include icons.ico("illus-position");
    }
  }
}