@use "../../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../../sedestral/sass/icons/icons";


.componentQuillHtmlReplyBlot {
  margin-top: 40px !important;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;


  &.collapsed {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  &.unCollapsed {
    visibility: visible;
  }
}

.componentQuillHtmlReplyBlotCollapse {
  position: absolute;
  display: flex;
  align-items: center;
  background: var(--white);
  z-index: 5;
  padding-left: 14px;
  padding-right: 5px;
  left: 0;
  top: -30px;
  height: 22px;
  box-shadow: rgb(207 207 219 / 40%) 0 0 0 1px inset;
  border-radius: 7px;
  width: fit-content;
  pointer-events: all;
  cursor: pointer;

  &:hover {
    box-shadow: unset;
    background: var(--grey-sur);
  }

  .more {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    transform: rotate(90deg);

    @include icons.ico("black-more");
  }
}