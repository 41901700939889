@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/outline";

.componentEditorDocsImage {

  .title {
    font-size: 15px;

    @include texts.font("semi-bold");
  }

  .image {
    height: 180px;
    width: 100%;
    background: var(--grey-sur);
    margin-top: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    .img {
      width: 100%;
    }

    .edit, .delete {
      height: 24px;
      background: var(--black-alpha);
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      color: var(--white);
      align-items: center;
      font-size: 13px;
      border-radius: 14px;
      cursor: pointer;

      @include outline.blue-hover(2);

      &.edit {
        right: 106px;

        .icon {
          @include icons.ico("black-bold-edit");
        }
      }

      &.delete {
        .icon {
          width: 16px;
          height: 16px;

          @include icons.ico("black-delete-light");
        }
      }

      .picker {
        position: absolute;

        input {
          opacity: 0;
        }
      }

      .icon {
        height: 14px;
        width: 14px;
        margin-right: 10px;
        margin-left: 10px;
        filter: var(--icon-white);
      }

      .name {
        margin-right: 10px;
      }
    }
  }
}