@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/boxs";

.componentRolePermissions {
  height: 100%;
  width: 100%;

  div[scrolltrack] {
    top: 10px !important;
    right: 2px !important;
    height: calc(100% - 20px) !important;
  }


  .buttonsContainer {
    background: var(--white);
    position: absolute;
    bottom: 15px;
    left: calc(50% - 315px);
    transform: translateY(50px);
    width: 600px;
    padding: 15px;

    @include boxs.sur;

    .text {
      float: left;
      font-size: 13px;
      margin-top: 10px;
    }

    div[btns], div[btnc] {
      padding: 10px 20px;
    }
  }

}