@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentEditorDocsWriters {
  .title {
    font-size: 16px;
    margin-bottom: 12px;

    @include texts.font("semi-bold");
  }

  .input {
    background: var(--grey-sur);
    border: unset;
    padding: 8px 12px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .entries {
    height: calc(100% - 120px);
    margin-bottom: 15px;
    width: calc(100% + 12px);
    margin-left: -6px;

    div[loaderlight] {
      height: 80px;
      position: relative;
    }

    div[scrolltrack] {
      margin-top: 44px;
      margin-right: 4px;
      display: block;
      height: calc(100% - 120px);
    }

    .content {
      .entry {
        height: 30px;
        margin-bottom: 5px;
        width: 100%;
        font-size: 14px;
        border-radius: 18px;
        display: flex;
        align-items: center;

        @include texts.font("semi-bold");

        &:hover {
          background: var(--grey-sur);
        }

        .type {
          height: 12px;
          width: 12px;
          margin-left: 6px;
          margin-right: 5px;

          &.account {
            @include icons.ico("black-user");
          }

          &.pensil {
            @include icons.ico("black-user-add");
          }
        }

        .buttons {
          display: flex;
          margin-left: auto;
          margin-right: 6px;

          .button {
            height: 24px;
            width: 24px;
            cursor: pointer;
            display: flex;
            border-radius: 100%;
            justify-content: center;
            align-items: center;

            &:hover {
              background: var(--grey-sur-hover);
            }

            &.plus {
              .icon {
                @include icons.ico("black-plus");
              }
            }

            &.checked {
              .icon {
                @include icons.ico("black-checked");
              }
            }

            &.delete {
              .icon {
                @include icons.ico("black-delete");
              }
            }

            .icon {
              height: 12px;
              width: 12px;
              background: black;
            }
          }
        }
      }
    }
  }

  .addEntry {
    display: flex;
    margin-left: auto;
    align-items: center;
    background: var(--grey-sur);
    width: fit-content;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur-hover);
    }

    .icon {
      height: 12px;
      width: 12px;
      margin-left: 8px;

      @include icons.ico("black-plus");
    }
  }
}