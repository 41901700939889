@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyHostsPicker {
  background: var(--grey-sur);
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  gap: 9px;

  @include texts.font("semi-bold");

  &:hover {
    background: var(--grey-sur-hover);
  }

  .entries {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    .entry {
      align-items: center;
      display: flex;
      gap: 8px;

      .color {
        height: 12px;
        width: 12px;
        background: red;
        border-radius: 4px;
      }

      span {
        margin-right: 10px;
      }

      .separator {
        background: var(--grey-sur-hover);
        width: 1px;
        height: 20px;
      }
    }
  }

  .arrow {
    margin-left: auto;
    height: 14px;
    width: 14px;
    flex: 0 0 14px;

    @include icons.ico("black-bottom");
  }
}