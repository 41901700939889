@use "../../../../sedestral/sass/mixins/outline";
@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/icons/icons";
@use "../../../../sedestral/sass/mixins/positioning";

.globalGifs {
  height: 100%;
  width: 100%;
  overflow: hidden;

  input {
    &::placeholder {
      color: var(--grey-text);
    }

    &:focus {
      outline: none;
    }
  }

  .searchContainer {
    border-bottom: var(--grey-sur) 1px solid;
    width: calc(100% - 15px);
    padding-left: 15px;
    height: 30px;

    .icon {
      height: 14px;
      width: 14px;
      margin-top: 7px;
      margin-right: 10px;
      float: left;
      filter: var(--icon-grey);

      @include icons.ico("black-search");
    }

    .input {
      font-size: 13px;
      height: 24px;
      padding-top: 3px;
      width: calc(100% - 40px);
      border: unset;
      padding-left: unset;
      float: left;
      color: var(--black);

      @include texts.font("semi-bold");
    }
  }

  .gridContainer {
    height: calc(100% - 30px);
    overflow: auto;

    .gridStretch {
      width: calc(100% - 30px);
      padding: 15px;
      overflow: hidden;
      -webkit-column-count: 2;
      -webkit-column-gap: 12px;
      -moz-column-count: 2;
      -moz-column-gap: 12px;
      column-count: 2;
      column-gap: 12px;

      .item {
        background: var(--grey-sur);
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        column-break-inside: avoid;
        margin-bottom: 12px;
        overflow: hidden;
        border-radius: 8px;
        height: fit-content;
        transition: 0.1s linear;

        &:hover {
          box-shadow: 0 5px 10px #00000029;
          transform: scale(1.03);
          cursor: pointer;
        }

        .image {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .searchContainer {
      .icon {
        height: 16px !important;
        width: 16px !important;
        margin-top: 5px !important;
      }

      .input {
        font-size: 15px !important;
      }
    }
  }

}