.pageBlog {
  height: 100%;
  width: 100%;
  position: absolute;

  .noPermissions {
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @media (max-width: 480px) {
    height: calc(100% - 58px);
  }
}