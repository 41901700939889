@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";

.componentRolePermission {
  border-bottom: var(--grey-sur) 1px solid;
  padding-bottom: 14px;
  margin-bottom: 12px;
  position: relative;

  &.inactive {
    opacity: 0.2;
    pointer-events: none;
  }

  .values {
    @include positioning.clear;

    .descriptions {
      width: calc(100% - 60px);
      float: left;

      .title {
        font-size: 14px;
        position: relative;

        @include texts.font("bold");
      }

      .description {
        font-size: 12px;
        margin-top: 2px;
      }
    }

    .label {
      float: right;
      position: absolute;
      height: 100%;
      right: 0;
      width: 42px;
      top: -5px;

      div[btnSwitch] {
        top: calc(50% - 12px);
        position: absolute;
      }
    }
  }
}