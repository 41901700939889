@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyClustersTopicComponent {
  height: 100%;

  .menuContainer {
    margin-bottom: 15px;
  }

  .bodyContainer {
    visibility: visible;
  }
}