@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/icons/icons";

.componentAccountMember {
  min-height: 52px;
  width: 100%;
  position: relative;
  border-bottom: var(--grey-sur) 1px solid;

  @include positioning.clear;


  .column {
    padding-left: 10px;
    padding-top: 10px;
    width: calc(36% - 11px);
    border-right: var(--grey-sur) 1px solid;
    height: 42px;
    position: relative;
    float: left;

    @include positioning.clear;

    &:last-child {
      border-right: 0 !important;
    }

    &.large {
      width: calc(28% - 11px);
    }

    &.edit {
      visibility: visible;
    }

    &.roles {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      padding-top: 10px;

      .rolesContainer {
        margin-top: 6px;
      }

      .ghostLine {
        margin-top: 12px;
      }
    }
  }

  .avatarContainer {
    height: 32px;
    width: 32px;
    position: relative;
    float: left;
  }

  .name {
    margin-left: 8px;
    margin-top: 6px;
    width: calc(100% - 48px);
    float: left;

    @include texts.ellipsis(1);
    @include texts.font("bold");
  }

  .mail {
    margin-top: 9px;
    font-size: 12px;
  }

  .buttons {
    width: 136px;
    margin-top: 1px;
    margin-right: 10px;
    position: relative;
    float: right;

    .largeButton {
      @include buttons.round;
      @include texts.font("bold");
      @include effects.hover-alpha;
      border: var(--grey-sur) 1px solid;
      border-radius: 20px;
      font-size: 13px;
      padding: 6px 14px;
      position: relative;
      float: right;

      &.RoleButton {
        visibility: visible;
      }

      .icon {
        height: 9px;
        width: 9px;
        top: 4px;
        margin-left: 6px;
        float: right;
        position: relative;

        @include icons.ico("black-plus");
      }
    }

    label {
      width: 50px;

      @include buttons.dropdown;

      .littleButton {
        height: 30px;
        margin-right: 5px;
        width: 30px;
        position: relative;
        float: right;

        @include positioning.flex-globally;
        @include buttons.round;
        @include effects.hover-alpha;

        .icon {
          height: 14px;
          width: 14px;
          @include icons.ico("black-details");
        }
      }

      ul, ol {
        right: 0;

        li {
          padding: 8px 10px !important;
          font-size: 12px;

          @include texts.font("bold");

          &.RemoveButton {
            visibility: visible;
          }
        }
      }
    }
  }

  .rolesContainer {
    width: 1000px;
    overflow: hidden;
  }

  .shadow {
    width: 0;
    top: 0;
    right: 0;
    box-shadow: var(--grey-shadow) 0 0 10px 17px;
    position: absolute;
    height: 100%;
  }

  .ghostCircle {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    float: left;
    background: var(--grey-sur);
  }

  .ghostLine {
    height: 10px;
    width: calc(100% - 70px);
    max-width: 120px;
    border-radius: 4px;
    margin-left: 10px;
    margin-top: 12px;
    float: left;
    position: relative;
    background: var(--grey-sur);
  }
}