@use "../../../../../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../../../../../sedestral/sass/mixins/outline";
@use "../../../../../../../../../../sedestral/sass/icons/icons";

.componentQuillFormattingImageTab {
  overflow: hidden;

  .buttons {
    margin-bottom: 10px;
    @include positioning.clear;

    .button {
      position: relative;
      background: var(--grey-sur);
      padding: 4px 5px;
      font-size: 13px;
      width: calc(50% - 13px);
      border-radius: 100px;
      cursor: pointer;
      text-align: center;


      @include texts.font("semi-bold");

      &.upload {
        float: right;
      }

      &.url {
        float: left;
      }

      div[visualselector] {
        top: unset;
        bottom: 0;
        height: 2px;
        border-radius: 2px;
        background: var(--blue-new-text);
      }
    }
  }

  .content {
    position: relative;
    min-height: 20px;
    overflow: hidden;
  }

  .searchImage {
    background: var(--grey-sur);
    padding: 8px 14px;
    font-size: 13px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: space-around;

    @include texts.font("semi-bold");

    .icon {
      height: 16px;
      width: 16px;
      filter: var(--icon-black);

      @include icons.ico("black-lineal-picture");
    }
  }

}