@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentCommunityImages {
  height: 100%;
  width: 100%;

  input {
    &::placeholder {
      color: var(--grey-text);
    }

    &:focus {
      outline: none;
    }
  }

  .searchContainer {
    height: 38px;
    background: var(--grey-sur);
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 25px;
    margin-bottom: 15px;
    width: 300px;
    padding: 0 12px;
    margin-top: 6px;

    .icon {
      height: 16px;
      width: 16px;
      filter: var(--icon-grey-dark);

      @include icons.ico("black-search");
    }

    .input {
      font-size: 15px;
      height: 100%;
      width: 100%;
      border: unset;
      padding-left: unset;
      background: unset;
      color: var(--black);

      @include texts.font("semi-bold");
    }
  }

  .gridContainer {
    height: calc(100% - 60px);

    div[scrolltrack] {
      right: -12px;
      top: 52px;
      height: calc(100% - 60px);
    }

    .gridStretch {
      width: 100%;
      overflow: hidden;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      &.selected {
        pointer-events: none;
      }

      .loader {
        height: 320px;
      }

      .empty {
        position: absolute;
        width: 100%;
        height: 80%;

        div[emptyText] {
          font-size: 18px;
        }

        .icon {
          height: 100px;
          width: 100px;

          @include icons.ico("3d-image");
        }
      }

      .item {
        background: var(--grey-sur);
        overflow: hidden;
        border-radius: 8px;
        height: fit-content;
        width: calc(50% - 6px);
        transition: 0.1s linear;
        cursor: pointer;
        position: relative;

        &:hover {
          opacity: 0.7;
        }

        &.selected {
          opacity: 1;

          .image {
            opacity: 0.3;
          }
        }

        .image {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .searchContainer {
      .icon {
        height: 16px !important;
        width: 16px !important;
        margin-top: 5px !important;
      }

      .input {
        font-size: 15px !important;
      }
    }
  }
}