@use "../../../../../../sedestral/sass/extends/buttons";
@use "../../../../../../sedestral/sass/mixins/positioning";
@import "../button-check";

.globalButtonCheckSwitch {
  width: 38px;
  padding: 2px;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;

  @include positioning.clear;

  &[checked] {
    background-color: var(--green);

    .caret {
      float: right;
    }
  }

  &[unchecked] {
    background-color: var(--grey-text);

    .caret {
      float: left;
    }
  }

  &.loading {
    background-color: var(--grey-thumb) !important;
  }

  .caret {
    height: 18px;
    width: 18px;
    border-radius: 100%;
    background: var(--white);
    box-shadow: 0 0 2px 2px #0000001a;
    transition: 1s;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--white-alpha);

    svg {
      height: 16px !important;
      width: 16px !important;

      circle {
        stroke: var(--black-alpha-middle) !important;
        stroke-width: 7px !important;
      }
    }
  }
}