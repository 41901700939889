@use "../../../../../../../../../sedestral/sass/icons/icons";

.globalQuillFormattingAlignment {
  .listEntry {
    padding: 6px;
    border-radius: 6px;
    margin-bottom: 2px;
    cursor: pointer;

    &.selected {
      visibility: visible;
    }

    &:hover {
      background: var(--grey-sur);
    }

    .icon {
      height: 14px;
      width: 14px;

      &.left {
        @include icons.ico("formatting-alignment-left");
      }

      &.right {
        @include icons.ico("formatting-alignment-right");
      }

      &.close {
        @include icons.ico("black-close-bold");
      }

      &.center {
        @include icons.ico("formatting-alignment-center");
      }
    }
  }
}