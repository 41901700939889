@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/icons/icons";

.componentAccountInvitationCreator {
  @include boxs.closable;
  height: 100%;
  width: 100%;
  box-shadow: unset;

  .headTop {
    .icon {
      @include icons.ico("black-close");
    }

    .CloseButton {
      visibility: visible;
    }
  }

  .rolesBlock {
    border-bottom: var(--grey-sur) 1px solid;
    height: 60px;
    @include positioning.clear;

    .label {
      font-size: 12px;
      min-width: 130px;
      float: left;
      height: 100%;
      position: relative;

      @include texts.font("bold");
      @include positioning.flex-vertically;
    }

    .rightSide {
      width: calc(100% - 130px);
      position: relative;
      float: left;
      height: 100%;
      @include positioning.flex-vertically;

      .rolesPicker {
        border: var(--grey-sur) 1px solid;
        font-size: 12px;
        border-radius: 8px;
        padding: 6px;
        width: 100%;
        cursor: pointer;

        @include texts.font("semi-bold");
        @include effects.hover-alpha;
      }
    }
  }

  .detailsBlock {
    margin-top: 16px;
    height: 80px;
    position: relative;

    .block {
      width: calc(50% - 5px);
      position: relative;

      .label {
        font-size: 12px;
        margin-bottom: 10px;

        @include texts.font("bold");
      }

      .selectBoxContainer {
        height: 30px;
        width: 100%;
      }

      &.left {
        float: left;
      }

      &.right {
        float: right;
      }
    }
  }

  .deskBlock {
    bottom: -13px;
    left: 17px;
    height: 100px;
    width: 100px;
    position: absolute;

    @include icons.ico("lineal-desk");
  }

  .bottomBlock {
    margin-top: 30px;

    .validateButton {
      height: 60px;
      width: 180px;
      font-size: 14px;
      border-radius: 8px;
      position: relative;
      float: right;

      @include buttons.blue-dark;
      @include texts.font("semi-bold");

      circle {
        stroke: var(--white);
      }

    }
  }
}