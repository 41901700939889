@use "../mixins/positioning";
@use "../mixins/texts";

.alt {
  .altTitle {
    background: var(--white);
    box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 20px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    padding: 5px 13px;
    font-size: 13px;
    bottom: -35px;
    transform: scale(1);
    opacity: 1;
    white-space: nowrap;
    z-index: 999999;
    color: var(--black);
    overflow-wrap: break-word;
    text-align: center;
    height: 22px;
    transition: opacity 0s !important;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    @include positioning.flex-vertically;
    @include texts.font("medium");

    hr {
      height: 1px;
      border: 0;
      background: var(--black-hr);
    }

    &:after {
      content: '';
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-bottom-color: var(--white);
      border-top: 0;
      margin-left: -6px;
      margin-top: -6px;
      position: absolute;
    }
  }

  &:hover {
    .altTitle {
      visibility: visible;
      opacity: 1;
      transition: 0.2s !important;
      transform: scale(1.0);
      bottom: -40px !important;
    }
  }
}

@mixin bottom {
  @extend .alt;
}

@mixin top {
  @extend .alt;

  &:hover {
    .altTitle {
      top: -45px;
    }
  }

  .altTitle {
    top: -40px;

    &:after {
      top: unset !important;
      bottom: -6px !important;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: var(--white) transparent transparent transparent;
    }
  }
}

@mixin right {
  @extend .alt;

  &:hover {
    .altTitle {
      left: calc(100% + 5px);
    }
  }

  .altTitle {
    left: calc(100% - 15px);
    top: 50% !important;
    margin-top: -10px;


    &:after {
      top: unset !important;
      right: -6px;
      border-style: solid;
      width: 0;
      height: 0;
      left: -6px;
      margin: unset;
      border-width: 6px 6px 6px 0;
      border-color: transparent var(--white) transparent transparent;
    }
  }
}

@mixin left {
  @extend .alt;

  &:hover {
    .altTitle {
      right: calc(100% + 5px);
      left: unset;
    }
  }

  .altTitle {
    right: calc(100% - 15px);
    top: 50% !important;
    margin-top: -10px;


    &:after {
      top: unset !important;
      right: -6px;
      border-style: solid;
      width: 0;
      height: 0;
      left: unset;
      margin: unset;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent var(--white);
    }
  }
}


@mixin leftImportant {
  @include left;
  .altTitle {
    &:after {
      top: unset !important;
      right: -6px !important;
      border-style: solid !important;
      width: 0 !important;
      height: 0 !important;
      left: unset !important;
      margin: unset !important;
      border-width: 6px 0 6px 6px !important;
      border-color: transparent transparent transparent var(--white) !important;
    }
  }
}