@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/outline";

.componentCommunityPageHome {

  .alerts {
    visibility: visible;
  }

  .cases {
    display: flex;
    gap: 15px;
    width: 100%;
    margin-bottom: 15px;

    .case {
      background: var(--grey-sur);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 25px 15px 15px 15px;
      min-height: 200px;
      width: calc(100% - 45px);
      position: relative;

      &.write {
        .icon {
          @include icons.ico("3d-write");
        }
      }

      &.idea {
        .icon {
          @include icons.ico("3d-idea");
        }
      }

      &.brainstorm {
        .icon {
          @include icons.ico("3d-brainstorm");
        }
      }

      &.cluster {
        .icon {
          @include icons.ico("3d-startup");
        }
      }

      &.wand {
        pointer-events: none;

        .icon {
          @include icons.ico("3d-wand");
        }
      }

      &.seo {
        .icon {
          @include icons.ico("3d-seo");
        }
      }

      &.rank {
        .icon {
          @include icons.ico("3d-statistics");
        }
      }

      &.publish {
        .icon {
          @include icons.ico("illus-letter");
        }
      }

      &.home {
        align-items: unset;
        min-height: unset;
        padding: 30px;
        width: calc(100% - 60px);

        &:hover {
          @include outline.outline(2);
        }

        .icon {
          width: 34px;
          height: 34px;
        }

        .title {
          text-align: left;
          font-size: 19px;
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .text {
          text-align: left;
          font-size: 14px;
        }
      }

      .soon {
        background: var(--red);
        color: var(--white);
        padding: 2px 4px;
        border-radius: 10px;
        width: fit-content;
        position: absolute;
        font-size: 12px;
        right: 25px;
        top: 20px;
      }

      .icon {
        height: 51px;
        width: 51px;
      }

      .title {
        font-size: 22px;
        text-align: center;

        @include texts.font("semi-bold");

        .arrow {
          height: 14px;
          width: 14px;
          display: inline-block;

          @include icons.ico("black-right-arrow");
        }
      }

      .text {
        text-align: center;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .cases {
      display: block;

      .case {
        width: calc(100% - 30px);
        margin-bottom: 20px;
      }
    }
  }
}