@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentClusterManageStats {
  display: flex;
  gap: 15px;

  .stat {
    font-size: 13px;
    border-right: 1px solid var(--grey-sur);
    padding-right: 15px;

    .title {
      color: var(--grey-text-dark);
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 10px;
    }

    .value {
      font-size: 18px;

      @include texts.font("semi-bold");
    }
  }
}