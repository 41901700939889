@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferManageView {
  display: flex;
  flex-direction: column;
  gap: 23px;
  min-height: 500px;
  position: relative;

  .button {
    cursor: pointer;
    height: 50px;
    background: var(--blue-mep);
    border-radius: 8px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    @include texts.font("semi-bold");
  }

  .top {
    background: var(--grey-sur);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 57px;
    width: calc(100% - 46px);
    border-radius: 10px;
    padding: 0 23px;

    .text {
      font-size: 16px;
      @include texts.font("semi-bold");
    }

    .date {
      padding: 9px 15px;
      background: var(--white);
      border-radius: 8px;
      font-size: 16px;
      @include texts.font("semi-bold");
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 13px;

    .title {
      font-size: 20px;
      @include texts.font("semi-bold");
    }

    .items {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 14px;
      flex-wrap: wrap;

      .item {
        @include boxs.white;
        width: calc(33% - 39.3px);
        min-width: 190px;
        height: 100%;
        padding: 0 15px;
        border-radius: 15px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;

        .informations {
          margin-top: 16px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;


          .title {
            font-size: 18px;
            @include texts.font("semi-bold");
          }
        }

        .bottom {
          margin-bottom: 15px;

          .value {
            font-size: 30px;
            @include texts.font("semi-bold");
          }

          .progressContainer {
            height: 6px;
            width: 100%;
            position: relative;
            border-radius: 3px;
            overflow: hidden;
          }
        }
      }
    }
  }
}