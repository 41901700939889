@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyRankTrackArticle {
  padding: 15px;
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  width: calc(33.33% - 46px);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @include outline.blue-hover(2);

  .head {
    display: flex;
    align-items: center;
    width: 100%;

    .details {
      width: 100%;

      .titles {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 18px;
        width: 100%;

        .title {
          font-size: 17px;

          @include texts.font("semi-bold");

          .link {
            display: inline-block;
            height: 12px;
            width: 12px;

            @include icons.ico("black-maximize");
          }
        }

        .index {
          border-radius: 20px;
          padding: 2px 10px 2px 2px;
          font-size: 12px;
          display: flex;
          align-items: center;
          gap: 5px;
          margin-left: auto;
          flex-shrink: 0;

          &.indexed {
            color: var(--white);
            background: var(--green);
          }

          &.notIndexed {
            color: var(--white);
            background: var(--orange);
          }

          &.error {
            color: var(--white);
            background: var(--red);
          }

          .google {
            height: 18px;
            width: 18px;
            background: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;

            .icon {
              height: 14px;
              width: 14px;

              @include icons.ico("colored-google");
            }
          }
        }
      }

      .labels {
        font-size: 13px;
        display: flex;
        justify-content: space-between;

        span {
          color: var(--grey-text-dark);
        }
      }
    }
  }

  .entries {
    margin-bottom: 20px;

    .entry {
      font-size: 13px;
      border-bottom: 1px solid var(--grey-sur);
      padding: 12px 0;
      display: flex;
      align-items: center;

      .positions {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 5px;

        .position {
          height: 22px;
          width: 22px;
          background: var(--grey-sur);
          border-radius: 8px;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include texts.font("semi-bold");
        }

        .arrow {
          height: 16px;
          width: 16px;

          &.progress {
            filter: var(--icon-green);

            @include icons.ico("black-top-arrow");
          }

          &.less {
            filter: var(--icon-red);

            @include icons.ico("black-bottom-arrow");
          }
        }
      }
    }
  }

  .googleComment {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;

    .label {
      font-size: 12px;
      color: var(--grey-text-dark);
    }

    .description {
      font-size: 13px;
    }
  }

  .bottom {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: auto;

    .buttons {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 12px;
        color: var(--grey-text-dark);
      }

      .right {
        display: flex;
        margin-left: auto;
        gap: 8px;
      }

      .button {
        background: var(--black);
        color: var(--white);
        font-size: 14px;
        display: flex;
        gap: 10px;
        padding: 0 12px;
        height: 40px;
        align-items: center;
        border-radius: 30px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &.internal {
          padding: 0 13px;

          .icon {
            @include icons.ico("black-view");
          }
        }

        .icon {
          height: 14px;
          width: 14px;
          filter: var(--icon-white);

          @include icons.ico("black-right-arrow");
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: calc(100% - 32px);
  }
}