@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyContent {
  height: 100%;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;

  div[scrolltrack] {
    right: 4px;
    top: 4px;
    height: calc(100% - 10px);
  }

  div[menuTab] {
    margin-bottom: 15px;
  }

  .container {
    height: 100%;
    width: 100%;
  }

  .descriptions {
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .loader {
      width: 100%;
      max-width: 300px;
      height: 40px;
      position: relative;
    }

    .backTitle {
      display: flex;
      gap: 10px;
      align-items: center;

      .backButton {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 100%;

        &:hover {
          background: var(--grey-sur);
        }

        .icon {
          height: 14px;
          width: 14px;

          @include icons.ico("black-left-arrow");
        }
      }

      .text {
        font-size: 20px;

        @include texts.font("bold");
      }
    }

    .title {
      font-size: 16px;

      @include texts.font("semi-bold");
    }

    .description {
      font-size: 14px;
    }

    .notifications {
      display: flex;
      gap: 10px;
      align-items: center;

      .switch {
        width: 50px;
      }

      .description {
        margin-top: 2px;
      }
    }
  }

  .hosts {
    width: fit-content;
    position: relative;
    margin-bottom: 15px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .date {
      border: 1px solid var(--grey-sur);
      height: 28px;
      width: 230px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      margin-left: auto;
      cursor: pointer !important;
    }

    div[loaderblock] {
      width: 300px;
      height: 40px;
    }
  }

  .body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .grid {
      .line {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        .cards {
          width: 100%;

          .card:first-child {
            margin-bottom: 10px;
          }
        }

        .card {
          padding: 12px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid var(--grey-sur);

          .head {
            border-bottom: 1px solid var(--grey-sur);
            margin-bottom: 10px;
            font-size: 20px;
            padding-bottom: 10px;

            @include texts.font("semi-bold");
          }

          .content {
            border-bottom: 1px solid var(--grey-sur);
            padding-bottom: 5px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .empty {
      height: 300px;

      &.emptyClusters {
        height: fit-content !important;
        position: relative;
        width: 100%;

        div[emptytext] {
          font-size: 20px;

          @include texts.font("semi-bold");
        }

        .icon {
          height: 244px;
          width: 610px;

          @include icons.ico("illus-clusters");
        }
      }

      .icon {
        height: 200px;
        width: 200px;

        @include icons.ico("animated-goals");
      }

      .iconDart {
        height: 80px;
        width: 80px;

        @include icons.ico("3d-dart");
      }

      .error {
        height: 200px;
        width: 200px;

        @include icons.ico("3d-dart");
      }
    }

    .loading {
      height: 100%;
      position: absolute;
      width: calc(100% - 30px);
    }

    .keywords {
      padding: 12px;
      border: 1px solid var(--grey-sur);
      border-radius: 14px;

      .title {
        font-size: 20px;
        border-bottom: 1px solid var(--grey-sur);
        margin-bottom: 10px;
        padding-bottom: 10px;

        @include texts.font("semi-bold");
      }
    }
  }

  .history {
    border-top: 1px solid var(--grey-sur);
    width: 420px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;

    .loader {
      height: 150px;
      position: relative;
    }

    .top {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 5px;
      padding-bottom: 10px;

      @include texts.font("semi-bold");

      .icon {
        width: 18px;
        height: 18px;

        @include icons.ico("black-history");
      }
    }

    .list {
      margin-left: -5px;

      .entry {
        cursor: pointer;
        border-radius: 10px;
        padding: 8px 5px;

        &:hover {
          background: var(--grey-sur);
        }
      }
    }
  }

  .buttons {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: var(--white);
    left: 0;
    padding: 15px 0px;
    border-top: 1px solid var(--grey-sur);

    .create {
      display: flex;
      border-radius: 30px;
      margin-right: 90px;
      height: 52px;
      background: var(--black);
      color: var(--white);
      padding: 0 20px;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        opacity: 0.8;
      }

      .icon {
        height: 16px;
        margin-left: 10px;
        width: 16px;
        filter: var(--icon-white);

        @include icons.ico("black-plus");
      }

      circle {
        stroke: var(--white);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .body {
      .keywords {
        margin-bottom: 50px;
      }

      .line {
        flex-direction: column;
      }
    }

    .clusters {

    }

    div[keywords] {
      margin-bottom: 50px;
    }
  }
}