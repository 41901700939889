@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageTabs {

  &.contained {
    .content {
      height: calc(100% - 51px);
      position: relative;
    }
  }

  .head {
    height: 49px;
    width: 100%;
    border-bottom: 1px solid var(--grey-sur);
    display: flex;
    align-items: center;

    .brand {
      display: flex;
      gap: 10px;
      margin-right: 10px;
      align-items: center;
      margin-left: 15px;

      .logo {
        height: 30px;
        width: 30px;

        &.seo {
          @include icons.ico("3d-dart");
        }

        &.ai {
          @include icons.ico("3d-ai");
        }

        &.blog {
          width: 26px;
          height: 26px;

          @include icons.ico("3d-ai-writing");
        }
      }

      .name {
        font-size: 18px;

        @include texts.font("semi-bold");
      }

      .separator {
        height: 25px;
        width: 1px;
        background: var(--grey-sur);
        margin-left: 13px;
      }
    }

    .buttons {
      display: flex;
      gap: 5px;

      .button {
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 10px;

        @include texts.font("semi-bold");

        &[checked] {
          background: var(--grey-sur);

          &:hover {
            background: var(--grey-sur-hover);
          }
        }

        &.keywords, &.rank, &.autopilot, &.style, &.gsc, &.blog, &.rankKeywords, &.home, &.context, &.know, &.clusters, &.cannibalization {
          visibility: visible;
        }

        &:hover {
          background: var(--grey-sur);
        }

        .new {
          background: var(--red);
          color: var(--white);
          padding: 3px 3px;
          border-radius: 4px;
          font-size: 8px;
        }

        .icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100% - 50px);
    position: relative;
  }

  @media screen and (max-width: 480px) {
    .head {
      height: fit-content;
      display: block;

      .brand {
        display: none;
      }

      .buttons {
        padding: 10px;
        overflow: auto;

        .button {
          white-space: nowrap;
        }
      }
    }
  }
}