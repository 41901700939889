@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentStepper {
  height: 100%;
  width: 100%;
  border-radius: 38px;
  background: var(--grey-sur);
  display: flex;

  &.mini {
    .number {
      width: 28px !important;
      height: 28px !important;
      flex: 0 0 28px !important;
      margin-left: 6px !important;
    }

    .text {
      font-size: 15px !important;
      margin-left: 10px !important;
    }

    .icon {
      height: 16px !important;
      width: 16px !important;
      flex: 0 0 16px !important;
      margin-left: 10px !important;
    }
  }

  .step {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 38px;
    cursor: pointer;

    &:hover {
      background: var(--grey-sur);
    }

    &.disabled {
      pointer-events: none;
    }

    &.selected {
      background: var(--grey-sur);

      &:hover {
        background: var(--grey-sur-hover);
      }

      .number {
        background: var(--black);
        color: var(--white);
      }
    }

    &.proposals {
      .icon {
        @include icons.ico("black-paragraph");
      }
    }

    &.sections {
      .icon {
        @include icons.ico("black-section");
      }
    }

    &.config {
      .icon {
        @include icons.ico("black-blog");
      }
    }

    &.style {
      .icon {
        @include icons.ico("black-style");
      }
    }

    &.article {
      .icon {
        @include icons.ico("black-document");
      }
    }

    &.writing {
      .icon {
        @include icons.ico("black-pensil");
      }
    }

    &.rules {
      .icon {
        @include icons.ico("black-rules");
      }
    }

    &.keywords {
      .icon {
        height: 18px;
        flex: 0 0 18px;
        width: 18px;

        @include icons.ico("black-tag");
      }
    }

    .number {
      width: 30px;
      height: 30px;
      margin-left: 8px;
      flex: 0 0 30px;
      background: var(--grey-alpha-half);
      color: var(--black);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }

    .icon {
      margin-left: 10px;
      height: 20px;
      width: 20px;
      flex: 0 0 20px;
    }

    .text {
      margin-left: 15px;
      color: var(--black);
      font-size: 16px;

      @include texts.font("semi-bold");
    }
  }

  @media screen and (max-width: 480px) {
    .step {
      .text {
        display: none;
      }
    }
  }
}