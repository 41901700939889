@use "../../../../sedestral/sass/mixins/texts";
@use "../../../../sedestral/sass/mixins/positioning";
@use "../../../../sedestral/sass/extends/buttons";
@use "../../../../sedestral/sass/icons/icons";

.visualCollapse {
  .top {
    height: 35px;
    font-size: 16px;
    border-bottom: var(--grey-sur) 1px solid;
    padding-left: 15px;
    position: relative;

    @include texts.font("bold");
    @include positioning.flex-vertically;

    .collapseButton {
      height: 20px;
      width: 20px;
      right: 5px;
      position: absolute;

      @include positioning.flex-globally;
      @include buttons.round;

      .icon {
        height: 12px;
        width: 12px;
        transition: 0.2s;

        @include icons.ico("black-bottom");
      }
    }
  }

  .visualBody {
    position: relative;
  }
}