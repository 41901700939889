@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityArticleAiSections {
  visibility: visible;
  position: relative;
  overflow: hidden;
}

.componentCommunityArticleAiSectionsLineSortable {
  border-color: transparent;
  transition: 0s !important;
  background: var(--white) !important;
}

.componentCommunityArticleAiSectionsLine {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  &.collapsed {
    .context {
      display: none;
    }

    .reduce {
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .line {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    &.context {
      margin-top: 15px;
    }

    .bar {
      position: absolute;
      height: 100%;
      width: 6px;
      background: var(--grey-sur);
      margin-left: 6px;
      border-radius: 8px;
    }

    .draggable {
      height: 18px;
      cursor: grabbing;
      width: 18px;
      flex: 0 0 18px;
      filter: var(--icon-grey-dark);

      @include icons.ico("black-draggable");
    }

    .inputContainer, .inputKeywordsContainer {
      height: 46px;
      position: relative;

      input {
        border-width: 1px;
        border-radius: 16px;

        @include texts.font("semi-bold");
      }
    }

    .buttons {
      display: flex;

      .button {
        width: 44px;
        height: 44px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur);
        }

        &.delete {
          .icon {
            @include icons.ico("black-delete-light");
          }
        }

        &.reduce {
          .icon {
            width: 16px;
            height: 16px;
            transition: 0.1s;

            @include icons.ico("black-bottom");
          }
        }

        .icon {
          height: 20px;
          width: 20px;
        }
      }
    }

    .editorContainer {
      margin-left: 28px;
      width: fit-content;

      .label {
        font-size: 13px;
        margin-bottom: 5px;
      }


      .editor, .keywords {
        height: fit-content;
        width: fit-content;
        min-width: 300px;
        border: 1px solid var(--grey-sur);
        border-radius: 16px;
        margin-bottom: 10px;
        overflow: hidden;

        &.keywords {
          border: 0;

          input {
            border-radius: 20px;
          }
        }

        div[editable] {
          width: calc(100% - 28px);
          padding-right: 14px;
          margin-left: 0;
          padding-left: 14px;
          word-break: break-word;
        }
      }
    }
  }
}

