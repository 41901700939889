@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/icons/icons";

.componentAvatarPrompt {
  height: 100%;
  width: 100%;

  &.mini {
    .editButton {
      height: 18px;
      width: 18px;

      .icon {
        height: 10px;
        width: 10px;
      }
    }
  }

  .avatarContainer {
    display: block;
  }

  .editButton {
    height: 26px;
    bottom: -10px;
    right: -5px;
    width: 26px;
    background-clip: padding-box;
    border-radius: 100%;
    border: 2px solid var(--grey-alpha);
    background: var(--white);
    cursor: pointer;
    position: absolute;

    @include positioning.flex-globally;

    &.delete {
      left: -5px;
      right: unset;

      .icon {
        @include icons.ico("black-delete-light");
      }
    }

    input {
      opacity: 0;
      cursor: pointer;
    }

    .icon {
      height: 12px;
      width: 12px;
      z-index: 9;
      pointer-events: none;
      position: absolute;

      @include icons.ico("black-edit");
    }
  }
}