@use "src/sedestral/sass/mixins/texts";

.componentCommunityPageStrategyRankingKeywords {
  .menu {
    display: flex;
    gap: 15px;

    .color {
      height: 12px;
      width: 12px;
      background: red;
      border-radius: 4px;
      margin: 5px 6px 0 0;
    }

    .button {
      border-bottom: 3px solid #0000;
      display: flex;
      padding: 10px 0px;
      margin-bottom: 10px;
      cursor: pointer;
      flex-shrink: 0;

      @include texts.font("semi-bold");

      &:hover {
        border-bottom: 3px solid var(--grey-sur);
      }

      &[checked] {
        border-bottom: 3px solid var(--grey-sur-hover);
      }
    }
  }

  .container {
    visibility: visible;
  }

  @media screen and (max-width: 480px) {
    .menu {
      width: 100%;
      overflow-x: auto;
    }
  }
}