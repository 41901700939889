@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentContentScoreTermsDetails {
  margin-top: 15px;
  height: 100%;

  div[scrolltrack] {
    right: -15px;
  }

  .loader {
    height: 200px;
    position: relative;
  }

  .stats {
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 10px;
    margin-bottom: 15px;
  }

  .samples {
    .title {
      font-size: 13px;
      margin-bottom: 15px;
    }

    .entries {
      .entry {
        border-bottom: 1px solid var(--grey-sur);
        padding-bottom: 10px;
        margin-bottom: 10px;

        .names {
          display: flex;
          align-items: center;
          font-size: 16px;
          gap: 5px;
          margin-bottom: 10px;

          @include texts.font("semi-bold");

          img {
            width: 14px;
            height: 14px;
          }

          .right {
            margin-left: auto;
            display: flex;

            .density {
              border-left: 1px solid var(--grey-sur);
              padding-left: 10px;
              border-right: 1px solid var(--grey-sur);
              padding-right: 10px;
              margin-right: 10px;
              margin-left: 10px;

              .label {
                font-size: 12px;
                color: var(--grey-text-dark);

                @include texts.font("medium");
              }

              .value{
                font-size: 14px;
              }
            }
          }

          .semi {
            height: 28px;
            width: 50px;
            top: -2px;
            position: relative;
          }
        }

        .parts {
          .part {
            display: flex;
            margin-bottom: 15px;

            .left {
              width: 105px;
              flex: 0 0 105px;

              .tag {
                border: 1px solid var(--grey-sur);
                font-size: 12px;
                width: fit-content;
                padding: 4px 10px;
                border-radius: 6px;

                @include texts.font("semi-bold");
              }
            }

            .content {
              font-size: 14px;

              strong {
                font-weight: unset;
                background: var(--orange-light);
              }
            }
          }
        }
      }
    }
  }
}