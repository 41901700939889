@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyCannibalizationKeyord {
  padding: 15px;
  border: 1px solid var(--grey-sur);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: calc(100% - 38px);
  margin-left: 3px;
  margin-bottom: 15px;

  @include outline.blue-hover(2);

  &.modal {
    padding: 0;
    border: unset;
    width: 100%;
    margin: 0;
    outline: none !important;
    box-shadow: none !important;

    .titles {
      display: none !important;
    }
  }

  .head {
    display: flex;
    align-items: center;
    width: 100%;

    .details {
      width: 100%;

      .titles {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        width: 100%;

        .title {
          font-size: 17px;

          @include texts.font("semi-bold");
        }
      }

      .labels {
        font-size: 13px;
        display: flex;
        justify-content: space-between;

        span {
          color: var(--grey-text-dark);
        }
      }
    }
  }

  .entries {
    margin-bottom: 10px;

    .entry {
      font-size: 13px;
      border-bottom: 1px solid var(--grey-sur);
      padding: 10px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .link {
        width: 12px;
        display: inline-block;
        height: 12px;
        position: relative;
        top: 2px;

        @include icons.ico("black-maximize");
      }

      .positions {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 5px;

        .position {
          height: 22px;
          width: 22px;
          background: var(--grey-sur);
          border-radius: 8px;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include texts.font("semi-bold");
        }

        .arrow {
          height: 16px;
          width: 16px;

          &.progress {
            filter: var(--icon-green);

            @include icons.ico("black-top-arrow");
          }

          &.less {
            filter: var(--icon-red);

            @include icons.ico("black-bottom-arrow");
          }
        }
      }
    }
  }

  .bottom {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: auto;

    .buttons {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;

      .button {
        background: var(--black);
        color: var(--white);
        font-size: 14px;
        display: flex;
        gap: 10px;
        padding: 0 12px;
        height: 30px;
        align-items: center;
        border-radius: 30px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        .icon {
          height: 14px;
          width: 14px;
          filter: var(--icon-white);

          @include icons.ico("black-right-arrow");
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}