@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyRankTrackArticleManage {
  border-radius: 14px;
  height: 100%;

  div[scrolltrack] {
    right: -15px;
  }

  .head {
    display: flex;
    align-items: center;
    margin-top: 15px;
    border-bottom: 1px solid var(--grey-sur);
    padding-bottom: 15px;
    margin-bottom: 15px;

    .details {
      margin-right: 10px;
    }
  }

  .google {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--grey-sur);
    gap: 4px;
    display: flex;
    flex-direction: column;

    .names {
      display: flex;
      font-size: 16px;
      gap: 8px;
      align-items: center;

      @include texts.font("semi-bold");

      .iconGoogle {
        height: 14px;
        width: 14px;

        @include icons.ico("colored-google");
      }

      .iconError {
        filter: var(--icon-red);
        height: 14px;
        width: 14px;
        @include icons.ico("black-close");
      }
    }

    .description {
      font-size: 13px;

      @include texts.font("medium");
    }
  }

  .entries {
    margin-bottom: 90px;
    margin-top: 15px;
  }
}