@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentClusterManage {
  border-radius: 14px;
  height: 100%;

  div[scrolltrack] {
    right: -15px;
  }

  .head {
    display: flex;
    align-items: center;

    .details {
      margin-right: 10px;

      .semi {
        height: 36px;
        width: 36px;
        position: relative;
        margin-top: -13px;
      }
    }

    .semiContainer {
      margin-left: auto;
      flex: 0 0 80px;

      .semi {
        height: 80px;
        width: 80px;
        flex: 0 0 80px;
      }
    }
  }

  .entries {
    margin-bottom: 90px;
    margin-top: 15px;
  }

  .bottom {
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: auto;
    position: absolute;
    bottom: 0;
    background: var(--white);
    width: 100%;
    padding-top: 15px;
    border-top: 1px solid var(--grey-sur);

    .selects {
      transform: translateY(100px);

      .name {
        font-size: 15px;
      }

      .numbers {
        font-size: 14px;

        .number {
          font-size: 20px;

          @include texts.font("semi-bold");
        }
      }
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 15px;
      align-items: center;

      .separator {
        height: 25px;
        border-right: 1px solid var(--grey-sur);
      }

      .button {
        background: var(--black);
        color: var(--white);
        font-size: 14px;
        display: flex;
        gap: 10px;
        padding: 0 12px;
        height: 40px;
        align-items: center;
        border-radius: 30px;
        margin-left: auto;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &.createButton {
          visibility: visible;
        }

        &.competitorsButton, &.autopilotButton {
          background: var(--white);
          border: 1px solid var(--grey-sur);
          color: var(--black);
          position: relative;

          &:hover {
            border-color: transparent;
            background: var(--grey-sur);
            opacity: 1;
          }

          &.autopilotButton {
            display: none;

            &.notIncluded {
              display: flex;
            }

            &.included {
              display: flex;

              .arrow {
                height: 14px;
                width: 14px;
                filter: var(--icon-green-flash);

                @include icons.ico("black-checked");
              }
            }

            .icon {
              filter: unset;
              height: 22px;
              width: 22px;

              @include icons.ico("3d-ai");
            }

            .arrow {
              height: 14px;
              width: 14px;
              filter: var(--icon-blue-light);

              @include icons.ico("black-top-arrow");
            }
          }

          .icon {
            height: 18px;
            width: 18px;
            filter: var(--icon-black);

            @include icons.ico("black-statistics");
          }
        }

        .icon {
          height: 18px;
          width: 18px;
          filter: var(--icon-white);

          @include icons.ico("black-write-ai");
        }
      }
    }

    .buttonSave {
      position: absolute;
      background: var(--white);
      width: 100%;
      padding: 10px 0;
      transform: translateY(100px);
    }
  }

  .competitors {
    transform: translateY(700px);
    background: var(--white);
    width: 100%;
    position: absolute;
    max-height: 100%;
    bottom: 0;
    border-top: 1px solid var(--grey-sur);
    font-size: 15px;
    height: 460px;
    margin-left: -20px;
    z-index: 10;
    margin-bottom: -20px;
    padding: 20px;
    box-shadow: 0px -20px 59px #0000002b;

    .title {
      font-size: 18px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      @include texts.font("semi-bold");
    }

    .close {
      height: 28px;
      width: 28px;
      margin-left: auto;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-close");
      }
    }
  }

  @media screen and (max-width: 480px) {
    .bottom {

      .buttons {
        .icon {
          flex: 0 0 18px;
        }
      }

      .selects {
        .name {
          display: none;
        }
      }
    }

    .entry {
      flex-wrap: wrap;
    }
  }
}