@use "../../../../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../../../../sedestral/sass/mixins/outline";
@use "../../../../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../../../../sedestral/sass/extends/buttons";
@use "../../../../../../../../../sedestral/sass/icons/icons";

.globalQuillFormattingLink {

  .inputContainer {
    margin-bottom: 6px;

    input {
      border-radius: 10px;
      font-size: 12px;
      padding-left: 24px;
      border-width: 1px;
      height: 38px;

      &.Text {
        visibility: visible;
      }

      &.LinkInput {
        visibility: visible;
      }
    }

    .icon {
      position: absolute;
      height: 10px;
      width: 10px;
      margin-top: 13.5px;
      margin-left: 8px;
      filter: var(--icon-grey-dark);

      &.link {
        @include icons.ico("black-link");
      }

      &.edit {
        @include icons.ico("black-edit");
      }
    }
  }

  .buttons {
    margin-top: 15px;

    @include positioning.clear;

    .roundButton {
      height: 26px;
      width: 26px;
      border-radius: 100%;
      float: right;
      margin-top: 4px;
      margin-right: 8px;

      @include positioning.flex-globally;
      @include buttons.round;

      &:hover {
        .icon {
          filter: var(--icon-black);
        }
      }

      .icon {
        height: 12px;
        width: 12px;
        filter: var(--icon-grey-dark);


        &.copy {
          @include icons.ico("black-copy");
        }

        &.remove {
          height: 15px;
          width: 15px;
          @include icons.ico("black-delete-light");
        }
      }
    }

    .validateButton {
      padding: 6px 18px;
      float: right;
      border-radius: 22px;
      font-size: 13px;
      color: var(--white);
      cursor: pointer;
      transition: 0.1s;
      border: 2px solid var(--grey-sur);
      background: var(--green);

      @include texts.font("semi-bold");

      &:hover {
        background: var(--green-hover);
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 10px !important;

    .inputContainer {
      .icon {
        margin-top: 15px !important;
      }

      input {
        height: 40px !important;
        font-size: 15px !important;
      }
    }
  }
}