.visualSortableChosen {
  border-color: transparent;
  transition: 0s !important;
  box-shadow: rgba(0, 0, 0, 0.03) 0 0 0 1px, rgba(0, 0, 0, 0.2) 0 0 20px 0 !important;
}

.visualSortableGhost {
  opacity: 0 !important;
}

.visualSortableFallback {
  opacity: 1 !important;
}