@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStrategyClustersTopicListComponent {

  .cover {
    margin-bottom: 15px;
  }

  .clustersContainer {
    gap: 20px;
    display: flex;
    padding: 2px;
    flex-wrap: wrap;
    margin-bottom: 100px;
  }
}