@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";


.componentCommunityPageKnowCreator {
  margin-top: 20px;
  flex-direction: column;
  display: flex;
  gap: 15px;

  input {
    font-size: 15px !important;
    border-width: 1px !important;
  }

  .name {
    height: 50px;
    position: relative;
  }

  .names {
    display: flex;
    align-items: center;

    .label {
      font-size: 15px;

      @include texts.font("semi-bold");
    }
  }

  .sources {
    border: 1px solid var(--grey-sur);
    border-radius: 15px;
    height: 300px;

    div[emptyBasic] {
      cursor: pointer;
    }

    .source {
      display: flex;
      align-items: center;
      gap: 12px;
      border-bottom: 1px solid var(--grey-sur);
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;

      .icon {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;

        &.link {
          @include icons.ico("black-link");
        }

        &.attachment {
          @include icons.ico("black-attachment");
        }

        &.document {
          @include icons.ico("black-lineal-document");
        }
      }

      .sourceName {
        font-size: 14px;
      }

      .delete {
        height: 24px;
        flex: 0 0 24px;
        width: 24px;
        margin-left: auto;
        margin-right: 12px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid var(--grey-sur);

        &:hover {
          background: var(--grey-sur-hover);
        }

        .icon {
          width: 14px;
          height: 14px;

          @include icons.ico("black-delete-light");
        }
      }
    }

    .emptyIcon {
      height: 120px;
      width: 120px;

      @include icons.ico("3d-upload-media");
    }
  }

  .button {
    background: var(--grey-sur);
    color: black;
    display: flex;
    width: fit-content;
    margin-left: auto;
    padding: 12px 25px;
    border-radius: 30px;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    position: relative;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur-hover);
    }

    .icon {
      height: 12px;
      width: 12px;
      margin-left: auto;

      @include icons.ico("black-plus");
    }

    .subButtons {
      position: absolute;
      width: 180px;
      bottom: calc(100% + 10px);
      right: 0;
      border-radius: 15px;
      font-size: 14px;
      display: none;
      overflow: hidden;

      @include boxs.sur;

      .subButton {
        display: flex;
        align-items: center;
        padding: 10px;
        gap: 10px;
        border-bottom: 1px solid var(--grey-sur);

        &:hover {
          background: var(--grey-sur);
        }

        &.link {
          .icon {
            @include icons.ico("black-link");
          }
        }

        &.attachment {
          .icon {
            @include icons.ico("black-attachment");
          }
        }

        &.document {
          .icon {
            @include icons.ico("black-lineal-document");
          }
        }

        .icon {
          height: 16px;
          width: 16px;
          margin-left: unset;
        }
      }
    }
  }
}