@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/mixins/positioning";

.globalQuillLimits {
  width: 100%;
}

.globalQuillLimit {
  margin-bottom: 6px;
  position: relative;

  .limitHead {
    @include positioning.clear;

    .channelIconContainer {
      height: 12px;
      width: 12px;
      margin-top: 3px;
      position: relative;
      margin-left: 4px;
      float: left;
    }

    .text {
      font-size: 11px;
      margin-left: 5px;
      margin-top: 3px;
      float: left;

      @include texts.font("semi-bold");
    }
  }

  .progression {
    height: 2px;
    margin-top: 4px;
    border-radius: 30px;
    margin-left: 4px;
    width: calc(100% - 8px);
    background: var(--grey-sur);

    .progress {
      width: 40%;
      height: 100%;
      background: var(--black);
      position: relative;
    }
  }
}