@use "../../../../../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../../../../../sedestral/sass/extends/buttons";
@use "../../../../../../../../../../sedestral/sass/mixins/texts";

.componentQuillFormattingImageUpload {
  .icon {
    height: 60px;
    width: 60px;
    margin-left: calc(50% - 30px);
    margin-top: 10px;

    @include icons.ico("3d-image");
  }

  .insert {
    font-size: 14px;
    padding: 6px 0;
    width: 100%;
    border-radius: 15px;
    text-align: center;
    margin-top: 20px;
    @include texts.font("semi-bold");
    @include buttons.grey;
  }

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    height: 100%;
    opacity: 0;
  }

}