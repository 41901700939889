@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStyleCreator {
  margin-top: 20px;

  input {
    font-size: 15px !important;
    border-width: 1px !important;
  }

  .name {
    height: 50px;
    position: relative;
  }

  .editor {
    height: 240px;
    border-radius: 12px;
    border: 1px solid var(--grey-sur);
  }

  .label {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .buttonsContainer {
    margin-top: 15px;
  }
}