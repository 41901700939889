@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

.componentClusterManageList {
  .entry {
    font-size: 13px;
    border-bottom: 1px solid var(--grey-sur);
    padding: 12px 0;
    display: flex;
    align-items: center;
    gap: 10px;

    .selector {
      width: 16px;

      &.mainKeyword {
        div[btnCheckbox] {
          &[checked] {
            background: var(--orange);
          }
        }
      }

      div[btnCheckbox] {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        padding: 0;
        justify-content: center;
        border-radius: 8px;

        &[checked] {
          background: var(--black);

          div[btncheckboxcaret] {
            filter: var(--icon-white);
          }
        }

        &:not([checked]) {
          &:hover {
            border-color: var(--grey-sur-hover);

            div[btncheckboxcaret] {
              filter: var(--icon-grey-dark);
            }
          }
        }

        div[btncheckboxcaret] {
          filter: var(--icon-grey);
          width: 10px;
          height: 10px;

          @include icons.ico("black-checked");
        }
      }
    }

    .keywordName {
      width: 283px;
      flex: 0 0 283px;
      display: flex;
      gap: 5px;

      &.full {
        flex: unset;
        width: 100%;
      }

      .addKeyword {
        display: flex;
        width: fit-content;
        align-items: center;
        padding: 6px 10px;
        gap: 10px;
        border-radius: 15px;
        margin-left: -10px;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur-hover);
        }

        .icon {
          height: 10px;
          width: 10px;

          @include icons.ico("black-plus");
        }
      }

      .main {
        flex: none;
        height: 16px;
        width: 16px;
        filter: var(--icon-orange);

        @include icons.ico("black-star");
      }

      .sedestral {
        height: 16px;
        width: 16px;
        position: relative;
      }
    }

    .label {
      color: var(--grey-text-dark);
      text-transform: uppercase;
      width: 90px;
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 0 0 90px;

      &.hidden {
        visibility: hidden;
      }

      &.intention {
        width: 90px;
        flex: 0 0 90px;
      }

      &.competition {
        width: 90px;
        flex: 0 0 90px;
      }

      &.position {
        flex: 0 0 115px;
        width: 115px;
      }

      &.vol {
        width: 115px;
        flex: 0 0 115px;
      }

      .volumes {
        width: 105px;
        height: 34px;

        .onChart {
          text-transform: none !important;
          display: flex;

          .lastValue {
            color: rgba(0, 0, 0, 0.67);
            display: flex;
            align-items: center;
            margin-right: 5px;
            font-size: 13px;
            width: 36px;
          }

          .chart {
            width: 64px;
            height: 34px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            &:hover {
              overflow: visible;
            }

            .chartContainer {
              height: 100px;
              width: 70px;
              position: absolute;
              margin-left: -10px;
            }
          }
        }
      }

      .progress {
        height: 36px;
        width: 36px;
      }

      .icon {
        height: 16px;
        width: 16px;
        filter: var(--icon-grey-very-dark);
      }
    }

    .buttons {
      width: 68px;
      display: flex;
      gap: 5px;
      justify-content: flex-end;
      margin-left: auto;
      align-items: center;

      .button {
        height: 20px;
        width: 20px;
        cursor: pointer;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--grey-sur);
        }

        &.delete {
          .icon {
            @include icons.ico("black-delete-light");
          }
        }

        &.competitionIcon {
          .icon {
            @include icons.ico("black-lineal-statistics");
          }
        }

        &.hidden {
          visibility: hidden;
        }

        .icon {
          height: 14px;
          width: 14px;
          filter: var(--icon-grey-very-dark);
        }
      }

      .coverContainer {
        height: 16px;
        width: 16px;
        position: relative;
        flex: 0 0 16px;
        overflow: hidden;
        border-radius: 100%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .entry {
      flex-wrap: wrap;
    }
  }
}