@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityArticleAiRules {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 120px;

  .steps {
    height: 40px;
    margin-bottom: 10px;
  }

  .know {
    border: 1px solid var(--grey-sur);
    padding: 20px;
    margin-bottom: 22px;
    border-radius: 15px;
  }

  .param {
    margin-bottom: 22px;

    .label {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @include texts.font("semi-bold");

      .dot {
        height: 8px;
        width: 14px;
        background: var(--black);
        border-radius: 8px;
        margin-right: 8px;
      }
    }

    .description {
      font-size: 13px;
      margin-bottom: 15px;
    }

    .select {
      height: 44px;

      div[data-selectbox="true"] {
        border: 1px solid var(--grey-sur);
      }
    }
  }

  @media screen and (max-width: 480px) {
    .sample {
      gap:15px;
      flex-wrap: wrap;
    }
  }

  .sample {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 15px;

    .label {
      margin-bottom: 0;
    }

    .button, .buttonLink {
      background: var(--grey-sur);
      border-radius: 38px;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 6px 10px 6px 6px;
      margin-left: auto;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur-hover);
      }

      .round {
        height: 26px;
        margin-right: 15px;
        width: 26px;
        background: var(--grey-sur-hover);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          height: 18px;
          width: 18px;

          @include icons.ico("3d-ai");
        }
      }
    }
  }

  .buttonLink {
    .iconLink {
      height: 18px;
      width: 18px;

      @include icons.ico("3d-link");
    }
  }

  .editor {
    height: 150px;
    padding: 10px 2px;
    border: 1px solid var(--grey-sur);
    border-radius: 10px;
    margin-bottom: 22px;
  }
}