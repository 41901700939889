@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/extends/editor";

.componentEditorDocs {
  overflow: hidden;

  &.published {
    .head {
      .left {
        pointer-events: none;
      }
    }

    .details {
      pointer-events: none;
    }

    div[editablecontainer] {
      height: calc(100% - 200px);
    }
  }

  div[formatting] {
    border-top: unset !important;
    margin-top: 0 !important;
  }

  div[editablecontainer] {
    height: calc(100% - 135px);
    display: flex;
    align-items: center;

    @include editor.tagsDocs;


    div[editabletb] {
      background: var(--white);
      z-index: 9;
      width: 100%;
    }

    div[editable] {
      width: calc(100% - 80px);
      max-width: 800px;
      margin-top: 0px;
      margin-left: 0;
      font-size: 15px;
      margin-bottom: unset;

      div[scrolltrack] {
        right: -15px;
      }

      div[qleditor] {
        height: unset;
        min-height: calc(100% - 50px);
        padding-bottom: 50px;
        padding-left: 4px;
        width: calc(100% - 4px);
      }

      div[data-placeholder]::before {
        margin-top: 17.5px !important;
      }
    }

    div[formatting] {
      button {
        height: 32px;
        min-width: 32px;
      }
    }
  }

  .head {
    padding-bottom: 6px;
    padding-top: 12px;
    padding-left: 5px;
    display: flex;
    z-index: 9;
    position: relative;
    background: var(--white);
    gap: 10px;

    .left {
      width: 100%;

      .tag {
        border: 1px solid var(--grey-sur);
        padding: 2px 10px;
        font-size: 12px;
        border-radius: 6px;
        margin-right: 5px;
      }

      .title {
        display: flex;
        margin-left: 5px;
        align-items: center;

        .saving {
          position: relative;
          margin-left: 10px;
          height: 20px;
          width: 20px;

          .icon {
            height: 100%;
            width: 100%;
            filter: var(--icon-grey-dark);

            @include icons.ico("black-uploaded");
          }
        }
      }

      .label {
        display: flex;
        margin-bottom: 0;
        margin-top: 7px;

        .tag {
          margin-left: 5px;
          margin-right: 0;
        }

        input {
          border: unset !important;
          font-size: 15px;
        }

        .round {
          height: 18px;
          flex: 0 0 18px;
          border-radius: 8px;
          margin-left: 8px;
          margin-top: 2px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            height: 12px;
            width: 12px;

            &.link {
              @include icons.ico("black-link");
            }

            &.description {
              @include icons.ico("black-pensil");
            }

            &.document {
              @include icons.ico("black-lineal-document");
            }
          }
        }

        .route, .snippet {
          display: block;
        }
      }
    }

    .right {
      margin-left: auto;
      display: flex;
      margin-right: 8px;
      flex-direction: column;
      align-items: flex-end;
      height: fit-content;
      border-left: 1px solid var(--grey-sur);
      padding-left: 8px;

      .zone {
        display: flex;
        margin-bottom: 5px;
        width: 100%;
        min-width: 180px;

        &.statesContainer {
          .button {
            white-space: nowrap;
            padding: 0;

            &:hover {
              background: unset;
            }

            div[data-selectbox] {
              border-radius: 16px;
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              border: unset !important;
            }
          }
        }

        &.alternateContainer {
          visibility: visible;
        }

        &.exportContainer {
          flex-direction: row-reverse;
          justify-content: flex-end;
          visibility: visible;
          gap: 5px;

          .download {
            width: fit-content;
            justify-content: center;

            .icon {
              height: 14px;
              width: 14px;
              margin-left: 8px;
              margin-right: 8px;

              @include icons.ico("black-download");
            }
          }

          .play {

            width: fit-content;
            justify-content: center;

            .icon {
              height: 14px;
              width: 14px;
              margin-left: 8px;
              margin-right: 8px;

              @include icons.ico("black-maximize");
            }
          }
        }

        .button {
          border: 1px solid var(--grey-sur);
          height: 30px;
          width: 100%;
          border-radius: 22px;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            background: var(--grey-sur);
            border-color: transparent;
          }

          &.locked {
            border-color: transparent;
            background: var(--black);
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 10px !important;

            &:hover {
              background: var(--black);
            }
          }

          &.collaborators {
            padding-left: 4px;
            justify-content: flex-end;

            &:hover {
              border: 1px solid var(--grey-sur);
              background: transparent;
            }
          }

          .name {
            margin-top: 1px;
            margin-left: 10px;

            @include texts.font("semi-bold");
          }
        }
      }
    }
  }

  .details {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 12px;
    position: relative;
    background: var(--white);
    z-index: 9;

    .button {
      border: 1px solid var(--grey-sur);
      border-radius: 20px;
      padding: 6px 10px;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 22px;

      &:hover {
        border-color: transparent;
        background: var(--grey-sur);
      }

      &.image {
        .icon {
          @include icons.ico("black-media");
        }
      }

      &.writers {
        .icon {
          @include icons.ico("black-user");
        }
      }

      .text {
        visibility: visible;
      }

      .icon {
        height: 14px;
        width: 14px;
        flex: 0 0 14px;
        margin-right: 8px;
      }

      .names {
        max-width: 185px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        margin-right: 5px;

        @include texts.font("semi-bold");
      }
    }
  }

  .invisibleScreen {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 12;

    .container {
      background: linear-gradient(360deg, white, white, transparent);
      width: 100%;
      bottom: 0;
      height: 100px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      &.error {
        height: 250px;

        .empty {
          height: 90px;
          width: 90px;
          border-radius: 100%;
          background: var(--grey-sur-opac);
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: -1px -8px 27px 10px var(--white);

          .icon {
            height: 60px;
            width: 60px;

            &.ai {
              @include icons.ico("3d-ai");
            }

            &.error {
              @include icons.ico("colored-status-notsolved");
            }
          }
        }
      }

      &.finished {
        height: 135px;
        transform: translateY(160px);

        .icon {
          height: 60px;
          width: 60px;
          @include icons.ico("colored-status-solved");
        }
      }

      .typingContainer {
        height: 50px;
        background: var(--white);
        padding: 0px 20px;
        border-radius: 30px;
        box-shadow: 0px 0px 0px 2px var(--outline-blue);
      }
    }
  }

  .revision {
    height: 100%;
    width: 100%;
    background: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;

    .icon {
      height: 200px;
      width: 200px;

      @include icons.ico("animated-search");
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--white);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .published {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid var(--grey-sur);
    transform: translateY(200px);
    z-index: 10;


    .icon {
      width: 48px;
      height: 48px;
      position: relative;
      margin-bottom: 10px;
      border-radius: 100%;
      overflow: hidden;
    }

    .text {
      width: 90%;
      font-size: 16px;
      text-align: center;
      position: relative;

      @include texts.font("semi-bold");
    }

    .description {
      width: 90%;
      font-size: 14px;
      text-align: center;
      position: relative;
    }

    div[btns] {
      border-radius: 50px;
      font-size: 15px;
      margin-top: 18px;
      background: var(--grey-sur);
      color: var(--black);

      &:hover {
        background: var(--grey-sur-hover);
      }

      circle {
        stroke: var(--black) !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .head {
      .left {
        display: none;
      }

      .right {
        width: 100%;
        margin-left: 12px;
      }
    }

    .details {
      display: none;
    }

    div[editablecontainer] {

      div[editabletb] {
        display: none;
      }

      div[editable] {
        left: 4%;
        width: 92%;
      }
    }
  }
}