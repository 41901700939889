@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentDocumentExplorerThree {
  width: 100%;
}

.componentDocumentExplorerEntry {
  position: relative;

  &.reduced {
    div[data-list] {
      display: none;
    }
  }

  .componentDocumentExplorerEntry {
    padding-left: 15px;
  }

  .folderContainer {
    visibility: visible;
  }

  .list {
    visibility: visible;
  }
}

.componentDocumentExplorerChosen {
  border-color: transparent;
  transition: 0s !important;
  box-shadow: rgba(0, 0, 0, 0.03) 0 0 0 1px, rgba(0, 0, 0, 0.2) 0 0 20px 0 !important;
  background: var(--white);
  border-radius: 8px;
  padding-left: 6px !important;

  div[data-folder] {
    border: unset !important;
    background: var(--white) !important;
  }

  .list {
    display: none;
  }
}

.componentDocumentExplorerGhost {
  background: transparent;
  border-color: transparent;
  box-shadow: unset !important;
  position: relative;

  &:after {
    content: "";
    background: var(--outline-blue);
    height: 5px;
    border-radius: 5px;
    width: 100%;
    position: absolute;
    top: calc(50% - 2.5px);
  }

  * {
    opacity: 0;
  }
}

.componentDocumentExplorerFallback {
  opacity: 1 !important;

  div[data-folder] {
    margin-bottom: 2px !important;
    margin-top: 0 !important;
  }
}