@use "src/sedestral/sass/mixins/texts";

.componentCommunityLimits {
  margin-bottom: 8px;
  border: 1px solid var(--grey-sur);
  padding: 10px 8px 0 8px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .entry {
    &.limit {
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 8px;
    }

    &.frequencies {
      visibility: visible;
    }

    .title {
      font-size: 13px;
      margin-bottom: 2px;

      @include texts.font("semi-bold");
    }

    .barContainer {
      height: 4px;
      border-radius: 8px;
      overflow: hidden;
    }

    .frequency {
      display: flex;
      align-items: center;

      .description {
        font-size: 12px;
        margin-right: 10px;
      }

      .circularContainer {
        height: 50px;
        width: 50px;
        position: relative;
        margin-left: auto;
      }
    }
  }

  @media (max-width: 480px) {
    .frequencies {
      display: none;
    }
  }
}