@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/mixins/effects";
@use "../../../../../sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";

[published] .formatting {
  pointer-events: none !important;
}

.componentEditor {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: var(--white);
  z-index: 5;

  .editionContainer {
    height: 100%;
    display: flex;
    flex-direction: column;

    .editable {
      height: 100%;
      width: calc(100% - 28px);
      margin-left: 14px;
      margin-top: 8px;
      position: relative;
      font-size: 13.5px;
      min-height: 32px;
      margin-bottom: 8px;
    }

    .toolbarPosition {
      .toolbar {

        &.fixed {
          position: fixed;
          background: var(--white);
          z-index: 9;
          border-radius: 14px 14px 0 0;
          overflow: hidden;

          @include outline.outline(2);

          .toolbarContent {
            background: var(--white);
            border-bottom: 1px solid var(--grey-sur);
          }

          .formattingContainer {
            .formatting {
              margin-top: 0 !important;
              border-top: unset !important;
              padding-top: 10px !important;
            }
          }
        }

        .toolbarContent {
          .formattingContainer {
            display: none;

            .formatting {
              padding-bottom: 6px;
              padding-left: 5px;
              width: calc(100% - 5px);
              border-bottom: 1px solid var(--grey-sur);
              border-top: 1px solid var(--grey-sur);
              padding-top: 6px;
              margin-top: 15px;
            }
          }

          .buttonsContainer {
            display: flex;

            .leftButtons {
              display: flex;

              .button {
                margin-left: 6px;

                &.unCollapse {
                  display: none;


                  .icon {
                    @include icons.ico("black-left-bold");
                  }
                }

                &.privateButton {
                  &.notPrivate {
                    .icon {
                      @include icons.ico("black-open-lock");
                    }
                  }

                  &.private {
                    .icon {
                      @include icons.ico("black-lock");
                    }
                  }
                }

                &.gptButton {
                  .icon {
                    @include icons.ico("black-open-lock");
                  }
                }
              }
            }

            .rightButtons {
              display: flex;
              margin-left: auto;
              margin-right: 7px;
              flex-direction: row-reverse;

              .button {
                margin-right: 6px;
              }
            }

            .button {
              height: 26px;
              width: 26px;
              border-radius: 100%;
              margin-top: 3px;
              position: relative;
              overflow: hidden;

              @include buttons.round;
              @include positioning.flex-globally;
              @include effects.hover-alpha;

              &.locked {
                pointer-events: none;
                background: var(--grey-sur);
                color: var(--grey-text);
              }

              &.sendButton {
                visibility: visible;
              }

              &.sendLargeButton {
                visibility: visible;
                height: 26px;
                margin-top: 1px;
                padding-top: 1px;
                padding-bottom: 1px;
                width: fit-content;
                position: relative;
                display: flex;
                margin-left: 6px;
                font-size: 13px;
                border-radius: 6px;

                @include texts.font("semi-bold");

                &:hover {
                  background: unset;
                }

                &:active {
                  box-shadow: unset;
                }

                .sendContent {
                  height: 100%;
                  padding-left: 9px;
                  border: 1px solid var(--grey-sur);
                  padding-right: 9px;
                  border-radius: 6px 0 0 6px;

                  @include positioning.flex-globally;


                  svg {
                    margin-left: -18px;
                    height: 18px !important;

                    circle {
                      stroke-width: 4px;
                    }
                  }

                  &:hover {
                    border-color: transparent;
                    background: var(--grey-sur);
                  }
                }

                .dropContent {
                  width: 26px;
                  height: 100%;
                  border: 1px solid var(--grey-sur);
                  border-radius: 0 6px 6px 0;
                  border-left: unset;

                  @include positioning.flex-globally;

                  &:hover {
                    border-color: transparent;
                    background: var(--grey-sur);
                  }

                  .icon {
                    height: 12px;
                    width: 12px;

                    @include icons.ico("black-bottom-bold");
                  }

                }
              }

              input {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
              }

              .icon {
                height: 16px;
                width: 16px;
                filter: var(--icon-black);

                &.integration {
                  @include icons.ico("black-integration");
                }

                &.attachment {
                  @include icons.ico("black-attachment");
                }

                &.settings {
                  @include icons.ico("black-settings");
                }

                &.emojis {
                  @include icons.ico("black-emojis");
                }

                &.microphone {
                  @include icons.ico("black-microphone");
                }

                &.send {
                  @include icons.ico("black-send");
                }

                &.shortcuts {
                  @include icons.ico("black-shortcuts");
                }
              }
            }
          }
        }
      }
    }
  }

  .emptyContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: var(--white);
    border-radius: 16px;

    .loader {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 40%;
    }

    .empty {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 60%;
      z-index: 3;


      .icon {
        height: 70px;
        width: 70px;

        @include icons.ico("3d-locked");
      }
    }
  }

  &.private {
    background-color: var(--private);
    border-color: transparent !important;

    .privateButton {
      .icon {
        filter: invert(72%) sepia(88%) saturate(1051%) hue-rotate(328deg) brightness(103%) contrast(101%) !important;
      }
    }
  }
}