@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStyleLimit {
  margin-bottom: 20px;

  .barContainer {
    position: relative;
    height: 6px;
    max-width: 300px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 5px;
  }
}