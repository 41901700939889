@use "sass:map";

$icons: (
        "black-score-top":"/src/sedestral/sass/icons/images/black-score-top.svg",
        "black-score-average":"/src/sedestral/sass/icons/images/black-score-average.svg",
        "black-replace":"/src/sedestral/sass/icons/images/black-replace.svg",
        "black-fire":"/src/sedestral/sass/icons/images/black-fire.svg",
        "black-pos-competition":"/src/sedestral/sass/icons/images/black-pos-competition.svg",
        "black-pos-track":"/src/sedestral/sass/icons/images/black-pos-track.svg",
        "black-trophy":"/src/sedestral/sass/icons/images/black-trophy.svg",
        "black-podium":"/src/sedestral/sass/icons/images/black-podium.svg",
        "black-ai-write":"/src/sedestral/sass/icons/images/black-ai-write.svg",
        "black-ai-up":"/src/sedestral/sass/icons/images/black-ai-up.svg",
        "black-tag":"/src/sedestral/sass/icons/images/black-tag.svg",
        "black-rules":"/src/sedestral/sass/icons/images/black-rules.svg",
        "black-purpose":"/src/sedestral/sass/icons/images/black-purpose.svg",
        "black-tone":"/src/sedestral/sass/icons/images/black-tone.svg",
        "black-voice":"/src/sedestral/sass/icons/images/black-voice.svg",
        "black-wix":"/src/sedestral/sass/icons/images/black-wix.svg",
        "black-shopify":"/src/sedestral/sass/icons/images/black-shopify.svg",
        "black-brightness":"/src/sedestral/sass/icons/images/black-brightness.svg",
        "black-editable":"/src/sedestral/sass/icons/images/black-editable.svg",
        "black-section":"/src/sedestral/sass/icons/images/black-section.svg",
        "black-paragraph":"/src/sedestral/sass/icons/images/black-paragraph.svg",
        "black-regen":"/src/sedestral/sass/icons/images/black-regen.svg",
        "black-export":"/src/sedestral/sass/icons/images/black-export.svg",
        "black-download":"/src/sedestral/sass/icons/images/black-download.svg",
        "black-help-center":"/src/sedestral/sass/icons/images/black-help-center.svg",
        "black-uploaded":"/src/sedestral/sass/icons/images/black-uploaded.svg",
        "black-blog":"/src/sedestral/sass/icons/images/black-blog.svg",
        "black-menu-reduce":"/src/sedestral/sass/icons/images/black-menu-reduce.svg",
        "black-canals":"/src/sedestral/sass/icons/images/black-canals.svg",
        "black-story":"/src/sedestral/sass/icons/images/black-story.svg",
        "black-post":"/src/sedestral/sass/icons/images/black-post.svg",
        "black-reel":"/src/sedestral/sass/icons/images/black-reel.svg",
        "black-phone":"/src/sedestral/sass/icons/images/black-phone.svg",
        "black-website":"/src/sedestral/sass/icons/images/black-website.svg",
        "black-language":"/src/sedestral/sass/icons/images/black-language.svg",
        "black-location":"/src/sedestral/sass/icons/images/black-location.svg",
        "black-mobile-menu":"/src/sedestral/sass/icons/images/black-mobile-menu.svg",
        "black-gender":"/src/sedestral/sass/icons/images/black-gender.svg",
        "black-call":"/src/sedestral/sass/icons/images/black-call.svg",
        "black-reduce":"/src/sedestral/sass/icons/images/black-reduce.svg",
        "black-instagram":"/src/sedestral/sass/icons/images/black-instagram.svg",
        "black-gif":"/src/sedestral/sass/icons/images/black-gif.svg",
        "black-close":"/src/sedestral/sass/icons/images/black-close.svg",
        "black-trending":"/src/sedestral/sass/icons/images/black-trending.svg",
        "black-file":"/src/sedestral/sass/icons/images/black-file.svg",
        "black-menu":"/src/sedestral/sass/icons/images/black-menu.svg",
        "black-locker":"/src/sedestral/sass/icons/images/black-locker.svg",
        "black-clock":"/src/sedestral/sass/icons/images/black-clock.svg",
        "black-plus":"/src/sedestral/sass/icons/images/black-plus.svg",
        "black-star":"/src/sedestral/sass/icons/images/black-star.svg",
        "black-checked-light":"/src/sedestral/sass/icons/images/black-checked-light.svg",
        "black-company":"/src/sedestral/sass/icons/images/black-company.svg",
        "black-home":"/src/sedestral/sass/icons/images/black-home.svg",
        "black-mute":"/src/sedestral/sass/icons/images/black-mute.svg",
        "black-sort":"/src/sedestral/sass/icons/images/black-sort.svg",
        "black-select-all":"/src/sedestral/sass/icons/images/black-select-all.svg",
        "black-attachment":"/src/sedestral/sass/icons/images/black-attachment.svg",
        "black-microphone":"/src/sedestral/sass/icons/images/black-microphone.svg",
        "black-shortcuts":"/src/sedestral/sass/icons/images/black-shortcuts.svg",
        "black-link":"/src/sedestral/sass/icons/images/black-link.svg",
        "black-link-light":"/src/sedestral/sass/icons/images/black-link-light.svg",
        "black-reminder":"/src/sedestral/sass/icons/images/black-reminder.svg",
        "black-emojis":"/src/sedestral/sass/icons/images/black-emojis.svg",
        "black-reload":"/src/sedestral/sass/icons/images/black-reload.svg",
        "black-status-solved":"/src/sedestral/sass/icons/images/black-status-solved.svg",
        "black-statistics":"/src/sedestral/sass/icons/images/black-statistics.svg",
        "black-channels":"/src/sedestral/sass/icons/images/black-channels.svg",
        "black-warning":"/src/sedestral/sass/icons/images/black-warning.svg",
        "black-settings":"/src/sedestral/sass/icons/images/black-settings.svg",
        "black-maximize":"/src/sedestral/sass/icons/images/black-maximize.svg",
        "black-glasses":"/src/sedestral/sass/icons/images/black-glasses.svg",
        "black-wait":"/src/sedestral/sass/icons/images/black-wait.svg",
        "black-draggable":"/src/sedestral/sass/icons/images/black-draggable.svg",
        "black-bottom":"/src/sedestral/sass/icons/images/arrow/bottom/black-bottom.svg",
        "black-left-bold":"/src/sedestral/sass/icons/images/arrow/left/black-left-bold.svg",
        "black-right-bold":"/src/sedestral/sass/icons/images/arrow/right/black-right-bold.svg",
        "black-top-bold":"/src/sedestral/sass/icons/images/arrow/top/black-top-bold.svg",
        "black-top":"/src/sedestral/sass/icons/images/arrow/top/black-top.svg",
        "black-bottom-bold":"/src/sedestral/sass/icons/images/arrow/bottom/black-bottom-bold.svg",
        "black-bottom-drop":"/src/sedestral/sass/icons/images/arrow/bottom/black-bottom-drop.svg",
        "black-left":"/src/sedestral/sass/icons/images/arrow/left/black-left.svg",
        "black-right-slide":"/src/sedestral/sass/icons/images/arrow/right/black-right-slide.svg",
        "black-right":"/src/sedestral/sass/icons/images/arrow/right/black-right.svg",
        "black-top-slide":"/src/sedestral/sass/icons/images/arrow/top/black-top-slide.svg",
        "black-bottom-arrow":"/src/sedestral/sass/icons/images/arrow/bottom/black-bottom-arrow.svg",
        "black-left-arrow":"/src/sedestral/sass/icons/images/arrow/left/black-left-arrow.svg",
        "black-right-arrow":"/src/sedestral/sass/icons/images/arrow/right/black-right-arrow.svg",
        "black-top-arrow":"/src/sedestral/sass/icons/images/arrow/top/black-top-arrow.svg",
        "black-conversations":"/src/sedestral/sass/icons/images/black-conversations.svg",
        "black-block":"/src/sedestral/sass/icons/images/black-block.svg",
        "black-block-light":"/src/sedestral/sass/icons/images/black-block-light.svg",
        "black-join":"/src/sedestral/sass/icons/images/black-join.svg",
        "black-feed":"/src/sedestral/sass/icons/images/black-feed.svg",
        "black-more":"/src/sedestral/sass/icons/images/black-more.svg",
        "black-folder":"/src/sedestral/sass/icons/images/black-folder.svg",
        "black-boolean":"/src/sedestral/sass/icons/images/black-boolean.svg",
        "black-mail":"/src/sedestral/sass/icons/images/black-mail.svg",
        "black-user-add":"/src/sedestral/sass/icons/images/black-user-add.svg",
        "black-calendar":"/src/sedestral/sass/icons/images/black-calendar.svg",
        "black-logo":"/src/sedestral/sass/icons/images/black-logo.svg",
        "black-flash":"/src/sedestral/sass/icons/images/black-flash.svg",
        "black-mention":"/src/sedestral/sass/icons/images/black-mention.svg",
        "black-bot":"/src/sedestral/sass/icons/images/black-bot.svg",
        "black-livechat":"/src/sedestral/sass/icons/images/black-livechat.svg",
        "black-operator-contains":"/src/sedestral/sass/icons/images/black-operator-contains.svg",
        "black-operator-greater":"/src/sedestral/sass/icons/images/black-operator-greater.svg",
        "black-operator-is":"/src/sedestral/sass/icons/images/black-operator-is.svg",
        "black-operator-is-not":"/src/sedestral/sass/icons/images/black-operator-is-not.svg",
        "black-operator-less":"/src/sedestral/sass/icons/images/black-operator-less.svg",
        "black-operator-not-contains":"/src/sedestral/sass/icons/images/black-operator-not-contains.svg",
        "black-operator-not-exist":"/src/sedestral/sass/icons/images/black-operator-not-exist.svg",
        "black-operator-exist":"/src/sedestral/sass/icons/images/black-operator-exist.svg",
        "black-operator-interval":"/src/sedestral/sass/icons/images/black-operator-interval.svg",
        "black-operator-after":"/src/sedestral/sass/icons/images/black-operator-after.svg",
        "black-operator-before":"/src/sedestral/sass/icons/images/black-operator-before.svg",
        "black-operator-asc":"/src/sedestral/sass/icons/images/black-operator-asc.svg",
        "black-operator-desc":"/src/sedestral/sass/icons/images/black-operator-desc.svg",
        "black-operator-startswith":"/src/sedestral/sass/icons/images/black-operator-startswith.svg",
        "black-operator-endswith":"/src/sedestral/sass/icons/images/black-operator-endswith.svg",
        "black-operator-contains-all":"/src/sedestral/sass/icons/images/black-operator-contains-all.svg",
        "black-mind":"/src/sedestral/sass/icons/images/black-mind.svg",
        "black-close-bold":"/src/sedestral/sass/icons/images/black-close-bold.svg",
        "black-bottom-double":"/src/sedestral/sass/icons/images/black-bottom-double.svg",
        "black-all-included":"/src/sedestral/sass/icons/images/black-all-included.svg",
        "black-pause":"/src/sedestral/sass/icons/images/black-pause.svg",
        "black-spam":"/src/sedestral/sass/icons/images/black-spam.svg",
        "black-play":"/src/sedestral/sass/icons/images/black-play.svg",
        "black-volume":"/src/sedestral/sass/icons/images/black-volume.svg",
        "black-bold-user":"/src/sedestral/sass/icons/images/black-bold-user.svg",
        "black-bold-edit":"/src/sedestral/sass/icons/images/black-bold-edit.svg",
        "black-stop":"/src/sedestral/sass/icons/images/black-stop.svg",
        "black-recorder":"/src/sedestral/sass/icons/images/black-recorder.svg",
        "black-upload":"/src/sedestral/sass/icons/images/black-upload.svg",
        "black-roles":"/src/sedestral/sass/icons/images/black-roles.svg",
        "black-copy":"/src/sedestral/sass/icons/images/black-copy.svg",
        "black-members":"/src/sedestral/sass/icons/images/black-members.svg",
        "black-user-list":"/src/sedestral/sass/icons/images/black-user-list.svg",
        "black-checked":"/src/sedestral/sass/icons/images/black-checked.svg",
        "black-routes":"/src/sedestral/sass/icons/images/black-routes.svg",
        "black-details":"/src/sedestral/sass/icons/images/black-details.svg",
        "black-search":"/src/sedestral/sass/icons/images/black-search.svg",
        "black-facebook":"/src/sedestral/sass/icons/images/black-facebook.svg",
        "black-draft":"/src/sedestral/sass/icons/images/black-draft.svg",
        "black-stamp":"/src/sedestral/sass/icons/images/black-stamp.svg",
        "black-filters":"/src/sedestral/sass/icons/images/black-filters.svg",
        "black-dashboard":"/src/sedestral/sass/icons/images/black-dashboard.svg",
        "black-list":"/src/sedestral/sass/icons/images/black-list.svg",
        "black-products":"/src/sedestral/sass/icons/images/black-products.svg",
        "black-card":"/src/sedestral/sass/icons/images/black-card.svg",
        "black-euro":"/src/sedestral/sass/icons/images/black-euro.svg",
        "black-invoice":"/src/sedestral/sass/icons/images/black-invoice.svg",
        "black-notification":"/src/sedestral/sass/icons/images/black-notification.svg",
        "black-login":"/src/sedestral/sass/icons/images/black-login.svg",
        "black-session":"/src/sedestral/sass/icons/images/black-session.svg",
        "black-edit":"/src/sedestral/sass/icons/images/black-edit.svg",
        "black-duplicate":"/src/sedestral/sass/icons/images/black-duplicate.svg",
        "black-delete":"/src/sedestral/sass/icons/images/black-delete.svg",
        "black-view":"/src/sedestral/sass/icons/images/black-view.svg",
        "black-email":"/src/sedestral/sass/icons/images/black-email.svg",
        "black-calendar-bold":"/src/sedestral/sass/icons/images/black-calendar-bold.svg",
        "black-marketing":"/src/sedestral/sass/icons/images/black-marketing.svg",
        "black-sell":"/src/sedestral/sass/icons/images/black-sell.svg",
        "black-style":"/src/sedestral/sass/icons/images/black-style.svg",
        "black-mailling":"/src/sedestral/sass/icons/images/black-mailling.svg",
        "black-estimate":"/src/sedestral/sass/icons/images/black-estimate.svg",
        "black-box":"/src/sedestral/sass/icons/images/black-box.svg",
        "black-document":"/src/sedestral/sass/icons/images/black-document.svg",
        "black-media":"/src/sedestral/sass/icons/images/black-media.svg",
        "black-publisher":"/src/sedestral/sass/icons/images/black-publisher.svg",
        "black-inbox":"/src/sedestral/sass/icons/images/black-inbox.svg",
        "black-help":"/src/sedestral/sass/icons/images/black-help.svg",
        "black-less":"/src/sedestral/sass/icons/images/black-less.svg",
        "black-inbox-icon":"/src/sedestral/sass/icons/images/black-inbox-icon.svg",
        "black-circle-check":"/src/sedestral/sass/icons/images/black-circle-check.svg",
        "black-circle-close":"/src/sedestral/sass/icons/images/black-circle-close.svg",
        "black-not-view":"/src/sedestral/sass/icons/images/black-not-view.svg",
        "black-all":"/src/sedestral/sass/icons/images/black-all.svg",
        "black-disconnect":"/src/sedestral/sass/icons/images/black-disconnect.svg",
        "black-integration":"/src/sedestral/sass/icons/images/black-integration.svg",
        "black-user":"/src/sedestral/sass/icons/images/black-user.svg",
        "black-chart":"/src/sedestral/sass/icons/images/black-chart.svg",
        "black-send":"/src/sedestral/sass/icons/images/black-send.svg",
        "black-delete-light":"/src/sedestral/sass/icons/images/black-delete-light.svg",
        "black-color":"/src/sedestral/sass/icons/images/black-color.svg",
        "black-smile":"/src/sedestral/sass/icons/images/black-smile.svg",
        "black-resize":"/src/sedestral/sass/icons/images/black-resize.svg",
        "black-lock":"/src/sedestral/sass/icons/images/black-lock.svg",
        "black-open-lock":"/src/sedestral/sass/icons/images/black-open-lock.svg",
        "black-history":"/src/sedestral/sass/icons/images/black-history.svg",
        "black-zigzag":"/src/sedestral/sass/icons/images/black-zigzag.svg",
        "black-moon":"/src/sedestral/sass/icons/images/black-moon.svg",
        "black-reply":"/src/sedestral/sass/icons/images/black-reply.svg",
        "black-reply-all":"/src/sedestral/sass/icons/images/black-reply-all.svg",
        "black-forward":"/src/sedestral/sass/icons/images/black-forward.svg",
        "black-exclamation":"/src/sedestral/sass/icons/images/black-exclamation.svg",
        "black-pensil":"/src/sedestral/sass/icons/images/black-pensil.svg",
        "black-trombone":"/src/sedestral/sass/icons/images/black-trombone.svg",
        "black-vertical-list":"/src/sedestral/sass/icons/images/black-vertical-list.svg",
        "black-house":"/src/sedestral/sass/icons/images/black-house.svg",
        "black-bubble":"/src/sedestral/sass/icons/images/black-bubble.svg",
        "black-computer":"/src/sedestral/sass/icons/images/black-computer.svg",
        "black-mobile":"/src/sedestral/sass/icons/images/black-mobile.svg",
        "black-world":"/src/sedestral/sass/icons/images/black-world.svg",
        "black-target":"/src/sedestral/sass/icons/images/black-target.svg",
        "black-ai-stars":"/src/sedestral/sass/icons/images/black-ai-stars.svg",
        "black-context":"/src/sedestral/sass/icons/images/black-context.svg",
        "black-ai-writing":"/src/sedestral/sass/icons/images/black-ai-writing.svg",
        "black-star-fod":"/src/sedestral/sass/icons/images/black-star-fod.svg",
        "black-write-ai":"/src/sedestral/sass/icons/images/black-write-ai.svg",
        "black-refresh":"/src/sedestral/sass/icons/images/black-refresh.svg",
        "black-speedometer":"/src/sedestral/sass/icons/images/black-speedometer.svg",
  /****/
        "black-lineal-blocks":"/src/sedestral/sass/icons/images/black-lineal-blocks.svg",
        "black-lineal-statistics":"/src/sedestral/sass/icons/images/black-lineal-statistics.svg",
        "black-line-chart":"/src/sedestral/sass/icons/images/black-line-chart.svg",
        "black-lineal-good":"/src/sedestral/sass/icons/images/black-lineal-good.svg",
        "black-lineal-user-block":"/src/sedestral/sass/icons/images/black-lineal-user-block.svg",
        "black-lineal-spam":"/src/sedestral/sass/icons/images/black-lineal-spam.svg",
        "black-lineal-no-notification":"/src/sedestral/sass/icons/images/black-lineal-no-notification.svg",
        "black-lineal-eyes":"/src/sedestral/sass/icons/images/black-lineal-eyes.svg",
        "black-lineal-ai":"/src/sedestral/sass/icons/images/black-lineal-ai.svg",
        "black-lineal-intent-commercial":"/src/sedestral/sass/icons/images/black-lineal-intent-commercial.svg",
        "black-lineal-intent-informational":"/src/sedestral/sass/icons/images/black-lineal-intent-informational.svg",
        "black-lineal-intent-navigational":"/src/sedestral/sass/icons/images/black-lineal-intent-navigational.svg",
        "black-lineal-intent-transactional":"/src/sedestral/sass/icons/images/black-lineal-intent-transactional.svg",
        "black-lineal-ai-bold":"/src/sedestral/sass/icons/images/black-lineal-ai-bold.svg",
        "black-lineal-block":"/src/sedestral/sass/icons/images/black-lineal-block.svg",
        "black-lineal-config":"/src/sedestral/sass/icons/images/black-lineal-config.svg",
        "black-lineal-picture":"/src/sedestral/sass/icons/images/black-lineal-picture.svg",
        "black-lineal-style":"/src/sedestral/sass/icons/images/black-lineal-style.svg",
        "black-lineal-tags":"/src/sedestral/sass/icons/images/black-lineal-tags.svg",
        "black-lineal-code":"/src/sedestral/sass/icons/images/black-lineal-code.svg",
        "black-lineal-read":"/src/sedestral/sass/icons/images/black-lineal-read.svg",
        "black-lineal-seo":"/src/sedestral/sass/icons/images/black-lineal-seo.svg",
        "black-lineal-voice":"/src/sedestral/sass/icons/images/black-lineal-voice.svg",
        "black-lineal-words":"/src/sedestral/sass/icons/images/black-lineal-words.svg",
        "black-lineal-document":"/src/sedestral/sass/icons/images/black-lineal-document.svg",
        "black-lineal-feed":"/src/sedestral/sass/icons/images/black-lineal-feed.svg",
        "black-lineal-article":"/src/sedestral/sass/icons/images/black-lineal-article.svg",
        "black-lineal-folder":"/src/sedestral/sass/icons/images/black-lineal-folder.svg",
        "black-lineal-colors":"/src/sedestral/sass/icons/images/black-lineal-colors.svg",
        "black-lineal-conversation-offline":"/src/sedestral/sass/icons/images/black-lineal-conversation-offline.svg",
        "black-lineal-edition":"/src/sedestral/sass/icons/images/black-lineal-edition.svg",
        "black-lineal-form":"/src/sedestral/sass/icons/images/black-lineal-form.svg",
        "black-lineal-install":"/src/sedestral/sass/icons/images/black-lineal-install.svg",
        "black-lineal-security":"/src/sedestral/sass/icons/images/black-lineal-security.svg",
        "black-lineal-settings":"/src/sedestral/sass/icons/images/black-lineal-settings.svg",
        "black-lineal-user":"/src/sedestral/sass/icons/images/black-lineal-user.svg",
        "black-lineal-html":"/src/sedestral/sass/icons/images/black-lineal-html.svg",
        "black-lineal-wordpress":"/src/sedestral/sass/icons/images/black-lineal-wordpress.svg",
        "black-lineal-domains":"/src/sedestral/sass/icons/images/black-lineal-domains.svg",
        "black-lineal-encryption":"/src/sedestral/sass/icons/images/black-lineal-encryption.svg",
        "black-lineal-filters":"/src/sedestral/sass/icons/images/black-lineal-filters.svg",
        "black-lineal-speed":"/src/sedestral/sass/icons/images/black-lineal-speed.svg",
        "black-lineal-pencil":"/src/sedestral/sass/icons/images/black-lineal-pencil.svg",
        "black-lineal-activity":"/src/sedestral/sass/icons/images/black-lineal-activity.svg",
        "black-lineal-closed-inbox":"/src/sedestral/sass/icons/images/black-lineal-closed-inbox.svg",
        "black-lineal-history":"/src/sedestral/sass/icons/images/black-lineal-history.svg",
        "black-lineal-mention":"/src/sedestral/sass/icons/images/black-lineal-mention.svg",
        "black-lineal-rating":"/src/sedestral/sass/icons/images/black-lineal-rating.svg",
        "black-lineal-recorder":"/src/sedestral/sass/icons/images/black-lineal-recorder.svg",
        "black-lineal-share":"/src/sedestral/sass/icons/images/black-lineal-share.svg",
        "black-lineal-song":"/src/sedestral/sass/icons/images/black-lineal-song.svg",
        "black-lineal-time":"/src/sedestral/sass/icons/images/black-lineal-time.svg",
        "black-lineal-home":"/src/sedestral/sass/icons/images/black-lineal-home.svg",
        "black-lineal-delay":"/src/sedestral/sass/icons/images/black-lineal-delay.svg",
        "black-lineal-planning":"/src/sedestral/sass/icons/images/black-lineal-planning.svg",
        "black-lineal-conversations":"/src/sedestral/sass/icons/images/black-lineal-conversations.svg",
        "black-lineal-clock":"/src/sedestral/sass/icons/images/black-lineal-clock.svg",
        "black-lineal-chat-count":"/src/sedestral/sass/icons/images/black-lineal-chat-count.svg",
        "black-lineal-chat-offline":"/src/sedestral/sass/icons/images/black-lineal-chat-offline.svg",
        "black-lineal-hours-notification":"/src/sedestral/sass/icons/images/black-lineal-hours-notification.svg",
        "black-lineal-team":"/src/sedestral/sass/icons/images/black-lineal-team.svg",
        "black-lineal-assignment":"/src/sedestral/sass/icons/images/black-lineal-assignment.svg",
        "black-lineal-reactivity":"/src/sedestral/sass/icons/images/black-lineal-reactivity.svg",
        "black-lineal-books":"/src/sedestral/sass/icons/images/black-lineal-books.svg",
        "black-lineal-reload":"/src/sedestral/sass/icons/images/black-lineal-reload.svg",
        "black-lineal-changes":"/src/sedestral/sass/icons/images/black-lineal-changes.svg",
        "black-lineal-circle-inbox":"/src/sedestral/sass/icons/images/black-lineal-circle-inbox.svg",
        "black-lineal-mail":"/src/sedestral/sass/icons/images/black-lineal-mail.svg",
        "black-lineal-mail-alias":"/src/sedestral/sass/icons/images/black-lineal-mail-alias.svg",
        "black-lineal-mail-redirection":"/src/sedestral/sass/icons/images/black-lineal-mail-redirection.svg",
        "black-lineal-brush":"/src/sedestral/sass/icons/images/black-lineal-brush.svg",
        "black-lineal-help":"/src/sedestral/sass/icons/images/black-lineal-help.svg",
        "black-lineal-bubble":"/src/sedestral/sass/icons/images/black-lineal-bubble.svg",
        "black-lineal-house":"/src/sedestral/sass/icons/images/black-lineal-house.svg",
        "black-lineal-h":"/src/sedestral/sass/icons/images/black-lineal-h.svg",
        "black-lineal-context":"/src/sedestral/sass/icons/images/black-lineal-context.svg",
        "black-lineal-target":"/src/sedestral/sass/icons/images/black-lineal-target.svg",
        "black-lineal-blog":"/src/sedestral/sass/icons/images/black-lineal-blog.svg",
        "black-lineal-actions":"/src/sedestral/sass/icons/images/black-lineal-actions.svg",
        "black-lineal-design":"/src/sedestral/sass/icons/images/black-lineal-design.svg",
        "black-lineal-three":"/src/sedestral/sass/icons/images/black-lineal-three.svg",
        "black-lineal-four":"/src/sedestral/sass/icons/images/black-lineal-four.svg",
        "black-lineal-five":"/src/sedestral/sass/icons/images/black-lineal-five.svg",
        "black-lineal-six":"/src/sedestral/sass/icons/images/black-lineal-six.svg",
  /****/
        "illus-cannibalization":"/src/sedestral/sass/icons/images/illus-cannibalization.svg",
        "illus-position":"/src/sedestral/sass/icons/images/illus-position.svg",
        "illus-money-bag":"/src/sedestral/sass/icons/images/illus-money-bag.svg",
        "illus-magic-hat":"/src/sedestral/sass/icons/images/illus-magic-hat.svg",
        "illus-circle":"/src/sedestral/sass/icons/images/illus-circle.svg",
        "illus-clusters":"/src/sedestral/sass/icons/images/illus-clusters.svg",
        "illus-pensil":"/src/sedestral/sass/icons/images/illus-pensil.svg",
        "illus-computer":"/src/sedestral/sass/icons/images/illus-computer.svg",
        "illus-letter":"/src/sedestral/sass/icons/images/illus-letter.svg",
        "illus-customer-service":"/src/sedestral/sass/icons/images/illus-customer-service.svg",
        "illus-transaction":"/src/sedestral/sass/icons/images/illus-transaction.svg",
        "illus-mails":"/src/sedestral/sass/icons/images/illus-mails.png",
        "illus-perms":"/src/sedestral/sass/icons/images/illus-perms.png",
        "illus-mails-advanced":"/src/sedestral/sass/icons/images/illus-mails-advanced.svg",
        "illus-livechats":"/src/sedestral/sass/icons/images/illus-livechats.svg",
        "illus-seo":"/src/sedestral/sass/icons/images/illus-seo.svg",
        "illus-black-autopilot":"/src/sedestral/sass/icons/images/illus-black-autopilot.svg",
        "illus-black-chart":"/src/sedestral/sass/icons/images/illus-black-chart.svg",
        "illus-black-chart-purple":"/src/sedestral/sass/icons/images/illus-black-chart-purple.svg",
        "illus-black-funnel":"/src/sedestral/sass/icons/images/illus-black-funnel.svg",
        "illus-black-google":"/src/sedestral/sass/icons/images/illus-black-google.svg",
        "illus-black-languages":"/src/sedestral/sass/icons/images/illus-black-languages.svg",
        "illus-black-picture":"/src/sedestral/sass/icons/images/illus-black-picture.svg",
        "illus-black-plan":"/src/sedestral/sass/icons/images/illus-black-plan.svg",
        "illus-black-ready":"/src/sedestral/sass/icons/images/illus-black-ready.svg",
        "illus-black-search-engine":"/src/sedestral/sass/icons/images/illus-black-search-engine.svg",
        "illus-black-seo":"/src/sedestral/sass/icons/images/illus-black-seo.svg",
        "illus-black-unique":"/src/sedestral/sass/icons/images/illus-black-unique.svg",
        "illus-black-write":"/src/sedestral/sass/icons/images/illus-black-write.svg",
        "illus-black-box":"/src/sedestral/sass/icons/images/illus-black-box.svg",
        "illus-black-page":"/src/sedestral/sass/icons/images/illus-black-page.svg",
        "illus-black-target":"/src/sedestral/sass/icons/images/illus-black-target.svg",
        "illus-black-prestashop":"/src/sedestral/sass/icons/images/illus-black-prestashop.svg",
        "illus-black-shopify":"/src/sedestral/sass/icons/images/illus-black-shopify.svg",
        "illus-black-wordpress":"/src/sedestral/sass/icons/images/illus-black-wordpress.svg",
        "illus-black-wix":"/src/sedestral/sass/icons/images/illus-black-wix.svg",
        "illus-black-time":"/src/sedestral/sass/icons/images/illus-black-time.svg",
        "illus-black-union":"/src/sedestral/sass/icons/images/illus-black-union.svg",
        "illus-black-zig":"/src/sedestral/sass/icons/images/illus-black-zig.svg",
        "illus-black-ai-plan":"/src/sedestral/sass/icons/images/illus-black-ai-plan.svg",
        "illus-black-optimize":"/src/sedestral/sass/icons/images/illus-black-optimize.svg",
  /****/
        "facebook-iconKit":"/src/sedestral/sass/icons/images/facebook-iconKit.png",
        "facebook-iconKit2":"/src/sedestral/sass/icons/images/facebook-iconKit2.png",
        "instagram-icon":"/src/sedestral/sass/icons/images/instagram-icon.svg",
        "instagram-comment":"/src/sedestral/sass/icons/images/black-instagram-comment.svg",
        "instagram-like":"/src/sedestral/sass/icons/images/black-instagram-like.svg",
        "instagram-save":"/src/sedestral/sass/icons/images/black-instagram-save.svg",
        "instagram-share":"/src/sedestral/sass/icons/images/black-instagram-share.svg",
        "instagram-camera":"/src/sedestral/sass/icons/images/instagram-camera.svg",
        "instagram-music":"/src/sedestral/sass/icons/images/instagram-music.svg",
        "linkedin-threePoints":"/src/sedestral/sass/icons/images/linkedin-threePoints.svg",
        "linkedin-like":"/src/sedestral/sass/icons/images/linkedin-like.svg",
        "linkedin-applause":"/src/sedestral/sass/icons/images/linkedin-applause.svg",
        "linkedin-idee":"/src/sedestral/sass/icons/images/linkedin-idee.svg",
        "black-twitter-logo":"/src/sedestral/sass/icons/images/black-twitter-logo.svg",
        "twitter-points":"/src/sedestral/sass/icons/images/twitter-points.svg",
        "twitter-like":"/src/sedestral/sass/icons/images/twitter-like.svg",
        "twitter-reply":"/src/sedestral/sass/icons/images/twitter-reply.svg",
        "twitter-retweet":"/src/sedestral/sass/icons/images/twitter-retweet.svg",
        "twitter-share":"/src/sedestral/sass/icons/images/twitter-share.svg",
        "twitter-views":"/src/sedestral/sass/icons/images/twitter-views.svg",
  /****/
        "3d-gsc-connected":"/src/sedestral/sass/icons/images/3d-gsc-connected.svg",
        "3d-gsc-disconnected":"/src/sedestral/sass/icons/images/3d-gsc-disconnected.svg",
        "3d-upload-media":"/src/sedestral/sass/icons/images/3d-upload-media.svg",
        "3d-home":"/src/sedestral/sass/icons/images/3d-home.svg",
        "3d-contact-unlist":"/src/sedestral/sass/icons/images/3d-contact-unlist.svg",
        "3d-transaction":"/src/sedestral/sass/icons/images/3d-transaction.svg",
        "3d-task":"/src/sedestral/sass/icons/images/3d-task.svg",
        "3d-ai":"/src/sedestral/sass/icons/images/3d-ai.svg",
        "3d-wand":"/src/sedestral/sass/icons/images/3d-wand.svg",
        "3d-persona":"/src/sedestral/sass/icons/images/3d-persona.svg",
        "3d-ai-style":"/src/sedestral/sass/icons/images/3d-ai-style.svg",
        "3d-context":"/src/sedestral/sass/icons/images/3d-context.svg",
        "3d-style":"/src/sedestral/sass/icons/images/3d-style.svg",
        "3d-table":"/src/sedestral/sass/icons/images/3d-table.svg",
        "3d-meeting":"/src/sedestral/sass/icons/images/3d-meeting.svg",
        "3d-publish":"/src/sedestral/sass/icons/images/3d-publish.png",
        "3d-preview":"/src/sedestral/sass/icons/images/3d-preview.png",
        "3d-news":"/src/sedestral/sass/icons/images/3d-news.svg",
        "3d-invitation":"/src/sedestral/sass/icons/images/3d-invitation.svg",
        "3d-love":"/src/sedestral/sass/icons/images/3d-love.svg",
        "3d-user":"/src/sedestral/sass/icons/images/3d-user.svg",
        "3d-statistics":"/src/sedestral/sass/icons/images/3d-statistics.svg",
        "3d-link":"/src/sedestral/sass/icons/images/3d-link.svg",
        "3d-members":"/src/sedestral/sass/icons/images/3d-members.svg",
        "3d-roles":"/src/sedestral/sass/icons/images/3d-roles.svg",
        "3d-cluster":"/src/sedestral/sass/icons/images/3d-cluster.svg",
        "3d-routes":"/src/sedestral/sass/icons/images/3d-routes.svg",
        "3d-notifications":"/src/sedestral/sass/icons/images/3d-notifications.svg",
        "3d-products":"/src/sedestral/sass/icons/images/3d-products.svg",
        "3d-bot":"/src/sedestral/sass/icons/images/3d-bot.svg",
        "3d-card":"/src/sedestral/sass/icons/images/3d-card.svg",
        "3d-invoices":"/src/sedestral/sass/icons/images/3d-invoices.svg",
        "3d-channels":"/src/sedestral/sass/icons/images/3d-channels.svg",
        "3d-subscription-flow":"/src/sedestral/sass/icons/images/3d-subscription-flow.svg",
        "3d-subscription":"/src/sedestral/sass/icons/images/3d-subscription.svg",
        "3d-shortcuts":"/src/sedestral/sass/icons/images/3d-shortcuts.svg",
        "3d-documents":"/src/sedestral/sass/icons/images/3d-documents.svg",
        "3d-stamp":"/src/sedestral/sass/icons/images/3d-stamp.svg",
        "3d-serp":"/src/sedestral/sass/icons/images/3d-serp.svg",
        "3d-time":"/src/sedestral/sass/icons/images/3d-time.svg",
        "3d-dashboard":"/src/sedestral/sass/icons/images/3d-dashboard.svg",
        "3d-sessions":"/src/sedestral/sass/icons/images/3d-sessions.svg",
        "3d-solved":"/src/sedestral/sass/icons/images/3d-solved.svg",
        "3d-disconnect":"/src/sedestral/sass/icons/images/3d-disconnect.svg",
        "3d-settings":"/src/sedestral/sass/icons/images/3d-settings.svg",
        "3d-help":"/src/sedestral/sass/icons/images/3d-help.svg",
        "3d-website":"/src/sedestral/sass/icons/images/3d-website.svg",
        "3d-mail-domain":"/src/sedestral/sass/icons/images/3d-mail-domain.svg",
        "3d-mail-alias":"/src/sedestral/sass/icons/images/3d-mail-alias.svg",
        "3d-mail-create":"/src/sedestral/sass/icons/images/3d-mail-create.svg",
        "3d-mail-redirect":"/src/sedestral/sass/icons/images/3d-mail-redirect.svg",
        "3d-history":"/src/sedestral/sass/icons/images/3d-history.svg",
        "3d-translate":"/src/sedestral/sass/icons/images/3d-translate.svg",
        "3d-shipment":"/src/sedestral/sass/icons/images/3d-shipment.svg",
        "3d-dart":"/src/sedestral/sass/icons/images/3d-dart.svg",
        "3d-empty":"/src/sedestral/sass/icons/images/3d-empty.svg",
        "3d-office":"/src/sedestral/sass/icons/images/3d-office.svg",
        "3d-map":"/src/sedestral/sass/icons/images/3d-map.svg",
        "3d-learn":"/src/sedestral/sass/icons/images/3d-learn.svg",
        "3d-error":"/src/sedestral/sass/icons/images/3d-error.svg",
        "3d-livechat":"/src/sedestral/sass/icons/images/3d-livechat.svg",
        "3d-notes":"/src/sedestral/sass/icons/images/3d-notes.svg",
        "3d-keyboard":"/src/sedestral/sass/icons/images/3d-keyboard.svg",
        "3d-company":"/src/sedestral/sass/icons/images/3d-company.svg",
        "3d-target":"/src/sedestral/sass/icons/images/3d-target.svg",
        "3d-confetti":"/src/sedestral/sass/icons/images/3d-confetti.svg",
        "3d-upload":"/src/sedestral/sass/icons/images/3d-upload.svg",
        "3d-ai-writing":"/src/sedestral/sass/icons/images/3d-ai-writing.svg",
        "3d-rating-bad":"/src/sedestral/sass/icons/images/3d-rating-bad.svg",
        "3d-rating-good":"/src/sedestral/sass/icons/images/3d-rating-good.svg",
        "3d-rating-not-good":"/src/sedestral/sass/icons/images/3d-rating-not-good.svg",
        "3d-rating-very-good":"/src/sedestral/sass/icons/images/3d-rating-very-good.svg",
        "3d-identity":"/src/sedestral/sass/icons/images/3d-identity.svg",
        "3d-writing":"/src/sedestral/sass/icons/images/3d-writing.svg",
        "3d-locked":"/src/sedestral/sass/icons/images/3d-locked.svg",
        "3d-forms":"/src/sedestral/sass/icons/images/3d-forms.svg",
        "3d-stop-red":"/src/sedestral/sass/icons/images/3d-stop-red.svg",
        "3d-stop-green":"/src/sedestral/sass/icons/images/3d-stop-green.svg",
        "3d-idea":"/src/sedestral/sass/icons/images/3d-idea.svg",
        "3d-write":"/src/sedestral/sass/icons/images/3d-write.svg",
        "3d-working":"/src/sedestral/sass/icons/images/3d-working.svg",
        "3d-police":"/src/sedestral/sass/icons/images/3d-police.svg",
        "3d-image":"/src/sedestral/sass/icons/images/3d-image.svg",
        "3d-seo":"/src/sedestral/sass/icons/images/3d-seo.svg",
        "3d-handshake":"/src/sedestral/sass/icons/images/3d-handshake.svg",
        "3d-inbox":"/src/sedestral/sass/icons/images/3d-inbox.svg",
        "3d-mail-error":"/src/sedestral/sass/icons/images/3d-mail-error.svg",
        "3d-message":"/src/sedestral/sass/icons/images/3d-message.svg",
        "3d-message-receive":"/src/sedestral/sass/icons/images/3d-message-receive.svg",
        "3d-server":"/src/sedestral/sass/icons/images/3d-server.svg",
        "3d-spam":"/src/sedestral/sass/icons/images/3d-spam.svg",
        "3d-password":"/src/sedestral/sass/icons/images/3d-password.svg",
        "3d-password-retry":"/src/sedestral/sass/icons/images/3d-password-retry.svg",
        "3d-importance":"/src/sedestral/sass/icons/images/3d-importance.svg",
        "3d-users":"/src/sedestral/sass/icons/images/3d-users.svg",
        "3d-blog":"/src/sedestral/sass/icons/images/3d-blog.svg",
        "3d-close":"/src/sedestral/sass/icons/images/3d-close.svg",
        "3d-close-orange":"/src/sedestral/sass/icons/images/3d-close-orange.svg",
        "3d-mail-success":"/src/sedestral/sass/icons/images/3d-mail-success.svg",
        "3d-mail-percentage":"/src/sedestral/sass/icons/images/3d-mail-percentage.svg",
        "3d-sub-basic":"/src/sedestral/sass/icons/images/3d-sub-basic.svg",
        "3d-sub-entreprise":"/src/sedestral/sass/icons/images/3d-sub-entreprise.svg",
        "3d-sub-pro":"/src/sedestral/sass/icons/images/3d-sub-pro.svg",
        "3d-collaboration":"/src/sedestral/sass/icons/images/3d-collaboration.svg",
        "3d-article-ia":"/src/sedestral/sass/icons/images/3d-article-ia.svg",
        "3d-article-blank":"/src/sedestral/sass/icons/images/3d-article-blank.svg",
        "3d-offer-basic":"/src/sedestral/sass/icons/images/3d-offer-basic.svg",
        "3d-offer-medium":"/src/sedestral/sass/icons/images/3d-offer-medium.svg",
        "3d-offer-pro":"/src/sedestral/sass/icons/images/3d-offer-pro.svg",
        "3d-offer-list":"/src/sedestral/sass/icons/images/3d-offer-list.svg",
        "3d-homework":"/src/sedestral/sass/icons/images/3d-homework.svg",
        "3d-brainstorm":"/src/sedestral/sass/icons/images/3d-brainstorm.svg",
        "3d-startup":"/src/sedestral/sass/icons/images/3d-startup.svg",
  /****/
        "lineal-desk":"/src/sedestral/sass/icons/images/lineal-desk.svg",
        "lineal-exam":"/src/sedestral/sass/icons/images/lineal-exam.svg",
        "lineal-form":"/src/sedestral/sass/icons/images/lineal-form.svg",
        "lineal-pencil":"/src/sedestral/sass/icons/images/lineal-pencil.svg",
        "lineal-shortcuts":"/src/sedestral/sass/icons/images/lineal-shortcuts.svg",
        "lineal-arrow-box":"/src/sedestral/sass/icons/images/lineal-arrow-box.svg",
        "lineal-parachute":"/src/sedestral/sass/icons/images/lineal-parachute.svg",
        "lineal-android":"/src/sedestral/sass/icons/images/lineal-android.svg",
        "lineal-apple":"/src/sedestral/sass/icons/images/lineal-apple.svg",
  /****/
        "animated-drawing":"/src/sedestral/sass/icons/images/animated-drawing.gif",
        "animated-ai":"/src/sedestral/sass/icons/images/animated-ai.gif",
        "animated-search":"/src/sedestral/sass/icons/images/animated-search.gif",
        "animated-website":"/src/sedestral/sass/icons/images/animated-website.gif",
        "animated-serp":"/src/sedestral/sass/icons/images/animated-serp.gif",
        "animated-goals":"/src/sedestral/sass/icons/images/animated-goals.gif",
  /****/
        "colored-topical-map-ranked":"/src/sedestral/sass/icons/images/colored-topical-map-ranked.svg",
        "colored-topical-map-published":"/src/sedestral/sass/icons/images/colored-topical-map-published.svg",
        "colored-topical-map-progress":"/src/sedestral/sass/icons/images/colored-topical-map-progress.svg",
        "colored-topical-map-draft":"/src/sedestral/sass/icons/images/colored-topical-map-draft.svg",
        "colored-topical-map":"/src/sedestral/sass/icons/images/colored-topical-map.svg",
        "colored-topical-map-white":"/src/sedestral/sass/icons/images/colored-topical-map-white.svg",
        "colored-prestashop":"/src/sedestral/sass/icons/images/colored-prestashop.svg",
        "colored-shopify":"/src/sedestral/sass/icons/images/colored-shopify.svg",
        "colored-separation":"/src/sedestral/sass/icons/images/colored-separation.svg",
        "colored-aquarius":"/src/sedestral/sass/icons/images/colored-aquarius.svg",
        "colored-status-solved":"/src/sedestral/sass/icons/images/colored-status-solved.svg",
        "colored-status-notsolved":"/src/sedestral/sass/icons/images/colored-status-notsolved.svg",
        "colored-google":"/src/sedestral/sass/icons/images/colored-google.svg",
        "colored-american-express":"/src/sedestral/sass/icons/images/cards/american-express.svg",
        "colored-mastercard":"/src/sedestral/sass/icons/images/cards/mastercard.svg",
        "colored-paypal":"/src/sedestral/sass/icons/images/cards/paypal.svg",
        "colored-visa":"/src/sedestral/sass/icons/images/cards/visa.svg",
        "colored-wordpress":"/src/sedestral/sass/icons/images/colored-wordpress.svg",
        "colored-webflow":"/src/sedestral/sass/icons/images/colored-webflow.svg",
        "colored-covered":"/src/sedestral/sass/icons/images/colored-covered.svg",
        "colored-covered-draft":"/src/sedestral/sass/icons/images/colored-covered-draft.svg",
        "colored-not-covered":"/src/sedestral/sass/icons/images/colored-not-covered.svg",
  /****/
        "404":"/src/sedestral/sass/icons/images/404.svg",
        "capture-publisher-creator":"/src/sedestral/sass/icons/images/capture-publisher-creator.webp",
        "capture-blog-speed":"/src/sedestral/sass/icons/images/capture-blog-speed.webp",
        "illus-funnel":"/src/sedestral/sass/icons/images/illus-funnel.svg",
  /****/
        "formatting-bold":"/src/sedestral/sass/icons/images/quill/bold.svg",
        "formatting-italic":"/src/sedestral/sass/icons/images/quill/italic.svg",
        "formatting-list":"/src/sedestral/sass/icons/images/quill/list.svg",
        "formatting-link":"/src/sedestral/sass/icons/images/quill/link.svg",
        "formatting-background":"/src/sedestral/sass/icons/images/quill/background.svg",
        "formatting-strikethrough":"/src/sedestral/sass/icons/images/quill/strikethrough.svg",
        "formatting-underline":"/src/sedestral/sass/icons/images/quill/underline.svg",
        "formatting-fonts":"/src/sedestral/sass/icons/images/quill/fonts.svg",
        "formatting-alignment-left":"/src/sedestral/sass/icons/images/quill/alignment-left.svg",
        "formatting-alignment-right":"/src/sedestral/sass/icons/images/quill/alignment-right.svg",
        "formatting-alignment-center":"/src/sedestral/sass/icons/images/quill/alignment-center.svg",
        "formatting-font-size":"/src/sedestral/sass/icons/images/quill/font-size.svg",
        "formatting-quote":"/src/sedestral/sass/icons/images/quill/quote.svg",
        "formatting-image":"/src/sedestral/sass/icons/images/quill/image.svg",
        "formatting-embed-align-center":"/src/sedestral/sass/icons/images/quill/embed-align-center.svg",
        "formatting-embed-align-left":"/src/sedestral/sass/icons/images/quill/embed-align-left.svg",
        "formatting-embed-align-right":"/src/sedestral/sass/icons/images/quill/embed-align-right.svg",
        "formatting-embed-half-width":"/src/sedestral/sass/icons/images/quill/embed-half-width.svg",
        "formatting-embed-max-width":"/src/sedestral/sass/icons/images/quill/embed-max-width.svg",
);
$browsers: (
        "chrome":"/src/sedestral/sass/icons/images/browsers/chrome.svg",
        "browser":"/src/sedestral/sass/icons/images/browsers/browser.svg",
        "brave":"/src/sedestral/sass/icons/images/browsers/brave.svg",
        "edge":"/src/sedestral/sass/icons/images/browsers/edge.svg",
        "firefox":"/src/sedestral/sass/icons/images/browsers/firefox.svg",
        "opera":"/src/sedestral/sass/icons/images/browsers/opera.svg",
        "safari":"/src/sedestral/sass/icons/images/browsers/safari.svg"
);
$systems: (
        "windows":"/src/sedestral/sass/icons/images/system/windows.svg",
        "android":"/src/sedestral/sass/icons/images/system/android.svg",
        "apple":"/src/sedestral/sass/icons/images/system/apple.svg",
        "debian":"/src/sedestral/sass/icons/images/system/debian.svg",
        "linux":"/src/sedestral/sass/icons/images/system/linux.svg",
        "ubuntu":"/src/sedestral/sass/icons/images/system/ubuntu.svg"
);
$channels: (
        "discord":"/src/sedestral/sass/icons/images/channels/discord/discord.svg",
        "discord-black":"/src/sedestral/sass/icons/images/channels/discord/discord-black.svg",
        "facebook":"/src/sedestral/sass/icons/images/channels/facebook/facebook.svg",
        "facebook-black":"/src/sedestral/sass/icons/images/channels/facebook/facebook-black.svg",
        "instagram":"/src/sedestral/sass/icons/images/channels/instagram/instagram.svg",
        "instagram-black":"/src/sedestral/sass/icons/images/channels/instagram/instagram-black.svg",
        "linkedin":"/src/sedestral/sass/icons/images/channels/linkedin/linkedin.svg",
        "linkedin-black":"/src/sedestral/sass/icons/images/channels/linkedin/linkedin-black.svg",
        "messenger":"/src/sedestral/sass/icons/images/channels/messenger/messenger.svg",
        "messenger-black":"/src/sedestral/sass/icons/images/channels/messenger/messenger-black.svg",
        "twitter":"/src/sedestral/sass/icons/images/channels/twitter/twitter.svg",
        "twitter-black":"/src/sedestral/sass/icons/images/channels/twitter/twitter-black.svg",
        "mail":"/src/sedestral/sass/icons/images/channels/mail/mail.svg",
        "mail-black":"/src/sedestral/sass/icons/images/channels/mail/mail-black.svg",
);
$patterns: (
        1:"/src/sedestral/sass/icons/images/patterns/1.svg",
        2:"/src/sedestral/sass/icons/images/patterns/2.svg",
        3:"/src/sedestral/sass/icons/images/patterns/3.svg",
        4:"/src/sedestral/sass/icons/images/patterns/4.svg",
        5:"/src/sedestral/sass/icons/images/patterns/5.svg",
        6:"/src/sedestral/sass/icons/images/patterns/6.svg",
        7:"/src/sedestral/sass/icons/images/patterns/7.svg",
        8:"/src/sedestral/sass/icons/images/patterns/8.svg",
        9:"/src/sedestral/sass/icons/images/patterns/9.svg",
        10:"/src/sedestral/sass/icons/images/patterns/10.svg",
        11:"/src/sedestral/sass/icons/images/patterns/11.svg",
        12:"/src/sedestral/sass/icons/images/patterns/12.svg",
        13:"/src/sedestral/sass/icons/images/patterns/13.svg",
        14:"/src/sedestral/sass/icons/images/patterns/14.svg",
        15:"/src/sedestral/sass/icons/images/patterns/15.svg",
        16:"/src/sedestral/sass/icons/images/patterns/16.svg",
        17:"/src/sedestral/sass/icons/images/patterns/17.svg",
        18:"/src/sedestral/sass/icons/images/patterns/18.svg",
        19:"/src/sedestral/sass/icons/images/patterns/19.svg",
        20:"/src/sedestral/sass/icons/images/patterns/20.svg",
        21:"/src/sedestral/sass/icons/images/patterns/21.svg",
        22:"/src/sedestral/sass/icons/images/patterns/22.svg",
        23:"/src/sedestral/sass/icons/images/patterns/23.svg",
        24:"/src/sedestral/sass/icons/images/patterns/24.svg",
        25:"/src/sedestral/sass/icons/images/patterns/25.svg",
        26:"/src/sedestral/sass/icons/images/patterns/26.svg",
        27:"/src/sedestral/sass/icons/images/patterns/27.svg",
        28:"/src/sedestral/sass/icons/images/patterns/28.svg",
        29:"/src/sedestral/sass/icons/images/patterns/29.svg",
        30:"/src/sedestral/sass/icons/images/patterns/30.svg",
        31:"/src/sedestral/sass/icons/images/patterns/31.svg",
        32:"/src/sedestral/sass/icons/images/patterns/32.svg",
        33:"/src/sedestral/sass/icons/images/patterns/33.svg",
        34:"/src/sedestral/sass/icons/images/patterns/34.svg",
        35:"/src/sedestral/sass/icons/images/patterns/35.svg",
        36:"/src/sedestral/sass/icons/images/patterns/36.svg",
        37:"/src/sedestral/sass/icons/images/patterns/37.svg",
        38:"/src/sedestral/sass/icons/images/patterns/38.svg",
        39:"/src/sedestral/sass/icons/images/patterns/39.svg",
        40:"/src/sedestral/sass/icons/images/patterns/40.svg",
        41:"/src/sedestral/sass/icons/images/patterns/41.svg",
        42:"/src/sedestral/sass/icons/images/patterns/42.svg",
        43:"/src/sedestral/sass/icons/images/patterns/43.svg",
        44:"/src/sedestral/sass/icons/images/patterns/44.svg",
        45:"/src/sedestral/sass/icons/images/patterns/45.svg",
        46:"/src/sedestral/sass/icons/images/patterns/46.svg",
        47:"/src/sedestral/sass/icons/images/patterns/47.svg",
        48:"/src/sedestral/sass/icons/images/patterns/48.svg",
        49:"/src/sedestral/sass/icons/images/patterns/49.svg",
        50:"/src/sedestral/sass/icons/images/patterns/50.svg",
        51:"/src/sedestral/sass/icons/images/patterns/51.svg",
        52:"/src/sedestral/sass/icons/images/patterns/52.svg",
        53:"/src/sedestral/sass/icons/images/patterns/53.svg");

@mixin max-size {
  width: 100%;
  height: 100%;
}

@mixin ico($name) {
  background: url('#{map.get($icons, $name)}');
  background-size: cover;
}


@mixin icoImportant($name) {
  background: url('#{map.get($icons, $name)}') !important;
  background-size: cover;
}

@mixin pattern($name) {
  background: url('#{map.get($patterns, $name)}');
}

@mixin browser($name) {
  background: url('#{map.get($browsers, $name)}');
  background-size: cover;
}

@mixin system($name) {
  background: url('#{map.get($systems, $name)}');
  background-size: cover;
}

@mixin channels($name) {
  background: url('#{map.get($channels, $name)}');
  background-size: cover;
}