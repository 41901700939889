@use "src/sedestral/sass/icons/icons";

.componentTags {
  vertical-align: baseline;
  flex-flow: wrap;
  -webkit-box-align: end;
  gap: 6px;
  width: 100%;
  height: 100%;
  display: inline-flex;
  position: relative;

  .tag {
    font-size: 14px;
    background: var(--grey-sur);
    border-radius: 16px;
    padding: 9px 12px;
    display: flex;
    overflow: hidden;
    word-break: break-word;
    align-items: center;

    .remove {
      height: 17px;
      border-radius: 100%;
      margin-left: 8px;
      flex: 0 0 17px;
      display: flex;
      margin-right: 0px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 17px;

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        width: 8px;
        height: 8px;

        @include icons.ico("black-close");
      }
    }
  }

  .inputContainer {
    display: flex;

    input {
      border-radius: 16px;
      border-width: 1px;
      height: 35px !important;
    }

    .addButton {
      height: 35px;
      width: 35px;
      border-radius: 100%;
      background: var(--grey-sur);
      flex: 0 0 35px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-plus");
      }
    }
  }
}