@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.componentTablePaging {
  align-items: center;
  display: flex;
  width: 100%;
  padding: 15px 0;
  justify-content: flex-end;

  .countPerPage {
    display: flex;
    align-items: center;

    .text {
      font-size: 13px;
      margin-right: 15px;
    }

    .select {
      width: 66px;
      height: 32px;

      div[data-selectbox] {
        background: var(--grey-sur);
        border-color: transparent;
        border-radius: 20px;


        &:hover {
          background: var(--grey-sur-hover);
        }
      }
    }
  }

  .pages {
    display: flex;
    margin-left: 15px;
    margin-right: 55px;

    span {
      margin-left: 5px;
      margin-right: 5px;
    }

    .round {
      height: 30px;
      width: 30px;
      background: var(--grey-sur);
      border-radius: 100%;
      display: flex;
      margin-right: 4px;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur-hover);
      }

      &.selected {
        background: var(--black);
        color: var(--white);

        &:hover {
          opacity: 0.7;
        }
      }

      &.left {
        .icon {
          @include icons.ico("black-left-bold");
        }
      }

      &.right {
        .icon {
          @include icons.ico("black-right-bold");
        }
      }

      .icon {
        height: 10px;
        width: 10px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
}