@use "../../../../../../../../../sedestral/sass/icons/icons";

.globalQuillFormattingFamily {
  font-size: 13px;
  padding: 10px 0;

  .listEntry {
    text-align: left;
    cursor: pointer;
    position: relative;
    padding: 5px 14px;

    .selectedIcon {
      height: 10px;
      width: 10px;
      float: right;
      position: absolute;
      right: 8px;
      top: calc(50% - 5px);
      display: none;

      @include icons.ico("black-checked");
    }

    &.selected {
      .selectedIcon {
        display: block;
      }
    }

    h1, h2, h3, p {
      margin: 6px;
      padding: 2px 8px;
    }

    &:hover {
      background: var(--grey-sur);
    }
  }
}