@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/icons/icons";

.componentLanguageAll {
  width: 100%;

  .countryIcon {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
}