@use "../../../../../../../sedestral/sass/mixins/texts";

.componentMediaImageCropperRatio {
  height: 100%;
  width: 100%;

  .ratio {
    margin-bottom: 20px;

    .names {
      display: flex;
      margin-bottom: 8px;

      .name {
        font-size: 17px;

        @include texts.font("semi-bold");
      }
    }

    .entries {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .entry {
        position: relative;
        border-radius: 10px;
        flex-direction: column;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(50% - 14px);
        cursor: pointer;
        border: 2px solid var(--grey-sur);

        &:hover {
          border-color: var(--grey-sur-hover);
          box-shadow: 1px 4px 4px -2px #00000012;
        }

        &.selected {
          border-color: var(--blue);
        }

        .shape {
          height: 50px;
          font-size: 12px;
          width: 60px;
          background: var(--grey-sur);
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include texts.font("semi-bold");
        }

        .name {
          font-size: 13px;
          margin-top: 15px;

          @include texts.font("semi-bold");
        }
      }
    }
  }
}