@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentContentScoreTerms {

  .slidingContainer {
    height: 22px;
    border: 1px solid var(--grey-sur);
    padding: 4px;
    border-radius: 28px;
    margin-bottom: 10px;

    div[chentry] {
      border-radius: 33px;
      width: calc(33.33% - 4px);
    }

    .sliding {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 3px 6px;
      font-size: 14px;

      @include texts.font("semi-bold");

      &.headings {
        .icon {
          @include icons.ico("formatting-font-size");
        }
      }

      &.meta {
        .icon {
          @include icons.ico("black-lineal-document");
        }
      }

      &.body {
        .icon {
          @include icons.ico("black-document");
        }
      }

      .icon {
        height: 12px;
        width: 12px;
      }
    }
  }

  .terms {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .term {
      padding: 4px 6px;
      border-radius: 32px;
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &.mid {
        background: var(--orange-semi-light);
        border: 1px solid var(--orange-light);
        color: var(--orange-dark);

        .counts {
          background: var(--orange);
          color: var(--white);
        }
      }

      &.good {
        background: var(--green-very-light);
        border: 1px solid var(--green-light);
        color: var(--green-dark);

        .counts {
          background: var(--green);
          color: var(--white);
        }
      }

      &.bad {
        background: var(--red-very-light);
        border: 1px solid var(--red-light);
        color: var(--red);

        .counts {
          background: var(--red);
          color: var(--white);
        }
      }

      .counts {
        font-size: 12px;
        padding: 2px 5px;
        border-radius: 12px;
        flex: 0 0 auto;
      }
    }
  }
}