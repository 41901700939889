@use "../../../../../sedestral/sass/mixins/texts";

.globalMediaUpload {
  height: 100%;
  width: 100%;
  border-radius: 13px;
  box-shadow: inset 0 0 0 1px var(--grey-sur);

  div[loaderLight] {
    width: 100%;
    min-width: 50px;
    position: relative;

    svg {
      width: 18px !important;
      margin-top: -8px !important;

      circle {
        stroke-width: 4px !important;
      }
    }
  }

  .uploadProgression {
    height: 4px;
    left: 6px;
    padding: 3px;
    overflow: hidden;
    width: calc(100% - 18px);
    background: var(--black-alpha-half);
    bottom: 6px;
    border-radius: 10px;
    position: absolute;

    .progress {
      width: 0;
      border-radius: 6px;
      transition: 0.2s;
      background: var(--white);
      position: relative;
      height: 100%;
    }
  }

  img {
    height: 100%;
  }

}