@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/outline";
@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/icons/icons";

.accountInvitation {
  height: 56px;
  border-bottom: var(--grey-sur) 1px solid;
  width: 100%;
  display: flex;

  label {
    @include buttons.dropdown;
    float: right;

    .button {
      margin-right: 10px;
      font-size: 13px;
      height: 30px;
      border-radius: 100%;
      padding: 0;
      width: 30px !important;
      border-color: var(--grey-sur);
      float: left;
      position: relative;

      @include buttons.round;
      @include positioning.flex-globally;
      @include texts.font("bold");

      &:after {
        border: 0 !important;
      }

      .moreIcon {
        height: 12px;
        width: 12px;

        @include icons.ico("black-more");
      }
    }

    input {
      @include inputs.basic;
    }

    ul, ol {
      right: 0;
      width: 146px;

      li {
        font-size: 12px;
        padding: 8px 10px;

        @include texts.font("bold");
      }

      .SendButton, .DeleteButton {
        visibility: visible;
      }
    }
  }

  .column {
    padding-left: 10px;
    flex: 0 0 calc(16% - 11px);
    overflow: hidden;
    border-right: var(--grey-sur) 1px solid;
    font-size: 13px;
    position: relative;
    height: 100%;

    @include positioning.flex-vertically;

    &.roles {
      padding-top: 2px;
      height: calc(100% - 1px);

    }

    &:last-child {
      border-right: 0 !important;
    }

    &.large {
      flex: 0 0 calc(28% - 11px);
    }

    &.short {
      flex: 0 0 calc(18% - 11px);
    }

    &.semiBold {
      @include texts.font("semi-bold");
    }

    &.edit {
      flex: 0 0 calc(10% - 11px);
      overflow: visible !important;

      @include positioning.flex-globally;
    }

    .rolesContainer {
      width: 1000px;
      position: absolute;
    }

    .shadow {
      width: 30px;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, transparent, white);
      position: absolute;
      height: calc(100% - 1px);

      &.flex {
        @include positioning.flex-globally;
      }
    }
  }

  .link {
    font-size: 13px;
    width: calc(100% - 36px);
    text-decoration: underline;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .copyButton {
    height: 26px;
    width: 26px;

    @include buttons.round;
    @include positioning.flex-globally;

    .icon {
      height: 12px;
      width: 12px;

      @include icons.ico("black-copy");
    }
  }

  .ghostLine {
    height: 10px;
    width: 60%;
    border-radius: 4px;
    background: var(--grey-sur);
  }
}