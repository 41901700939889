@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentCommunityPageGsc {

  .sitemaps {
    height: fit-content;

    .iconSitemap {
      height: 100px;
      width: 100px;

      @include icons.ico("3d-website");
    }

    .sitemap {
      visibility: visible;
    }

    .delete {
      background: var(--white);
      border-radius: 20px;
      cursor: pointer;

      &:hover {
        opacity: 1;
        border-color: transparent;
        background: var(--grey-sur);
      }

      .icon {
        height: 18px;
        width: 18px;
        filter: var(--icon-black);

        @include icons.ico("black-delete-light");
      }
    }
  }

  .disconnected {
    display: flex;
    gap: 15px;

    .case {
      width: 100%;
      border: 1px solid var(--grey-sur);
      padding: 15px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      .title {
        font-size: 18px;
        width: 100%;
        text-align: center;

        @include texts.font("semi-bold");
      }

      .description {
        font-size: 15px;
        width: 100%;
        text-align: center;
      }

      .icon {
        height: 80px;
        width: 80px;
        margin: 15px auto;

        &.gscConnected {
          @include icons.ico("3d-gsc-connected");
        }

        &.gscDisconnected {
          @include icons.ico("3d-gsc-disconnected");
        }
      }

      .form {
        width: 100%;

        .label {
          margin-bottom: 10px;
          font-size: 14px;
        }

        div[data-selectbox] {
          margin-bottom: 12px;
          position: relative;
          height: 40px;
          border-width: 1px;
        }
      }
    }
  }

  .connected + .connected {
    margin-top: 20px;
  }

  .connected {
    padding: 20px;
    border: 1px solid var(--grey-sur);
    border-radius: 10px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    .head {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;

      .title {
        text-align: center;

        @include texts.font("semi-bold");
      }

      .iconContainer {
        height: 20px;
        width: 20px;
        position: relative;
        overflow: hidden;
        border-radius: 100%;
      }
    }

    .content {
      .firstLabel {
        border-bottom: 1px solid var(--grey-sur);
        padding-bottom: 10px;
        font-size: 15px;
        margin-bottom: 15px;
      }

      .html {
        border: 1px solid var(--grey-sur);
        padding: 10px;
        margin-top: 15px;
        border-radius: 8px;
        font-size: 14px;
        background: var(--blue);
        color: var(--white);
      }

      .buttonsContainer {
        margin-top: 25px;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        margin: 0 auto 20px;
      }

      th, td {
        padding: 12px;
        text-align: center;

        a:hover {
          color: var(--blue) !important;
        }
      }

      @media (max-width: 600px) {
        table, thead, tbody, th, td, tr {
          display: block;
        }

        thead tr {
          display: none;
        }

        tr {
          margin-bottom: 15px;
          padding: 10px;
        }

        td {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
          padding: 8px 0;
          border: none;
          position: relative;
          overflow: scroll;
        }

        td:last-child {
          border-bottom: 0;
        }

        td:before {
          content: attr(data-label);
          font-weight: bold;
          margin-right: 10px;
          flex: 0 0 40%;
        }
      }
    }
  }

  .buttonGoogle {
    width: fit-content;
    align-items: center;
    padding: 10px 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 60px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    margin-bottom: 10px;
    background: var(--grey-sur);
    color: var(--black);
    display: flex;
    gap: 15px;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey);
    }

    .google {
      width: 28px;
      height: 28px;

      @include icons.ico("colored-google");
    }
  }

  @media (max-width: 480px) {
    .disconnected {
      flex-wrap: wrap;

      .case {
        width: 100%;
      }
    }
  }
}