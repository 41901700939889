@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/icons/icons";

.componentRole {
  font-size: 10px;
  padding: 4px 6px 4px 4px;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 30px;
  position: relative;
  float: left;

  @include texts.font("bold");

  .pastille {
    height: 10px;
    top: 1px;
    margin-right: 6px;
    width: 10px;
    border-radius: 100%;
    position: relative;
    float: left;
  }
}