@use "../../../../../sedestral/sass/extends/animations";

.globalLoaderProgress {

  .limit {
    height: 13px;
    width: 13px;
    border-radius: 100%;
    border: var(--grey-sur) 3px solid;
    position: absolute;
  }

  .spinner {
    @include animations.spinner-progress;

    svg {
      height: 18px;
      width: 18px;
      position: relative;

      .progress {
        stroke-width: 15px !important;
        transition: stroke-dashoffset 0.2s ease;
      }
    }
  }

}