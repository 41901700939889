@use "src/sedestral/sass/mixins/texts";

.componentCommunityWebsiteBlogConnect {

  cursor: default;

  .step2 {
    margin-top: 10px;
  }

  .input {
    height: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .host {
    @include texts.font("bold");
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .help {
    cursor: auto;
    font-size: 14px;
    background: var(--blue-new);
    color: var(--blue-new-text);
    padding: 14px;
    border-radius: 12px;
    margin-bottom: 10px;

    ul {
      list-style: none;
      counter-reset: list-counter;
      padding: 0;
      margin: 0;
    }

    li {
      counter-increment: list-counter;

      strong {
        @include texts.font("bold");
      }
    }

    li:not(:last-child) {
      margin-bottom: 10px;
    }

    li::before {
      content: counter(list-counter) ". ";
    }

    a {
      color: var(--blue-new-text);
      text-decoration: underline;
    }
  }

  .info {
    cursor: auto;
    font-size: 14px;
    background: var(--orange-light);
    color: var(--orange-dark);
    padding: 14px;
    border-radius: 12px;
    margin-bottom: 10px;

    a {
      color: var(--orange-dark);
      text-decoration: underline;
    }
  }
}