@use "../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../sedestral/sass/extends/animations";

.componentQuillCollaborators {
  display: flex;
  flex-direction: row-reverse;

  .activeAccountsContainer {
    margin-right: 10px;
    display: flex;

    .avatarFloat {
      width: 18px;

      .avatarBorder {
        border-radius: 100%;
        box-shadow: 0 0 0 2px var(--white);
        height: 24px;
        width: 24px;
        position: relative;
        background: var(--white);

        .avatarContainer {
          padding: 1px;
          border-radius: 100%;
          position: relative;
          height: calc(100% - 6px);
          width: calc(100% - 6px);
        }
      }
    }

  }
}