.componentCommunityArticle {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .views {
    height: 100%;
    width: 100%;
    display: flex;

    .editor {
      width: calc(100% - 360px);
    }

    .score {
      width: 360px;
      flex: 0 0 360px;
      border-left: 1px solid var(--grey-sur);
    }
  }
}