@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityArticleAiGeneration {
  padding: 14px;
  height: 100%;

  div[scrolltrack] {
    right: 4px;
  }

  .buttonGenerateContainer {
    background: var(--white);
    position: absolute;
    bottom: 0;
    height: 74px;
    width: calc(100% - 60px);
    left: 0;
    border-top: 1px solid var(--grey-sur);
    display: flex;
    align-items: center;
    padding: 0px 30px;
    font-size: 14px;

    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 15px;
      margin-right: 55px;
    }

    .buttonGenerate {
      padding: 10px 18px 10px 18px;
      font-size: 14px;
      display: flex;
      align-items: center;
      width: fit-content;
      background: var(--black);
      border-radius: 30px;
      cursor: pointer;
      position: relative;
      color: var(--white);

      @include texts.font("semi-bold");

      &:hover {
        opacity: 0.8;
      }

      &.clicked {
        color: transparent;

        .icon {
          opacity: 0;
        }
      }

      .icon {
        width: 18px;
        height: 18px;
        margin: 6px 6px 6px 12px;
        filter: var(--icon-white);

        @include icons.ico("black-right-arrow");
      }
    }

    .text {
      margin-left: auto;
      text-align: center;
      font-size: 16px;

      @include texts.font("semi-bold");

      .click {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .head {
    display: flex;
    margin-bottom: 15px;
    align-items: center;

    .capsule {
      height: 44px;
      width: 44px;
      flex: 0 0 44px;
      border-radius: 100%;
      background: var(--grey-sur);
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        height: 26px;
        width: 26px;

        @include icons.ico("3d-ai");
      }
    }

    .text {
      font-size: 18px;
      margin-left: 12px;

      @include texts.font("semi-bold");
    }
  }

  .stepsContainer {
    position: relative;
    margin-bottom: 15px;
    height: 52px;
  }

  .generationContainer {
    display: flex;
    position: relative;
    margin-bottom: 20px;
  }

  .title {
    font-size: 16px;
    display: flex;
    align-items: center;

    @include texts.font("semi-bold");

    .dot {
      height: 8px;
      width: 14px;
      background: var(--black);
      border-radius: 8px;
      margin-right: 8px;
    }
  }

  .disabled {
    pointer-events: none;
    cursor: unset;
    background: unset;
  }

  .bodyContainer {
    position: relative;
    margin-bottom: 20px;

    &.contained {
      height: calc(100% - 190px);
    }

    .label {
      font-size: 13px;
      margin-bottom: 10px;
    }

    .proposal {
      border: 1px solid var(--grey-sur);
      padding: 18px;
      border-radius: 12px;
      margin-bottom: 18px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover, &.selected {
        background: var(--grey-sur);
        border-color: transparent;
      }

      &.finished {
        cursor: unset;

        &:hover {
          background: unset;
          border-color: var(--grey-sur);
        }
      }

      &.waiting {
        height: 62px;
      }

      .proposalTitle {
        font-size: 16px;
        margin-bottom: 10px;

        @include texts.font("semi-bold");

        strong {
          @include texts.font("extra-bold");
        }
      }

      .description {
        font-size: 14px;
        color: var(--grey-text-dark);

        strong {
          @include texts.font("bold");
        }
      }

      .loading {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: var(--white);
      }

      .button {
        background: var(--black);
        color: var(--white);
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-top: 15px;
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 25px;
        align-items: center;

        .icon {
          height: 12px;
          width: 12px;
          margin-left: 10px;
          filter: var(--icon-white);

          @include icons.ico("black-right-arrow");
        }
      }
    }

    .serp {
      margin-bottom: 15px;

      .name {
        font-size: 16px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        gap: 8px;

        @include texts.font("semi-bold");

        .round {
          height: 22px;
          width: 22px;
          flex: 0 0 22px;
          background: var(--grey-sur);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;

          .icon {
            height: 14px;
            width: 14px;

            @include icons.ico("colored-google");
          }
        }
      }

      .results {
        background: var(--black);
        color: var(--white);
        font-size: 13px;
        padding: 4px 10px;
        border-radius: 20px;
        width: fit-content;
        cursor: pointer;

        @include texts.font("semi-bold");

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .headRetry {
      display: flex;
      gap: 15px;
      align-items: center;
      margin-bottom: 15px;

      .label {
        align-items: center;
        display: flex;
        margin-bottom: 0;
      }

      .button {
        padding: 5px 10px;
        border: 1px solid var(--grey-sur);
        border-radius: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:hover {
          background: var(--grey-sur);
          border-color: transparent;
        }

        .icon {
          height: 14px;
          width: 14px;

          @include icons.ico("black-regen");
        }
      }
    }
  }

  .waiting {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .typingContainer {
    position: relative;
    height: 70px;
  }

  .finishedContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;

    @include texts.font("semi-bold");

    .capsule {
      height: 80px;
      width: 80px;
      border-radius: 100%;
      background: var(--grey-sur);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 10px;

      .icon {
        height: 60px;
        width: 60px;

        @include icons.ico("3d-ai");
      }

      .pastille {
        background: var(--white);
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        .icon {
          height: 28px;
          width: 28px;

          @include icons.ico("colored-status-solved")
        }
      }
    }
  }

  .buttonsContainer {
    height: 160px;

    .add {
      border: 1px solid var(--grey-sur);
      border-radius: 16px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0;
      margin-top: -16px;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur);
        border-color: transparent;
      }

      .text {
        margin-bottom: 6px;
        font-size: 14px;
      }

      .icon {
        width: 14px;
        height: 14px;

        @include icons.ico("black-plus");
      }
    }
  }

  .emptyContainer {
    height: 400px;

    .round {
      height: 200px;
      width: 200px;
      background: var(--grey-sur);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        height: 140px;
        width: 140px;

        @include icons.ico("3d-ai");
      }
    }

    .writing {
      height: 100px;
      width: 100px;

      @include icons.ico("3d-ai-writing");
    }

    .serpEmpty {
      height: 200px;
      width: 200px;

      @include icons.ico("animated-serp");
    }
  }

  .confirmationContainer {
    background: var(--white);
    position: absolute;
    bottom: 0;
    height: 74px;
    width: calc(100% - 60px);
    left: 0;
    border-top: 1px solid var(--grey-sur);
    display: flex;
    align-items: center;
    padding: 0px 30px;
    font-size: 14px;

    @include texts.font("semi-bold");

    .text {
      margin-right: 15px;
    }

    .buttons {
      width: 310px;
      margin-left: auto;
      margin-right: 55px;
      flex: 0 0 310px;
    }
  }

  @media screen and (max-width: 480px) {
    .finishedContainer {
      text-align: center;
    }

    .generationContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .buttonGenerate {
        width: calc(100% - 28px);
      }
    }

    .confirmationContainer {
      .text {
        display: none;
      }
    }

    .buttonGenerateContainer {
      width: calc(100% - 40px);
      padding: 0 20px;
      height: 60px;

      .buttonGenerate {
        margin-left: auto;
        margin-right: auto;
        padding: 4px 18px;
        text-align: center;

        &.skip {
          display: none;
        }
      }
    }

    .emptyContainer {
      .serpEmpty {
        height: 100px;
        width: 100px;
      }

      div[emptytext] {
        font-size: 18px !important;
      }
    }

    .serp {
      .entry {
        .title {
          flex-direction: column;
          align-items: unset;
          gap: 0 !important;
          margin-bottom: 10px;

          .maximize {
            display: none !important;
          }
        }
      }
    }
  }
}