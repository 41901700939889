@use "src/sedestral/sass/mixins/positioning";

.componentGeneralAccount {
  width: calc(100% - 30px) !important;
  padding: 15px;
  height: 100%;

  @include positioning.clear;

  div[scrolltrack] {
    right: 5px !important;
    top: 57px;
    height: calc(100% - 65px);
  }

  .left {
    float: left;
    width: 300px;
    height: 200px;
  }

  .right {
    float: right;
    width: calc(100% - 330px);
    height: 200px;
  }
}