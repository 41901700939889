@use "../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/extends/buttons";
@use "../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../sedestral/sass/mixins/outline";

.globalCollapseExtended {
  position: relative;
  border-radius: 14px;
  overflow: hidden;

  @include outline.outline(2);

  &.collapsed {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
    flex: 0 0 78px;

    .contentSide {
      display: none;
    }

    .round {
      .icon {
        transform: rotate(90deg);
      }
    }
  }

  .headSide {
    position: relative;
    padding: 20px;
    height: auto;
    width: calc(100% - 40px);
    border-bottom: unset;

    .iconContainer {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 90px;

      @include positioning.flex-globally;

      .icon {
        height: 40px;
        width: 40px;
        position: relative;


        &.html {
          @include icons.ico("black-lineal-html");
        }

        &.shopify {
          @include icons.ico("colored-shopify");
        }

        &.wix {
          @include icons.ico("black-wix");
        }

        &.wordpress {
          @include icons.ico("colored-wordpress");
        }

        &.webflow {
          @include icons.ico("colored-webflow");
        }

        &.prestashop {
          @include icons.ico("colored-prestashop");
        }

        &.domains {
          @include icons.ico("black-lineal-domains");
        }

        &.settings {
          @include icons.ico("black-lineal-settings");
        }

        &.encryption {
          @include icons.ico("black-lineal-encryption");
        }

        &.filters {
          @include icons.ico("black-lineal-filters");
        }

        &.activity {
          @include icons.ico("black-lineal-activity");
        }

        &.speed {
          @include icons.ico("black-lineal-speed");
        }

        &.colors {
          @include icons.ico("black-lineal-colors");
        }

        &.launcher {
          @include icons.ico("black-lineal-circle-inbox");
        }

        &.glasses {
          @include icons.ico("black-glasses");
        }

        &.brush {
          @include icons.ico("black-lineal-brush");
        }

        &.edition {
          @include icons.ico("black-lineal-edition");
        }
      }
    }

    .namesContainer {
      position: relative;
      margin-left: 80px;
      width: calc(100% - 144px);

      @include positioning.clear;

      .name {
        margin-bottom: 4px;
        font-size: 16px;

        @include texts.font("semi-bold");

        .headBadge {
          @include positioning.clear;

          .text {
            float: left
          }

          .badge {
            font-size: 11px;
            padding: 5px 10px;
            margin-top: -2px;
            margin-left: 10px;
            border-radius: 5px;
            color: var(--orange-dark);
            background: var(--orange-light);
            float: left;
          }
        }
      }

      .collapseDescription {
        font-size: 14px;
        color: var(--grey-text);
      }
    }

    .closeContainer {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 70px;

      @include positioning.flex-globally;

      .round {
        height: 32px;
        width: 32px;
        border-radius: 100%;
        border: 2px solid var(--grey-sur);

        @include buttons.round;
        @include positioning.flex-globally;

        .icon {
          height: 14px;
          width: 14px;
          transition: 0.2s;

          @include icons.ico("black-bottom-bold");
        }
      }
    }
  }

  .contentSide {
    padding: 20px;
    width: calc(100% - 40px);
  }
}
