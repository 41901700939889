@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityPageKnowEntry {
  border: 1px solid var(--grey-sur);
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 15px;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .name {
      font-size: 18px;

      @include texts.font("semi-bold");
    }

    .buttons {
      margin-left: auto;
      display: flex;
      gap: 10px;

      .delete, .edit {
        background: var(--grey-sur);
        padding: 6px 16px;
        border-radius: 35px;
        cursor: pointer;

        @include texts.font("semi-bold");

        &:hover {
          background: var(--grey-sur-hover);
        }
      }
    }

  }

  .lines {
    display: flex;
    align-items: center;
    gap: 20px;

    .line {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &.document {
        .icon {
          @include icons.ico("black-lineal-document");
        }
      }

      &.link {
        .icon {
          @include icons.ico("black-link");
        }
      }

      &.attachment {
        .icon {
          @include icons.ico("black-attachment");
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      span {
        margin-right: 5px;

        @include texts.font("semi-bold");
      }
    }
  }

  @media (max-width: 480px) {
    .top {
      flex-direction: column;
      align-items: unset;

      .name {
        font-size: 16px;
      }

      .delete {
        font-size: 14px;
      }

      .buttons {
        margin-left: unset;
      }
    }

    .lines {
      flex-direction: column;
      gap: 0;
      align-items: unset;

      .line {
        font-size: 14px;

        .icon {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}