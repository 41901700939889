@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityArticleAiRulesStyle {
  .param {
    margin-bottom: 12px;

    .label {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-size: 14px;

      @include texts.font("medium");

      .dot {
        height: 8px;
        width: 14px;
        background: var(--black);
        border-radius: 8px;
        margin-right: 8px;
      }
    }

    .select {
      height: 44px;

      div[data-selectbox="true"] {
        border: 1px solid var(--grey-sur);
      }
    }
  }

  .empty {
    height: 150px;

    div[emptyemoji] {
      font-size: 26px !important;
    }

    div[emptytext] {
      font-size: 15px !important;
      margin-bottom: 5px !important;

      @include texts.font("semi-bold");
    }

    div[emptybutton] {
      font-size: 15px !important;
      margin-top: 5px !important;
      padding: 6px 20px !important;

      @include texts.font("semi-bold");
    }
  }
}