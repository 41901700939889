@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/outline";

.componentCommunityPageContext {
  .editorContainer {
    display: flex;
    width: 100%;
    justify-content: center;

    .editorWidth {
      width: 100%;

      .editor {
        width: calc(100% - 24px);
        margin-bottom: 25px;
        height: 250px;
        position: relative;
        border: 2px solid var(--grey-sur);
        border-radius: 12px;
        padding: 10px;
      }
    }
  }
}