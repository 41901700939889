@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentDocumentExplorer {
  width: 100%;
  height: 100%;

  .cases {
    display: flex;
    gap: 10px;

    .case {
      height: 76px;
      gap: 5px;
      border-radius: 12px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid var(--grey-sur);
      cursor: pointer;
      position: relative;

      &:hover {
        background: var(--grey-sur);
        border-color: transparent;
      }

      &.optimize {
        pointer-events: none;

        .icon {
          @include icons.ico("black-ai-up");
        }
      }

      &.create {
        .icon {
          @include icons.ico("black-ai-write");
        }
      }

      .soon {
        position: absolute;
        background: var(--red);
        color: var(--white);
        font-size: 10px;
        padding: 1px 4px;
        border-radius: 10px;
        top: -4px;
        right: -3px;
      }

      .icon {
        height: 20px;
        width: 20px;
      }

      .title {
        font-size: 14px;
        text-align: center;
        width: 85%;

        @include texts.font("semi-bold");
      }
    }
  }

  .label {
    font-size: 14px;

    @include texts.font("semi-bold");
  }
}