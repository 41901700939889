@use "../../../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../../../sedestral/sass/extends/boxs";

.componentQuillBlotsLogicFormatter {
  @include boxs.sur;

  position: absolute;
  border-radius: 6px;
  pointer-events: none;
  z-index: 5;
  padding: 5px;
  display: flex;

  .formatterContainer {
    pointer-events: all;
    height: 20px;
  }
}