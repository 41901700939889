@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentDocumentExplorerFolder {
  border-radius: 10px;
  margin-bottom: 2px;
  cursor: pointer;
  padding: 6px;

  &:hover {
    background: var(--grey-sur-opac);

    .right {
      width: auto !important;
      opacity: 1 !important;
    }
  }

  &.edition {
    .names {
      .name {
        width: 100%;
        overflow: visible !important;
      }
    }

    .right {
      width: 0 !important;
      opacity: 0 !important;
    }
  }

  &.reduced {
    .button {
      &.reduce {
        .icon {
          transform: rotate(270deg) !important;
        }
      }
    }
  }

  &.hasBottom {
    margin-bottom: 8px;
    margin-top: 8px;

    &:hover {
      border-color: transparent;
    }
  }

  &.selected {
    background: var(--grey-sur);
    border-color: transparent;

    &:hover {
      background: var(--grey-sur-hover);
      border-color: transparent;
    }
  }

  .names {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;

    .button {
      height: 18px;
      width: 18px;
      flex: 0 0 18px;
      display: flex;
      border-radius: 7px;
      align-items: center;
      justify-content: center;

      &:hover {
        background: var(--grey-sur-hover);
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      &.document {
        .icon {
          @include icons.ico("black-lineal-document");
        }
      }

      &.plus {
        .icon {
          @include icons.ico("black-plus");
        }
      }

      &.more {
        .icon {
          @include icons.ico("black-more");
        }
      }

      &.reduce {
        .icon {
          transform: rotate(0deg);

          @include icons.ico("black-bottom-bold");
        }
      }

      .image {
        height: 14px;
        width: 14px;
        position: relative;

        div[mediaimage] {
          min-height: unset;
          min-width: unset;
          background: transparent;
        }

        div[mediaerror] {
          width: 100%;
          height: 100%;
        }
      }

      .icon {
        height: 12px;
        width: 12px;
        filter: var(--icon-grey-very-dark);
      }
    }

    .name {
      font-size: 16px;
      margin-left: 6px;
      margin-right: 5px;
      width: fit-content;

      @include texts.ellipsis(1);
      @include texts.font("semi-bold");

      &.active {
        cursor: text;
      }

      input {
        max-width: calc(100% - 40px);
      }
    }

    .right {
      margin-left: auto;
      opacity: 0;
      display: flex;
      gap: 5px;
      width: 0;
      flex-direction: row-reverse;
    }
  }

  .bottom {
    width: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
    padding-top: 3px;
    font-size: 14px;
    height: 16px;

    .entry {
      position: relative;
      display: flex;
      margin-right: 8px;
      align-items: center;
      font-size: 12px;
      color: var(--grey-text-dark);

      &.time {
        margin-left: auto;
        color: var(--grey-text);
      }

      p {
        margin: unset
      }

      .icon {
        margin-right: 4px;
        position: relative;
        height: 16px;
        width: 16px;
        overflow: hidden;
        border-radius: 100%;
      }
    }
  }
}