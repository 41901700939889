@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/icons/icons";

.componentAccountMembers {
  width: 100%;
  height: fit-content;
  position: relative;
  border-top: 1px solid var(--grey-sur);

  .top {
    height: 46px;
    border-bottom: var(--grey-sur) 1px solid;

    .leftContainer {
      border-right: var(--grey-sur) 1px solid;
      z-index: 2;
      width: calc(100% - 200px);
      position: relative;
      float: left;
      height: 100%;

      .search {
        width: calc(100% - 175px);
        left: 13px;
        position: relative;
        height: 100%;

        .icon {
          height: 16px;
          width: 16px;
          top: 14px;
          position: absolute;
          filter: var(--icon-grey);

          @include icons.ico("black-search");
        }

        .input {
          left: 30px;
          font-size: 13px;
          width: calc(100% - 12px);
          height: 34px;
          top: 4.5px;
          border: unset;
          position: absolute;

          @include texts.font("bold");
        }
      }

      .filter {
        @include texts.font("bold");
        @include buttons.round;
        @include effects.hover-alpha;
        border: var(--grey-sur) 1px solid;
        right: 15px;
        border-radius: 20px;
        font-size: 13px;
        top: 9px;
        padding: 4px 12px;
        position: absolute;

        .pastille {
          height: 12px;
          display: none;
          top: 2px;
          color: var(--white);
          font-size: 10px;
          margin-left: 4px;
          border-radius: 100%;
          padding: 2px 6px;
          background: var(--red);
          position: relative;
          float: right;
        }

        .icon {
          height: 10px;
          width: 10px;
          margin-left: 9px;
          top: 4px;
          float: right;
          position: relative;

          @include icons.ico("black-bottom-bold");
        }
      }
    }

    .invitation {
      @include buttons.round;
      @include texts.font("bold");
      @include effects.hover-alpha;
      z-index: 1;
      padding: 4px 13px;
      border-radius: 20px;
      top: 9px;
      border: 0;
      right: 14px;
      font-size: 15px;
      float: right;
      position: relative;

      .icon {
        height: 10px;
        width: 10px;
        top: 4px;
        margin-right: 5px;
        position: relative;
        float: left;
        @include icons.ico("black-plus");
      }
    }
  }

  .categories {
    height: 45px;
    width: 100%;
    position: relative;
    background: var(--grey-sur);

    .column {
      width: calc(36% - 10px);
      font-size: 13px;
      padding-left: 10px;
      height: 100%;
      float: left;

      @include positioning.flex-vertically;
      @include texts.font("bold");

      &.large {
        width: calc(28% - 10px);
      }
    }
  }

  .membersContainer {
    margin-bottom: 10px;
    width: 100%;
    position: relative;

    &.shine {
      @include animations.shine;
    }
  }

  .emptyContainer {
    position: absolute;
    width: 100%;
    top: 146px;
    height: calc(100% - 122px);
    border-radius: 15px;
    background: linear-gradient(to top, var(--white), #d06bf900);

    .icon {
      height: 80px;
      width: 80px;

      @include icons.ico("3d-members");
    }

  }
}