@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentStatisticsChartDetails {

  &.tiny {
    .chartContainer {
      height: 70px;

      .chart {
        height: 110px;
      }
    }
  }


  .top {
    display: flex;

    .name {
      font-size: 14px;
    }

    .values {
      display: flex;
      margin-bottom: 10px;
      gap: 10px;

      .value {
        font-size: 18px;
        color: var(--blue);

        @include texts.font("semi-bold");

        &.empty {
          color: var(--black);
        }

        span {
          font-size: 12px;
          @include texts.font("medium");

          &.up {
            color: var(--green) !important;
          }

          &.down {
            color: var(--red-king) !important;
          }
        }
      }
    }

    .evolution {
      display: flex;
      font-size: 14px;
      margin-left: auto;
      gap: 2px;
      height: fit-content;
      align-items: center;

      .content {
        align-items: center;
        display: flex;
      }

      &.up {
        color: var(--green);

        .arrow {
          filter: var(--icon-green);
          transform: rotate(45deg);
        }
      }

      &.down {
        color: var(--red-king);

        .evolutionValue {
          visibility: visible;
        }

        .arrow {
          filter: var(--icon-red-kind);
          transform: rotate(145deg);
        }
      }

      .arrow {
        height: 12px;
        width: 12px;

        @include icons.ico("black-top-arrow");
      }
    }
  }

  .chartContainer {
    position: relative;
    height: 95px;
    width: 100%;

    .chart {
      height: 135px;
      width: calc(100% + 6px);
      position: absolute;
      top: -14px;
      left: -8px;
    }
  }
}