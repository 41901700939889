@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityArticleAiRulesKnow {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .title {
    display: flex;
    align-items: center;
    gap: 10px;

    .round {
      background: var(--grey-sur);
      width: 28px;
      height: 28px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        height: 14px;
        width: 14px;

        &.stars {
          @include icons.ico("black-ai-stars");
        }

        &.google {
          @include icons.ico("colored-google");
        }
      }
    }

    .name {
      font-size: 16px;

      @include texts.font("semi-bold");
    }
  }

  .description {
    font-size: 13px;
  }

  .selectBox {
    position: relative;
    height: 50px;
    display: flex;
    gap: 15px;

    @media (max-width: 480px) {
      flex-wrap: wrap;
      margin-bottom: 70px;
    }

    div[data-selectbox] {
      border-radius: 30px;
    }

    .button {
      display: flex;
      padding: 10px 20px;
      background: var(--grey-sur);
      width: fit-content;
      border-radius: 24px;
      font-size: 14px;
      gap: 8px;
      align-items: center;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur-hover);
      }

      .icon {
        height: 14px;
        width: 14px;
        flex: 0 0 14px;

        @include icons.ico("black-plus");
      }
    }
  }
}