@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPagePartnerColumns {

  .empty {
    height: 210px;
    position: relative;
    border: 1px solid var(--grey-sur);
    border-radius: 14px;

    .emptyIcon {
      height: 65px;
      width: 65px;

      @include icons.ico("3d-empty");
    }

    div[emptyText] {
      font-size: 18px !important;
    }
  }

  .alert {
    border: 1px solid var(--grey-sur);
    padding: 10px;
    border-radius: 14px;
    margin-bottom: 15px;

    .top {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 5px;
      color: var(--red);

      @include texts.font("semi-bold");

      .pastille {
        height: 12px;
        width: 12px;
        background: var(--red);
        border-radius: 100%;
      }
    }

    .description {
      font-size: 13px;
    }

  }

  .entry {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .column {
      border: 1px solid var(--grey-sur);
      width: 100%;
      padding: 0 8px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      height: 32px;

      &.webflow {
        .icon {
          @include icons.ico("colored-webflow");
        }
      }

      &.sed {
        &:hover {
          background: var(--grey-sur);
          border-color: transparent;
        }
      }

      div[data-selectbox] {
        height: 100%;
        margin: 0;
        border: unset;

        &:hover {
          background: unset;
        }
      }

      .pastille {
        height: 8px;
        width: 8px;
        background: var(--red);
        border-radius: 100%;
        margin-left: auto;
      }

      .icon {
        height: 18px;
        width: 18px;
        flex: 0 0 18px;
        position: relative;
      }

      .selectBox {
        height: 30px;
        width: 100%;
      }
    }

    .equal {
      flex: 0 0 16px;
      width: 16px;
      height: 16px;
      filter: var(--icon-grey);

      @include icons.ico("black-right-arrow");
    }
  }

  .buttons {
    margin-top: 15px;
  }
}