@use "src/sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";

.componentRoleCreator {
  .input {
    margin-bottom: 10px;
    margin-top: 10px;
    height: 40px;
    font-size: 14px;

    @include inputs.border;
  }

  .selectBoxContainer {
    width: 100%;
    height: 38px;
    position: absolute;
  }

  .buttons {
    position: relative;
    margin-top: 68px;
  }
}