@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.globalMediaFile {
  height: 100%;
  width: 100%;
  background: var(--white);

  .image {
    height: 100%;
    width: 50px;
    border-right: var(--grey-sur) 1px solid;
    position: relative;
    float: left;

    @include positioning.flex-globally;

    .icon {
      height: 22px;
      width: 22px;

      @include icons.ico("black-file");
    }
  }

  .names {
    margin-top: 8px;
    margin-left: 8px;
    width: calc(100% - 84px);
    float: left;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .fileType {
      font-size: 15px;
      text-transform: uppercase;

      @include texts.ellipsis(1);
      @include texts.font("bold");
    }

    .fileName {
      font-size: 12px;

      @include texts.ellipsis(2);
    }
  }
}