@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/outline";

.componentAlert {
  padding: 16px;
  background-color: var(--orange);
  color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  min-height: 34px;
  font-size: 14px;

  &.big {
    background-color: var(--red) !important;

    .actions {
      .button {
        color: var(--red) !important;
      }
    }
  }

  &.margin {
    margin-bottom: 15px;
  }

  .message {
    flex-grow: 1;

    b {
      @include texts.font("semi-bold");
    }

  }

  .actions {
    display: flex;
    gap: 10px;
    margin-left: 10px;

    .button {
      padding: 8px 20px;
      font-size: 15px;
      border: none;
      border-radius: 25px;
      background-color: white;
      color: var(--orange);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
}