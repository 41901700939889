@use "../../../../../sedestral/sass/mixins/positioning";
@use "../../../../../sedestral/sass/icons/icons";
@use "../../../../../sedestral/sass/mixins/texts";

.globalLoaderPaint {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  @include positioning.flex-globally;

  .ai {
    height: 32px;
    width: 32px;
    margin-bottom: 10px;
    position: absolute;
    right: 10px;
    bottom: 0;

    @include icons.ico("3d-ai");
  }

  .drawingCircle {
    background: white;
    height: 100px;
    width: 100px;
    border-radius: 100%;

    @include positioning.flex-globally;

    .drawing {
      height: 70px;
      width: 70px;

      @include icons.ico("animated-drawing");
    }
  }
}