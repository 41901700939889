@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/positioning";

.componentStateIcon {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  @include positioning.flex-globally;

  &.none {
    background: var(--green-very-light);

    .icon {
      @include icons.ico("black-checked");

      filter: var(--icon-green);
    }
  }

  &.transferred {
    background: var(--blue-light) !important;

    .icon {
      filter: var(--icon-blue);

      @include icons.ico("black-send");
    }
  }

  &.scheduled {
    background: var(--grey-sur);

    .icon {
      @include icons.ico("3d-time");
    }
  }

  &.deleted {
    background: var(--red-very-light);

    .icon {
      filter: var(--icon-red);

      @include icons.ico("black-delete");
    }
  }

  &.error {
    background: var(--orange-very-light);

    .icon {
      filter: var(--icon-orange);
      height: 40%;
      width: 40%;

      @include icons.ico("black-close");
    }
  }

  &.errorRed {
    background: var(--red-very-light);

    .icon {
      filter: var(--icon-red);
      height: 40%;
      width: 40%;

      @include icons.ico("black-close");
    }
  }

  &.draft {
    background: var(--orange-very-light);

    .icon {
      filter: var(--icon-orange);

      @include icons.ico("black-draft");
    }
  }

  &.rank {
    background: var(--green-very-light);

    .icon {
      @include icons.ico("black-trophy");

      filter: var(--icon-green);
    }
  }

  .icon {
    height: 60%;
    width: 60%;
  }
}