@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPicker {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .avatarContainer {
    height: 18px;
    width: 18px;
    position: relative;
    border-radius: 100%;
    margin-right: 10px;
  }

  .name {
    font-size: 14px;

    @include texts.font("semi-bold");
  }

  .arrow {
    height: 12px;
    width: 12px;
    margin-left: auto;
    margin-right: 4px;

    @include icons.ico("black-bottom-bold");
  }
}