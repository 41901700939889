@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";


.componentAvatarEdition {
  height: 120px;
  width: 100%;


  .form {
    @include positioning.clear;

    .promptContainer {
      height: 74px;
      width: 74px;
      position: relative;
      float: left;
    }

    .texts {
      margin-left: 20px;
      width: calc(100% - 110px);
      float: left;

      .description {
        font-size: 15px;
        color: var(--grey-text);
      }
    }
  }


  div[btnsave] {
    margin-top: 40px;

    div[btns], div[btnc] {
      padding: 6px 13px;
      font-size: 13px;
    }
  }
}