@use "src/sedestral/sass/mixins/effects";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/boxs";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/outline";

.componentGeneralBoard {
  width: 100%;
  height: calc(100% - 57px);

  div[scrolltrack] {
    height: calc(100% - 6px);
    right: -5px;
    top: 2px;
  }

  .menuCards {
    @include positioning.clear;
    width: calc(100% + 15px);
  }

  .menuCard {
    @include boxs.white;
    @include positioning.clear;
    @include boxs.card;

    min-height: 116px;
    width: calc(50% - 49px);
    margin-right: 15px;
    margin-bottom: 15px;
    transition: 0.1s;
    float: left;
    box-shadow: unset;

    &.clickable {
      @include outline.blue-hover(4);
    }

    &.hidden {
      display: none;
    }

    &.max {
      width: calc(100% - 30px);
    }

    &.large {
      width: calc(100% - 45px);

      .title {
        width: 200px !important;
      }
    }

    &.SessionsButton {
      visibility: visible;
    }

    &.HistoryButton {
      visibility: visible;
    }

    &.AccountButton {
      visibility: visible;
    }

    &.SiteButton {
      visibility: visible;
    }

    &.ProductsButton {
      visibility: visible;
    }

    &.SubscriptionsButton {
      visibility: visible;
    }

    &.MembersButton {
      visibility: visible;
    }

    &.RolesButton {
      visibility: visible;
    }

    &.NotificationsButton {
      visibility: visible;
    }

    .imageContainer {

      .products {
        @include icons.max-size;
        @include icons.ico("3d-products");
      }

      .subscription {
        @include icons.max-size;
        @include icons.ico("3d-subscription");
      }

      .members {
        @include icons.max-size;
        @include icons.ico("3d-members");
      }

      .notifications {
        @include icons.max-size;
        @include icons.ico("3d-notifications");
      }

      .roles {
        @include icons.max-size;
        @include icons.ico("3d-roles");
      }

      .sessions {
        @include icons.max-size;
        @include icons.ico("3d-sessions");
      }

      .channels {
        @include icons.max-size;
        @include icons.ico("3d-time");
      }
    }


    .title {
      font-size: 18px;
      margin-bottom: 15px;
      margin-left: 2px;

      @include texts.font("bold");
    }

    .noPadding {
      margin-left: -15px;
      width: calc(100% + 30px);
    }

    .input {
      height: 46px;
      margin-bottom: 8px;

      &.Name, &.Link {
        visibility: visible;
      }
    }
  }

  .title {
    font-size: 20px;
    margin-bottom: 20px;

    @include texts.font("bold");

    &.marge {
      margin-left: 15px;
      margin-top: 15px;
    }
  }

  .cardsTitle {
    @include texts.font("bold");
    font-size: 19px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }
}