@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/icons/icons";

.componentRolePicker {
  height: 100%;
  width: 100%;

  .pickerTop {
    height: 30px;
    position: relative;
    width: 100%;

    .icon {
      left: 10px;
      top: 10px;
      height: 12px;
      width: 12px;
      position: absolute;
      filter: var(--icon-grey);

      @include icons.ico("black-search");
    }

    .input {
      left: 26px;
      width: calc(100% - 32px);
      outline: none;
      font-size: 12px;
      cursor: text;
      height: 23px;
      top: 4px;
      padding-left: 4px;
      border: unset;

      &[contenteditable=true]:empty:before {
        color: var(--grey-text);
      }

      @include texts.font("semi-bold");
      position: relative;
    }
  }

  .roles {
    height: calc(100% - 38px);
    width: calc(100% - 3px);
    max-height: 250px;
    padding-bottom: 8px;

    &[scrollvirtual] {
      width: 100%;

      .role {
        width: calc(100% - 28px) !important;
      }
    }

    div[scrolltrack] {
      right: 4px;
      top: 36px !important;
      height: calc(100% - 42px) !important;
      display: block;
    }

    .container {
      .role {
        padding-left: 5px;
        left: 5px;
        padding-right: 5px;
        border-radius: 6px;
        margin-top: 5px;
        padding-bottom: 8px;
        width: calc(100% - 20px);
        text-transform: uppercase;
        position: relative;

        @include positioning.clear;

        &:hover {
          cursor: pointer;
          background: var(--grey-sur);
        }


        &.inactive {
          opacity: 0.3;
          pointer-events: none;
        }

        .pastille {
          height: 11px;
          border-radius: 100%;
          top: 7px;
          margin-right: 5px;
          width: 11px;
          position: relative;
          float: left;
        }

        .name {
          font-size: 13px;
          top: 4px;
          margin-top: unset;
          position: relative;
          float: left;
          width: calc(100% - 16px);

          @include texts.font("bold");
        }

        .checked {
          right: 9px;
          height: 12px;
          top: 8px;
          display: block;
          width: 12px;
          position: absolute;

          @include icons.ico("black-checked");
        }
      }
    }
  }

  .loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background: var(--grey);
    top: 0;
    left: 0;

    @include positioning.flex-globally;

    svg {
      height: 14px;
      width: 14px;

      circle {
        stroke-width: 6px;
      }
    }
  }
}