@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentContentScoreSerp {
  height: 100%;


  &.closed {
    height: 200px;
    overflow: hidden;
    position: relative;

    .name {
      display: flex;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px 15px 0px 15px;

    .name {
      font-size: 16px;
      display: none;
      align-items: center;
      gap: 8px;

      @include texts.font("semi-bold");

      .round {
        height: 22px;
        width: 22px;
        flex: 0 0 22px;
        background: var(--grey-sur);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        .icon {
          height: 14px;
          width: 14px;

          @include icons.ico("colored-google");
        }
      }
    }

    .description {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-width: 1px;
    border-spacing: 0;
    border-color: var(--grey-sur);
    overflow: hidden;
    border-top: 1px solid var(--grey-sur);

    thead {
      width: 100%;
      @include texts.font("semi-bold");
    }

    tbody {
      width: 100%;
      overflow: scroll;
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    th {
      font-size: 14px;
      padding: 15px 15px;
    }

    td, th {
      position: relative;
      font-size: 13px;
      padding: 10px 15px !important;
      border-bottom: 1px solid;
      border-right: 1px solid;
      border-color: var(--grey-sur);
      text-align: left;

      &:last-child {
        border-right: none;
      }

      &.score {
        width: 154px;
        flex: 0 0 154px;
      }

      &.seo {
        width: 160px;
        flex: 0 0 160px;
      }


      &.words {
        width: 44px;
      }

      &.pos {
        width: 34px;
      }

      &.content {
        width: fit-content;
      }

      .entries {
        visibility: visible;
      }

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .semiContainer {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .bar {
          position: relative;
          height: 6px;
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
        }

        .semi {
          margin-top: -8px;
          position: relative;
          height: 34px;
          width: 60px;
        }
      }

      .competitor {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .link {
          font-size: 13px;
          color: var(--blue-punk);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .names {
          font-size: 15px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @include texts.font("semi-bold");

          .favicon {
            height: 16px;
            width: 16px;
            top: 3px;
            position: relative;
          }
        }

        .description {
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .expand {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 150px;
    background: linear-gradient(360deg, #ffffff 0%, #ffffff80 80%, #ffffff00 100%);
    z-index: 1;

    .button {
      border-radius: 30px;
      padding: 5px 15px;
      font-size: 14px;
      margin-left: 15px;
      background: var(--black);
      color: var(--white);
      height: fit-content;
      display: flex;
      gap: 10px;
      align-items: center;
      width: fit-content;
      margin-top: auto;
      margin-bottom: 10px;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        opacity: 0.8;
      }
    }
  }
}