@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentInvoices {
  height: 100%;
  width: 100%;

  .categories {
    width: 100%;
    height: 45px;
    background: var(--grey-sur);

    .column {
      width: calc(18% - 10px);
      font-size: 13px;
      padding-left: 10px;
      height: 100%;
      float: left;

      @include texts.font("bold");
      @include positioning.flex-vertically;

      &.large {
        width: calc(18% - 10px);
      }

      &.short {
        width: calc(10% - 10px);
      }
    }
  }

  .entries{
    visibility: visible;
  }
}