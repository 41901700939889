@use "src/sedestral/sass/icons/icons";

.componentCommunityConfig {
  margin-top: 20px;
  height: 100%;

  div[scrolltrack] {
    right: -10px;
    height: calc(100% - 68px);
  }

  .steps {
    height: 40px;
    margin-bottom: 16px;
  }

  input {
    font-size: 15px !important;
    border-width: 1px !important;
  }

  .name {
    height: 50px;
    position: relative;
  }

  .editor {
    height: 180px;
    border-radius: 12px;
    border: 1px solid var(--grey-sur);
    margin-bottom: 74px;
  }

  .label {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  .languageContainer {
    position: relative;
    height: 50px;

    div[data-selectbox="true"] {
      border-width: 1px;
    }
  }

  .buttonsContainer {
    position: absolute;
    bottom: -4px;
    background: var(--white);
    width: calc(100% + 0px);
    left: -20px;
    padding: 8px 20px;
    box-shadow: 0px 0px 6px #00000014;

    div[btnc] {
      border-radius: 30px;
    }

    div[btns] {
      background: var(--black);
      border-radius: 30px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}