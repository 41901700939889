@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPageKnowCreatorFile {

  .file {
    border-radius: 12px;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    font-size: 14px;
    position: relative;
    border: 1px solid var(--grey-sur);

    .icon {
      height: 28px;
      width: 28px;

      @include icons.ico("black-attachment")
    }

    .name {
      visibility: visible;
    }

    input {
      opacity: 0;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .sliding {
    position: relative;
    height: 30px;
    font-size: 14px;
    margin-bottom: 25px;

    @include texts.font("semi-bold");

    .icon {
      height: 12px;
      width: 12px;
      margin-right: 10px;

      &.link {
        @include icons.ico("black-link");
      }

      &.light {
        @include icons.ico("black-link-light");
      }
    }
  }
}