@use "src/sedestral/sass/mixins/texts";

.componentEditorDocsState {
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 5px;
  display: flex;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  padding-right: 10px;

  @include texts.font("semi-bold");


  &:hover {
    background: var(--grey-sur-hover);
  }

  .icon {
    height: 18px;
    margin-right: 6px;
    width: 18px;
    position: relative;
    overflow: hidden;
    border-radius: 100%;
  }
}