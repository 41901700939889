@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/outline";

.componentCommunityPage {
  height: calc(100% - 25px);
  padding: 25px 40px 0 40px;
  max-width: 980px;
  width: calc(100% - 80px);

  &.selfCenter {
    margin-left: auto;
    margin-right: auto;
  }

  &.contentCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: unset;

    .description {
      text-align: center;
    }
  }

  div[scrolltrack] {
    right: 5px;
  }

  .brand {
    display: flex;
    align-items: center;
    gap: 15px;

    .capsule {
      height: 36px;
      width: 36px;
      background: var(--grey-sur);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.style {
        width: 116px;
        height: 34px;
        border-radius: 38px;

        .icon {
          height: 96px;
          width: 96px;

          @include icons.ico("3d-ai-style");
        }
      }

      &.context {
        .icon {
          @include icons.ico("3d-context");
        }
      }

      &.brain {
        .icon {
          @include icons.ico("3d-brainstorm");
        }
      }

      &.partner {
        .icon {
          @include icons.ico("3d-blog");
        }
      }

      &.google {
        .icon {
          @include icons.ico("colored-google");
        }
      }

      .icon {
        height: 20px;
        width: 20px;

        @include icons.ico("3d-ai");
      }
    }

    .name {
      font-size: 24px;

      @include texts.font("semi-bold");
    }
  }

  .description {
    font-size: 17px;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 25px;

    @include texts.font("semi-bold");
  }

  .body {
    width: 100%;

    .buttons {
      position: absolute;
      bottom: 0px;
      width: 100%;
      background: var(--white);
      left: 0;
      padding: 15px 0px;
      border-top: 1px solid var(--grey-sur);

      .create {
        display: flex;
        border-radius: 30px;
        margin-right: 90px;
        height: 52px;
        background: var(--black);
        color: var(--white);
        padding: 0 20px;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;

        @include texts.font("semi-bold");

        &:hover {
          opacity: 0.8;
        }

        .icon {
          height: 16px;
          margin-left: 10px;
          width: 16px;
          filter: var(--icon-white);

          @include icons.ico("black-plus");
        }

        circle {
          stroke: var(--white);
        }
      }
    }

    .bodyInside {
      margin-bottom: 100px;

      .loader {
        height: calc(100% - 300px);
        width: 100%;
      }

      .empty {
        height: 350px;
        position: relative;

        .emptyIcon {
          width: 80px;
          height: 80px;

          @include icons.ico("3d-brainstorm");
        }

        div[emptytext] {
          @include texts.font("semi-bold");
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 25px 15px 0;

    .body {
      .buttons {
        .create {
          margin-right: 15px;
          height: 38px;
          font-size: 14px;

          .icon {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    .brand {
      .style {
        height: 36px !important;
        width: 112px !important;

        .icon {
          height: 100px !important;
          width: 100px !important;
        }
      }
    }

    .brandName {
      font-size: 24px;
      margin-top: 14px;
    }

    .description {
      font-size: 18px;
    }
  }
}