@use "../../../../../../../sedestral/sass/extends/boxs";

.globalQuillFormattingLine {
  @include boxs.sur;
  z-index: 9999;
  height: 24px;
  display: none;
  border-radius: 6px;
  width: 194px;
  padding: 4px;
  position: absolute;

  div[qCa] {
    margin-right: 0 !important;
    border-right: 0 !important;
  }
}