@use "src/sedestral/sass/icons/icons";

.componentCommunityPageStyleEmpty {
  .entry {
    border: 1px solid var(--grey-sur);
    margin-top: 60px;
    border-radius: 12px;
    padding: 15px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.ai {
      border-color: var(--blue-ai);

      .rules {
        background: var(--blue-ai);
        color: var(--white);

        .icon{
          @include icons.ico("3d-ai");
        }
      }
    }

    .rules {
      padding: 10px 20px;
      background: var(--grey-sur-opac);
      display: flex;
      align-items: center;
      width: fit-content;
      border-radius: 34px;
      margin-top: -38px;
      margin-bottom: 15px;

      .icon {
        height: 25px;
        width: 25px;
        margin-right: 10px;

        @include icons.ico("3d-persona");
      }
    }
  }
}