.componentMailsDomainsCreator {
  height: fit-content;

  .input {
    height: 48px;
    margin-top: 14px !important;
    font-size: 16px;
    margin-bottom: 20px !important;
  }

  div[btnsave] {
    margin-bottom: 20px;
    position: relative;
    height: 70px;
  }

  div[btnc] {
    display: none;
  }

  div[btns] {
    padding: 12px 18px;
    border-radius: 10px;
  }
}