@use "src/sedestral/sass/extends/animations";
@use "src/sedestral/sass/icons/icons";

.componentAiTyping {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--black);

  .bot {
    height: 22px;
    width: 22px;
    margin-right: 6px;
    margin-top: -4px;

    @include icons.ico("3d-ai");
  }

  .typing {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-left: 10px;
    top: 1px;
    position: relative;

    span {
      background-color: #000;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin: 0 3px;
      display: inline-block;
      animation: bounce 0.9s infinite both;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }

      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }
  }

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-12px);
    }
  }
}