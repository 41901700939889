@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.globalMediaLink {
  height: 100%;
  width: 100%;

  div[mediaImage] {
    max-height: 102px;
    border-radius: 12px 12px 0 0 !important;
    overflow: hidden;
    position: relative;
    background: var(--grey-sur) !important;
    margin-top: 0 !important;
    width: 100% !important;
    margin-bottom: 5px !important;

    img {
      width: 100% !important;
      height: unset !important;
      max-width: unset !important;
      top: 0;
    }
  }

  .text {
    margin: 9px 0 35px 0;

    .title {
      margin-top: 7px;
      margin-left: 8px;
      width: calc(100% - 16px);

      @include texts.ellipsis(2);
      @include texts.font("bold");
    }

    .website {
      margin-top: 6px;
      font-size: 12px;
      margin-left: 8px;
      float: left;
      color: var(--grey-text);

      .host {
        float: left;
      }

      .icon {
        height: 10px;
        width: 10px;
        margin-top: 3px;
        margin-right: 5px;
        float: left;
        filter: var(--icon-grey);

        @include icons.ico("black-link");
      }
    }
  }
}