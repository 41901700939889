@use "../mixins/texts";

@mixin white {
  border: 1px solid #eaeaea;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  width: 100%;
  background: var(--white-light);
  color: var(--black);

  @include texts.font("medium");

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield;
  }

  &[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }

  &::placeholder {
    color: var(--grey-text);
  }

  &:focus {
    outline: 0;
  }
}

@mixin basic {
  @include white;
  border: unset;
  width: 100% !important;

  &:focus {
    box-shadow: unset !important;
  }
}

@mixin border {
  @include white;

  border: var(--grey-sur) 2px solid;
  border-radius: 10px;


  &[disabled] {
    border: var(--grey-sur-hover) 2px solid;
    background: var(--grey-sur);
  }

  &:focus {
    border-color: var(--grey-alpha);
  }
}