@use "../../../../../sedestral/sass/mixins/texts";
@use "../../../../../sedestral/sass/icons/icons";

.componentCasesTiny {
  .cases {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  .component {
    padding: 15px;
    border: 1px solid var(--grey-sur);
    margin-top: 15px;
    border-radius: 14px;
    max-width: 560px;
  }
}

.componentCaseTiny {
  border: 1px solid var(--grey-sur);
  padding: 10px;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  display: flex;
  border-radius: 14px;
  cursor: pointer;
  min-width: 146px;

  &:hover {
    border-color: var(--blue);
  }

  &.selected {
    outline: 2px solid var(--blue);
    border-color: transparent;

    .cursor {
      pointer-events: all;
    }
  }

  .iconContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 28px;
      width: 28px;

      &.picture {
        @include icons.ico("black-lineal-picture");
      }

      &.ai {
        @include icons.ico("black-lineal-ai-bold");
      }

      &.block {
        @include icons.ico("black-lineal-block");
      }

      &.config {
        @include icons.ico("black-lineal-config");
      }

      &.style {
        @include icons.ico("black-lineal-style");
      }

      &.three {
        @include icons.ico("black-lineal-three");
      }

      &.four {
        @include icons.ico("black-lineal-four");
      }

      &.five {
        @include icons.ico("black-lineal-five");
      }

      &.six {
        @include icons.ico("black-lineal-six");
      }
    }
  }

  .description {
    font-size: 13px;
  }

  .cursor {
    pointer-events: none;
  }
}