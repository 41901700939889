@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentCommunityPagePartner {
  .cards {
    width: 100%;
  }

  .connect {
    padding: 20px;
    border: 1px solid var(--grey-sur);
    border-radius: 10px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    .head {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;

      .title {
        text-align: center;

        @include texts.font("semi-bold");
      }

      .iconContainer {
        height: 20px;
        width: 20px;
        position: relative;
        overflow: hidden;
        border-radius: 100%;
      }
    }

    .content {
      .firstLabel {
        padding-bottom: 10px;
        font-size: 15px;
      }

      .html {
        padding: 10px;
        margin-top: 15px;
        border-radius: 30px;
        font-size: 14px;
        background: var(--grey-sur);
        color: var(--black);
        width: fit-content;
        display: flex;
        align-items: center;
        gap: 8px;

        .icon {
          height: 20px;
          width: 20px;

          &.WEBFLOW {
            @include icons.ico("colored-webflow");
          }

          &.WORDPRESS {
            @include icons.ico("colored-wordpress");
          }

          &.SHOPIFY {
            @include icons.ico("colored-shopify");
          }

          &.PRESTASHOP {
            @include icons.ico("colored-prestashop");
          }

          &.WIX {
            @include icons.ico("black-wix");
          }
        }
      }

      .saves {
        border-top: 1px solid var(--grey-sur);
        margin-top: 10px;
        padding-top: 15px;
      }
    }
  }

  .extend {
    margin-bottom: 20px;

    .loader, .empty {
      height: 150px;
      position: relative;

      div[emptyText] {
        font-size: 18px;

        @include texts.font("semi-bold");
      }

      .emptyIcon {
        height: 80px;
        width: 80px;

        @include icons.ico("3d-blog");
      }
    }

    .list {
      .title {
        font-size: 16px;
        margin-bottom: 15px;

        @include texts.font("semi-bold");
      }

      .entries {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .entry {
          border-radius: 12px;
          border: 1px solid var(--grey-sur);
          cursor: pointer;
          font-size: 15px;
          padding: 10px 15px;

          &.selected {
            background: var(--grey-sur);
            border: unset;
          }

          &:hover {
            border-color: transparent;
            background: var(--grey-sur);
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    .cards {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .card {
        width: calc(100% - 40px);
      }
    }

    .list {
      .entries {
        width: 100% !important;
      }
    }
  }
}