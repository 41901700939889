@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/icons/icons";

.componentOfferManageTicket {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .entry {
    .product {
      display: flex;
      font-size: 15px;
      margin-bottom: 10px;

      @include texts.font("semi-bold");
    }
  }

  .subscription {
    width: 100%;
    display: flex;
    align-items: baseline;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--grey-sur);
    font-size: 20px;

    @include texts.font("bold");

    span {
      font-size: 14px;
      margin-left: 4px;

      @include texts.font("semi-bold");
    }
  }

  .items {
    width: 100%;
    padding: 4px 0 12px;
    border-bottom: 2px solid var(--grey-sur);
    display: flex;
    flex-direction: column;
    gap: 4px;

    .item {
      font-size: 16px;
      @include texts.font("bold");

      span {
        font-size: 14px;
        @include texts.font("medium");
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .price {
      font-size: 24px;
      @include texts.font("bold");

      span {
        font-size: 16px;
        @include texts.font("semi-bold");
      }
    }

    .cancel {
      cursor: pointer;
      background: var(--offer-cancel);
      border-radius: 8px;
      padding: 8px 13px;
      color: var(--white);
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      margin-top: 12px;

      @include texts.font("semi-bold");
    }
  }

  .schedule {
    background: var(--grey-sur);
    display: flex;
    margin-top: 14px;
    align-items: center;
    padding: 12px;
    border-radius: 12px;
    font-size: 14px;
    gap: 12px;
    text-decoration: underline;
    cursor: pointer;
    position: relative;

    &:hover {
      background: var(--grey-sur-hover);
    }

    &.loading {
      color: transparent;

      .icon {
        opacity: 0;
      }
    }

    .icon {
      height: 18px;
      width: 18px;
      flex: 0 0 18px;

      @include icons.ico("3d-time");
    }
  }
}