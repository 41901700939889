@use "src/sedestral/sass/icons/icons";
@use "src/sedestral/sass/mixins/texts";

.componentCommunityPageStyleEntry {
  border: 1px solid var(--grey-sur);
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 15px;

  .top {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .name {
      font-size: 18px;

      @include texts.font("semi-bold");
    }

    .delete {
      background: var(--grey-sur);
      padding: 10px 20px;
      border-radius: 35px;
      margin-left: auto;
      cursor: pointer;

      @include texts.font("semi-bold");

      &:hover {
        background: var(--grey-sur-hover);
      }
    }
  }

  .line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &.purpose {
      .icon {
        @include icons.ico("black-purpose");
      }
    }

    &.tone {
      .icon {
        @include icons.ico("black-tone");
      }
    }

    &.voice {
      .icon {
        @include icons.ico("black-voice");
      }
    }

    .icon {
      height: 28px;
      width: 28px;
      margin-right: 10px;
    }

    span {
      margin-right: 5px;

      @include texts.font("semi-bold");
    }
  }

  @media (max-width: 480px) {
    .top {
      .name {
        font-size: 16px;
      }

      .delete {
        font-size: 14px;
      }
    }

    .line {
      font-size: 14px;

      .icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}