@use "../../../../../../sedestral/sass/mixins/texts";
@use "../../../../../../sedestral/sass/icons/icons";
@use "../../../../../../sedestral/sass/mixins/outline";

[published] .switch {
  display: none !important;
}

.componentQuillImageBlot {

  .switch {

    background: var(--black-alpha-dark);
    color: var(--white);
    width: fit-content;
    padding: 10px 14px;
    font-size: 15px;
    bottom: 10px;
    position: absolute;
    right: 10px;
    pointer-events: all;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    @include texts.font("semi-bold");
    @include outline.blue-hover(2);

    .icon {
      height: 16px;
      width: 16px;
      filter: var(--icon-white);

      @include icons.ico("black-replace");
    }
  }
}