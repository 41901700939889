@use "src/sedestral/sass/mixins/texts";

.componentRolePermissionsCategory {
  padding: 15px;

  div[top] {
    font-size: 20px !important;
    padding-left: 0 !important;
    border-bottom: 0 !important;
    margin-bottom: 10px;
    @include texts.font("bold");

    div[collapse] {
      height: 26px !important;
      width: 26px !important;
      right: 0 !important;

      :first-child {
        height: 14px !important;
        width: 14px !important;
      }
    }
  }
}